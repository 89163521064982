import React from 'react';
import { History } from 'history';
import { Grid, Typography } from '@mui/material';
import { Button, Card, Icon, InputFile, TextToSpeech } from '../../components';
import BaseView from '../base/base-view.component';
import Model from './user-model';  //editable..
import PersonalinfotypeModel from '../personalinfotype/personalinfotype-model';
import PersonalinfoViewByuser from '../personalinfo/personalinfo-view-byuser.component';
import TransactionList from '../transaction/transaction-list.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
    dataRowPersonalinfoType: PersonalinfotypeModel;
    mousePos: string;
}

export default class UserAuthProfile extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
            dataRowPersonalinfoType: PersonalinfotypeModel.defaultValue(),
            mousePos: '',
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowPersonalinfotype = (data: any) => {
        //this.setState({ dataRowPersonalinfoType: { ...this.state.dataRowPersonalinfoType, 'personalinfotype': data?.id || null, 'personalinfotypeCaption': data?.name || '', 'personalinfotypeType': data?.type || 'customer'  }  });
    };
    
    private imgdisplay () {
        
        return (
        <Grid item >
            <Typography sx={{typography: {xs: 'caption', md: 'body2'}}}> {this.state.mousePos} </Typography>
            <img src='https://cdn4.iconfinder.com/data/icons/professions-1-5/66/16-512.png' alt= '' height={300} width={300}  useMap="#imgmap" onMouseMove={this.printMousePos}/>
            <map id = "imgmap" >
                <TextToSpeech text='Wajah'>
                    <area shape='poly' title='wajah' alt= '' coords='138,26,164,26,162,45,143,48' href=''/>
                </TextToSpeech>
                <TextToSpeech text='Lengan Kiri'>
                    <area shape='poly' title='Lengan Kiri' alt= '' coords='186,59,203,102,184,155,176,139,192,99,178,82' href='' />
                </TextToSpeech>
                <TextToSpeech text='Lengan Kanan'>
                    <area shape='poly' title='Lengan Kanan' alt= '' coords='112,59,98,102,124,155,128,139,110,99,122,82' href='' />
                </TextToSpeech>
                <TextToSpeech text='Perut'>
                    <area shape='rect' title='Perut' alt= '' coords='125,117,170,134' href=''/>
                </TextToSpeech>
                
            </map>
        </Grid>
        )
    }
    private printMousePos = (e: React.MouseEvent) => {
        this.setState({mousePos:  "x: " + (e.clientX-349) + " - y: " + (e.clientY-198)})
    }
      
    render() {
        return (
            //editable..
            <BaseView history={this.props.history} id={+Model.getUser()} caption={Model.caption+' Information'} captionColor='primary.light'
                model={Model} getDataRow={this.getDataRow} disableCard>              
                    
                <Grid container direction='column' >
                    <Grid item container direction='column' alignItems='center' position='absolute'>
                        <InputFile name='imageUrl' value={this.state.dataRow.imageUrl} type='image' maxHeight={150} circle readOnly/>
                        <Typography sx={{typography: {xs: 'h4', md: 'h3'}, textTransform: 'capitalize'}}>
                            {this.state.dataRow.name}
                        </Typography>
                        <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} >
                            {this.state.dataRow.useremail}
                        </Typography>
                        <Button variant='contained' startIcon={<Icon name='logout'/>} sx={{margin: 2}} onClick={()=>{this.props.history.push('/user/signout')}} label='Logout' />
                        <Button startIcon={<Icon name='edit'/>} onClick={()=>{this.props.history.push('/user/profileedit', {id: this.state.dataRow.id})}} label='Change Profile' />
                        <Button startIcon={<Icon name='key'/>} onClick={()=>{this.props.history.push('/user/changepassword')}} label='Change Password' />
                    </Grid>

                    <Grid mt={10}>
                        <Card color='secondary.dark' gradientColor='white'>
                            <Grid mt={40}>
                                {this.state.dataRow.personalinfo &&
                                <>
                                <PersonalinfoViewByuser history={this.props.history} id={this.state.dataRow.personalinfo} caption='Personal Info' />
                                <TransactionList history={this.props.history} transactiontypeType='sales' personalinfotypeType='customer' personalinfoId={this.state.dataRow.personalinfo} url='/transactionsales' actions={['view']} disableBackButton />
                                </>
                                }
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </BaseView>
        );
    }
}