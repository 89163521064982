import * as React from 'react';
import { Card as CardMui, CardHeader, Collapse, Grid, IconButton, Typography } from '@mui/material';
import { Icon, Popover } from '.';

interface Props {
  id?: string;
  caption?: string;
  color?: any;
  captionAlign?: 'left'|'center'|'right';
  popOver?: string;
  gradientColor?: any;
  borderColor?: any;
  borderRadius?: number;
  borderWidth?: number;
  boxShadow?: number;
  padding?: number;
  margin?: number;
  fullHeight?: boolean;
  wrap?: boolean;
  onClick?: ()=>void;
  media?: React.ReactNode;
  children?: React.ReactNode;
  
}

export const Card: React.FunctionComponent<Props> = (props) => {
  
  const [expanded, setExpanded] = React.useState(!props.wrap);
  const [background, setBackground] = React.useState('');
  const [hover, setHover] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const onExpand = () => {
    setExpanded(!expanded);
  };

  const onMouseEnter = (e: React.MouseEvent<HTMLElement>) => {
    if (props.popOver) setAnchorEl(e.currentTarget);
  }

  const onMouseLeave = () => {
    if (props.popOver) setAnchorEl(null);
  }

  React.useEffect(() => {
    if (props.gradientColor)
      setBackground('linear-gradient(90deg, '+props.gradientColor+', transparent)');

    if (props.boxShadow)
      setHover({ boxShadow: props.boxShadow, backgroundColor: 'secondary.main' });

  },[props.gradientColor, props.boxShadow])

  return (
    <>
    {props.children || props.media?
    <Grid id={props.id} item xs={12} display={props.fullHeight? 'flex' : ''} height={props.fullHeight? '100%' : 'auto'} mb={props.margin || 0}  mt={props.margin || 0} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>     
      <CardMui variant='outlined' sx={{width: '100%', background: background, backgroundColor: props.color || 'white', borderWidth: props.borderWidth , borderColor: props.borderColor || 'silver', borderRadius: props.borderRadius || 5, ':hover': hover }}>
        {props.caption?
          <CardHeader 
            sx={{padding: 2, paddingTop: 1, paddingBottom: 0}}
            title={<Typography sx={{typography: {xs: 'h6', md: 'h5'}}} textAlign={props.captionAlign} color='primary.light'>{props.caption}</Typography>}
            action={ 
              <IconButton style={{marginLeft: 'auto'}} onClick={onExpand}> <Icon name={expanded? 'expand_more' : 'expand_less'}/> </IconButton>
            }
          />
        :''}      
        <Collapse in={expanded} timeout='auto' unmountOnExit>
            {props.media?
            <Grid container justifyContent='center'>
              {props.media}
            </Grid>
            :''}
            {props.children?
            <Grid p={props.padding || 2} pt={props.caption? 0 : -1}>
                {props.children}
            </Grid>
            :''}
        </Collapse>
      </CardMui>
      <Popover value={props.popOver} anchorEl={anchorEl} />
    </Grid>    
    :''
    }
    
    </>
  )
};


