import * as React from 'react';
import Applayout01 from './applayout01/index.component';
import Applayout02 from './applayout02/index.component';
import Applayout03 from './applayout03/index.component';
import Applayout04 from './applayout04/index.component';
import Applayout05 from './applayout05/index.component';
import Applayout06 from './applayout06/index.component';
import Applayout07 from './applayout07/index.component';
import AppService from '../../services/app.service';

interface IProps {  
  getThemes: (data: any) => void;
}

interface IState {
}

export default class Applayout extends React.Component<IProps, IState> {

  constructor(props: IProps) {
      super(props); 
  }

  public render(): React.ReactNode {  
    switch (AppService.appId()) {

      case '1': return <Applayout01 getThemes={this.props.getThemes}/>; //Jatara
      case '2': return <Applayout02 getThemes={this.props.getThemes}/>; //Kemon
      case '3': return <Applayout03 getThemes={this.props.getThemes}/>; //Noia Noia
      case '4': return <Applayout04 getThemes={this.props.getThemes}/>; //Kotak Bicara
      case '5': return <Applayout05 getThemes={this.props.getThemes}/>; //Klinik
      case '6': return <Applayout06 getThemes={this.props.getThemes}/>; //RFM Water
      case '7': return <Applayout07 getThemes={this.props.getThemes}/>; //Hands on Repo
      default : return <Applayout01 getThemes={this.props.getThemes}/>;
    }
  }
}