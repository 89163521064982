import React from 'react';
import { History } from 'history';
import BaseShare from '../base/base-share.component';
import Model from './user-model';  //editable..
import UserAuthSendEmailConfirmation from './user-auth-sendemailconfirmation.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserShare extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <>            
            <BaseShare history={this.props.history} caption='Share Email Confirmation' buttonCaption=' '
                model={Model} getDataRow={this.getDataRow}>              
            </BaseShare>
                
            <UserAuthSendEmailConfirmation history={this.props.history} caption=' ' useremail={this.state.dataRow.useremail} disableBackButton/>
            </>
        );
    }
}