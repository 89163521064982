import ApiService from '../../services/api.service';

export default class OwnerModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    phone: string;
    email: any;
    address: string;
    image: any;
    imageId: number;
    imageUrl: string;    

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.phone = dataRow.attributes.phone;
        this.email = dataRow.attributes.email;
        this.address = dataRow.attributes.address;
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ApiService.svrUrl+this.image.attributes.url : '';

    } 

    public static endPoint: string = 'owners' 
    public static endPointMedia: string = 'api::owner.owner';
    public static endPointFilter2 = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[id]='+owner)
    } 
    public static url: string = '/owner';
    public static caption: string = 'Owner';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        phone: '',
        email: null,
        address: '',
        image: null,
        imageId: 0,
        imageUrl: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static setOwner(id: string, caption: string) {
        localStorage.setItem('owner',id);
        localStorage.setItem('ownerCaption',caption);
    }

    public static getOwner() {
        return localStorage.getItem('owner') || '';
    }

    public static getOwnerCaption() {
        return localStorage.getItem('ownerCaption') || '';
    }
}