import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputFile, InputMultiline, InputSelect, Picklist } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './event-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import EventtypePicklist from '../eventtype/eventtype-picklist.component';
import EventsectionChecklist from '../eventsection/eventsection-checklist.component';
import PersonalinfoPicklist from '../personalinfo/personalinfo-picklist.component';
import PersonalinfoChecklist from '../personalinfo/personalinfo-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    caption?: string;
    astemplate?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class EventCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: {...Model.defaultValue(), astemplate: this.props.astemplate || 'no'},
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };

    private getDataRowEventtype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'eventtype': data?.id || null, 'eventtypeCaption': data?.name || ''  }  });
    };

    private getDataRowEventsection = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'eventsection': data || [], 'eventsectionCaption': data?.length>0? (data[0].name || data[0]?.attributes.name) + (data.length>1? ', ...': '') : ''}  });
    };
    
    private getDataRowCustomer = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'customer': data?.id || null, 'customerCaption': data?.name || ''  }  });
    };
    
    private getDataRowParticipant = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'participant': data || [], 'participantCaption': data?.length>0? (data[0].name || data[0]?.attributes.name) + (data.length>1? ', ...': '') : ''}  });
    };
    
    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} caption={this.state.dataRow.parent && 'Duplicate Event Section'}
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} error={this.state.dataError?.status} onChange={this.onChange} />
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                {this.state.dataRow.parent &&
                <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/>
                }
                {!this.state.dataRow.parent &&
                <Picklist name='eventtype' label='Type' value={this.state.dataRow.eventtype} caption={this.state.dataRow.eventtypeCaption} error={this.state.dataError?.eventtype} clearValue={this.getDataRowEventtype} onChange={this.onChange} >
                    <EventtypePicklist currentId={this.state.dataRow.eventtype} getDataRow={this.getDataRowEventtype}/>
                </Picklist>
                }
                <Picklist name='eventsection' label='Section' value={this.state.dataRow.eventsection} caption={this.state.dataRow.eventsectionCaption} disableAutoClose clearValue={this.getDataRowEventsection} error={this.state.dataError?.eventsection} onChange={this.onChange}>
                    <EventsectionChecklist history={this.props.history} currentId={this.state.dataRow.eventsection} getDataRow={this.getDataRowEventsection} disableBackButton/>
                </Picklist> 
                {!this.state.dataRow.parent &&
                <Picklist name='customer' label='Customer' value={this.state.dataRow.customer} caption={this.state.dataRow.customerCaption} error={this.state.dataError?.customer} clearValue={this.getDataRowCustomer} onChange={this.onChange} >
                    <PersonalinfoPicklist currentId={this.state.dataRow.customer} getDataRow={this.getDataRowCustomer}/>
                </Picklist>
                }
                <Picklist name='participant' label={'Participant' + (this.state.dataRow.participantLength===1? ' Name' : 's')} value={this.state.dataRow.participant} caption={this.state.dataRow.participantCaption} disableAutoClose clearValue={this.getDataRowParticipant} error={this.state.dataError?.participant} onChange={this.onChange}>
                    <PersonalinfoChecklist caption='Select Participant' personalinfotypeType='customer' currentId={this.state.dataRow.participant} getDataRow={this.getDataRowParticipant} disableBackButton/>
                </Picklist> 
                <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onChange} />
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onChange} />
                <InputDate name='startdate' label='Start Date' value={this.state.dataRow.startdate} clearValue error={this.state.dataError?.startdate} onChange={this.onChange} />
                <InputDate name='enddate' label='End Date' value={this.state.dataRow.enddate} clearValue error={this.state.dataError?.enddate} onChange={this.onChange} />
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
             
            </BaseCopy>
        );
    }
}