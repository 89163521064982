import React from 'react';
import { History } from 'history';
import { Chip, Input } from '../../components';
import { Grid } from '@mui/material';
import BaseCreate from '../base/base-create.component';
import BaseEdit from '../base/base-edit.component';
import BaseView from '../base/base-view.component'; 
import BaseList from '../base/base-list.component';
import Model from './transaction-model';  //editable..
import UserModel from '../user/user-model';
import EventModel from '../event/event-model';
import TransactiontypeModel from '../transactiontype/transactiontype-model';
import TransactiondetailCreate from '../transactiondetail/transactiondetail-create.component';

interface IProps { 
    history: History;
    root?: string;
    caption?: string;
    transactiontypeType?: string;
    transactionType?: string;
    disableFields?: any;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
    transactiondetailId: number;
    job: string;
}

export default class TransactionCreateByuserEvent extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
            transactiondetailId: 0,
            job: '',
        } 
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow: {...data, status: 'approved'}});    
    }

    private getDataRowUser = (data:any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'customer': data?.personalinfo, description: 'Digital Wedding Invitation'} });
    }
    
    private getDataRowEvent = (data:any) => {
        this.setState({job: ''});
        if (data?.eventtypeType) setTimeout(() => this.props.history.push('/'+data?.eventtypeType, {id: data?.id}), 1);
    }


    private getDataSetTransactiontype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transactiontype': data?.[0]?.id || null, 'transactiontypeCaption': data?.[0]?.name || ''  }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onSave = (data:any) => {
        this.setState({dataRow: { ...this.state.dataRow, id: data.id}, job: 'transactiondetail'});
    }

    private onSaveTransactiondetail = (data:any) => {
        this.setState({transactiondetailId: data.id, job: 'changestatus'});
    }

    private onSaveEdit = (data:any) => {
        this.setState({job: 'event'});
    }

    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable.. 
            <>       
            <BaseView id={+UserModel.getUser()} caption='' 
                model={UserModel} getDataRow={this.getDataRowUser}/>
            
            <BaseCreate history={this.props.history} caption={this.props.caption} buttonCaption={this.state.dataRow.transactiontype? '' : ' '}
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} onSave={this.onSave} disableBackButton disableAutoBack disableCard buttonOnBottom>
                
                <BaseList history={this.props.history} caption=' ' endPointFilter={TransactiontypeModel.endPointFilter(this.props.transactiontypeType||'')} model={TransactiontypeModel} getDataSet={this.getDataSetTransactiontype} disableView/>
                {this.props.disableFields?.includes('transactionType') && !this.state.dataRow.transactiontype?
                <Grid container justifyContent='center'><Chip label='Transaction is not allowed, possible transaction type is missing' size='small' color='default'/></Grid>
                :
                <Input name='name' label='' value={this.state.dataRow.name} placeHolder='Event Name (ex: wedding-dewa-dewi)' box error={this.state.dataError?.name} onChange={this.onChange} />
                
                }
                           
            </BaseCreate>

            {this.state.job==='transactiondetail' && <TransactiondetailCreate history={this.props.history} transactionId={this.state.dataRow.id} productId={12} productQty={1} save onSave={this.onSaveTransactiondetail} disableView/>}
            {this.state.job==='changestatus' && 
            <BaseEdit id={this.state.dataRow.id} caption=' ' buttonCaption=' '
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} save onSave={this.onSaveEdit} disableAutoBack disableBackButton disableCard />
            }
            {this.state.job==='event' &&
            <BaseView id={this.state.transactiondetailId} caption='' 
                model={EventModel} endPointFilter={EventModel.endPointFilter9()} getDataRow={this.getDataRowEvent}/>
            }
            </>
        );
    }     
     
}