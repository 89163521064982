import * as React from 'react';
import Model from './region-model';
import BasePicklist from '../base/base-picklist.component';

interface IProps { 
  currentId?: number;  
  caption?: string;  
  parentId?: number;  
  regionType?: string;  
  getDataRow: (data: any) => void;
}

interface IState {
}

export default class RegionPicklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  public render(): React.ReactNode {
    return (
      <BasePicklist caption={this.props.caption} endPointFilter={this.props.parentId? Model.endPointFilter2+this.props.parentId : this.props.regionType? Model.endPointFilter3+this.props.regionType : Model.endPointFilter } model={Model} currentId={this.props.currentId} getDataRow={this.props.getDataRow} disableBackButton/>
    );
  }
}
