import ApiService from '../../services/api.service';
import OwnerModel from '../owner/owner-model';

export default class ReportModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    parent: any;
    parentCaption: string;
    report: any;
    title: string;
    content: string;
    seq: number;
    type: string;
    typeCaption: string;
    option: any;
    image: any;
    imageId: number;
    imageUrl: string;
    imagetitle: string;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.parent = dataRow.attributes.parent?.data?.id || null;
        this.parentCaption = dataRow.attributes.parent?.data?.attributes.name || dataRow.attributes.parent?.data?.id;
        this.report = dataRow.attributes.report?.data || null;
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.seq = dataRow.attributes.seq;
        this.type = dataRow.attributes.type || 'general' || 'sectiongeneral';
        this.typeCaption = (ReportModel.optionType, ReportModel.optionTypeSection).filter((d: any) => d.key === this.type)?.[0]?.value || 'General';
        this.option = dataRow.attributes.option || (!this.parent && ReportModel.option[this.type]);
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ApiService.svrUrl+this.image?.attributes.url : '';
        this.imagetitle = dataRow.attributes.imagetitle;
        
    } 

    public static endPoint: string = 'reports';
    public static endPointMedia: string = 'api::report.report';
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&sort=seq&filters[owner]='+owner+'&filters[parent][id][$null]=true');
    } 
    public static endPointFilter1: string = '?populate[report][populate]=image&populate[report][sort]=seq&filters[id]=';
    public static endPointFilter2: string = '?populate=*&sort=seq&filters[parent]=';
    public static endPointFilter3: string = '?populate=*&sort=seq&filters[reportgroup]=';
    public static url: string = '/report';
    public static caption: string = 'Report';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        parent: null,
        parentCaption: '',
        report: null,
        title: '',
        content: '',
        seq: 0,
        type: '',
        typeCaption: '',
        option: this.option.general,
        image: null,
        imageId: 0,
        imageUrl: '',
        imagetitle: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'typeCaption', caption: 'Type' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'title', caption: 'Title' },
    ]
    
    public static optionType = [
        { key: 'general', value: 'General' },
        { key: 'invitation', value: 'Invitation' },
        { key: 'invoice', value: 'Invoice' },
        { key: 'page', value: 'Page' },
    ]
    
    public static optionTypeSection = [
        { key: 'sectioncover', value: 'Section Cover' },
        { key: 'sectionheader', value: 'Section Header' },
        { key: 'sectionbody', value: 'Section Body' },
        { key: 'sectionfooter', value: 'Section Footer' },
        { key: 'sectiongallery', value: 'Section Gallery' },
    ]
    
    public static printOption : any = {
        printOption: {
            type: 'pdf',
            fileName: '[filename]',
            fileType: 'a4',
            fileProperties: { title: '[reportname]', subject: '[reportname] - [fullname]', author: '[documenttitle]', keywords: '[reportdescription]', creator: '[documenttitle]' },
            margin: 20,
            marginAdj: [{ pageNumber: 1, value: 0 }],
            headerText: { start: 0, left: '', center: '', right: '' },
            footerText: { start: 2, left: 'Confidential Report', center: 'Page [pagenumber]', right: '[fullname]' },
        },
    }
    public static option : any = {
        general: {
            ...this.printOption,
            sectionbody: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            sectioncover: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                imagetitle: {
                    frame: {justifyContent:'right'},
                    typography: {typography: {xs: 'body2', md: 'h5'}, textAlign: 'center', color: 'navy', border: {xs: 2, md: 3}, padding: {xs: 1, md: 2}},
                },
                image: {justifyContent:'center', marginTop: {xs: 15, md: 25}, marginBottom: {xs: 15, md: 25}},
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h4'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
            },

            sectionheader: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            sectionfooter: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 20, md: 40}, marginBottom: {xs: 10, md: 20}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },
        },
        
        invitation: {
            sectionbody: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            sectioncover: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                imagetitle: {
                    frame: {justifyContent:'right'},
                    typography: {typography: {xs: 'body2', md: 'h5'}, textAlign: 'center', color: 'navy', border: {xs: 2, md: 3}, padding: {xs: 1, md: 2}},
                },
                image: {justifyContent:'center', marginTop: {xs: 15, md: 25}, marginBottom: {xs: 15, md: 25}},
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h4'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
            },

            sectionheader: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            sectionfooter: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 20, md: 40}, marginBottom: {xs: 10, md: 20}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },
        },

        invoice: {
            ...this.printOption,
            sectionbody: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            sectioncover: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                imagetitle: {
                    frame: {justifyContent:'right'},
                    typography: {typography: {xs: 'body2', md: 'h5'}, textAlign: 'center', color: 'navy', border: {xs: 2, md: 3}, padding: {xs: 1, md: 2}},
                },
                image: {justifyContent:'center', marginTop: {xs: 15, md: 25}, marginBottom: {xs: 15, md: 25}},
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h4'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
            },

            sectionheader: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            sectionfooter: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 20, md: 40}, marginBottom: {xs: 10, md: 20}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },
        },

        page: {
            sectionbody: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            sectioncover: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                imagetitle: {
                    frame: {justifyContent:'right'},
                    typography: {typography: {xs: 'body2', md: 'h5'}, textAlign: 'center', color: 'navy', border: {xs: 2, md: 3}, padding: {xs: 1, md: 2}},
                },
                image: {justifyContent:'center', marginTop: {xs: 15, md: 25}, marginBottom: {xs: 15, md: 25}},
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'subtitle1', md: 'h4'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
            },

            sectionheader: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 5, md: 10}, marginBottom: {xs: 5, md: 10}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },

            sectionfooter: { 
                frame: {paddingTop: {xs: 1, md: 2}, paddingBottom: {xs: 1, md: 2}},
                image: {justifyContent:'center', marginTop: {xs: 20, md: 40}, marginBottom: {xs: 10, md: 20}},
                imagetitle: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 1}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'center'},
                },
                title: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 10, md: 20}},
                    typography: {typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'center'},
                },
                content: {
                    frame: {justifyContent:'center', paddingBottom: {xs: 1, md: 2}},
                    typography: {typography: {xs: 'caption', md: 'h6'}, textAlign: 'justify'},
                },
            },
        },
    }
}