import * as React from 'react';
import { Container, Grid } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  color?: any;
  colorOthers?: any;
  position?: 'topLeft'|'topRight'|'bottomRight'|'bottomLeft';
  fullWidth?: boolean;
  disableMargin?: boolean;
  children?: React.ReactNode;
}

export const Trapezoid: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const margin = 20; 

  const createPolygon = () => {
    const adjHeight = isMobile? 50 : 100;

    //`polygon(topLeft(x,y), topRight(x,y), bottomRight(x,y), bottomLeft(x,y))`
    switch (props.position){
      case 'topLeft': return `polygon(0 0, 100% ${adjHeight}px, 100% 100%, 0 100%)`;
      case 'topRight': return `polygon(0 ${adjHeight}px, 100% 0, 100% 100%, 0 100%)`;
      case 'bottomLeft': return `polygon(0 0, 100% 0, 100% calc(100% - ${adjHeight}px), 0 100%)`;
      case 'bottomRight': return `polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - ${adjHeight}px))`;
      default: return '';      
    }
  }

  return (        
    <Grid id='box' bgcolor={props.colorOthers}>
      <Grid id={props.id} sx={{height: 'auto', backgroundColor:props.color||'primary.main', clipPath: createPolygon()}} p={props.disableMargin || isMobile? 0 : margin} pt={!props.disableMargin && props.position?.includes('top')? margin : 0} pb={!props.disableMargin && props.position?.includes('bottom')? margin : 0}> 
        {isMobile || props.fullWidth? props.children : <Container>{props.children}</Container>}
      </Grid>
    </Grid>
  );
};
