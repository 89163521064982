import { createTheme } from '@mui/material/styles';

const themeBlank = createTheme({
  palette: {
    primary: {
      light: "#eeeeee",
      main: "#eeeeee",
      dark: "#eeeeee",
      contrastText: "#eeeeee",
    },
    secondary: {
      light: "#eeeeee",
      main: "#eeeeee",
      dark: "#eeeeee",
      contrastText: "#eeeeee",
    },
    info: {
      light: "#eeeeee",
      main: "#eeeeee",
      dark: "#eeeeee",
      contrastText: "#eeeeee",
    },
    success: {
      light: "#eeeeee",
      main: "#eeeeee",
      dark: "#eeeeee",
      contrastText: "#eeeeee",
    },
    warning: {
      light: "#eeeeee",
      main: "#eeeeee",
      dark: "#eeeeee",
      contrastText: "#eeeeee",
    },
    background: {
      default: "whitesmoke"
    }
  },
});

const themeDefault = createTheme({
  palette: {
    primary: {
      light: "#1769aa",
      main: "#2196f3",
      dark: "#4dabf5",
      contrastText: "#fff",
    },
    secondary: {
      main: "#bbdefb",
      light: "#c8e4fb",
      dark: "#e8f4fd",
      contrastText: "#fff",
    },
    info: {
      main: "#0e4686",
      light: "#1565c0",
      dark: "#4383cc",
      contrastText: "#fff",
    },
    success: {
      main: "#205723",
      light: "#2e7d32",
      dark: "#57975b",
      contrastText: "#fff",
    },
    warning: {
      main: "#a74b00", 
      light: "#ef6c00", 
      dark: "#f28933", 
      contrastText: "#fff",
    },
    background: {
      default: "#2196f3"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        action: {
          active: "#2196f3",
          hover: "#4dabf5",
          hoverOpacity: 0.7,
          focus: "#2196f3",
          focusOpacity: 1,
          selected: "#4dabf5",
          selectedOpacity: 1
        },
      },
    },
  },
});

const themeBrown = createTheme({
  palette: {
    primary: {
      light: "#b28704", 
      main: "#ffc107", 
      dark: "#ffcd38", 
      contrastText: "#fff",
    },
    secondary: {
      main: "#ffd54f", 
      light: "#ffdd72", 
      dark: "#ffecb3", 
      contrastText: "#fff",
    },
    info: {
      main: "#ae7519",
      light: "#f9a825",
      dark: "#fab950",
      contrastText: "#fff",
    },
    success: {
      main: "#205723",
      light: "#2e7d32",
      dark: "#57975b",
      contrastText: "#fff",
    },
    warning: {
      main: "#a74b00", 
      light: "#ef6c00", 
      dark: "#f28933", 
      contrastText: "#fff",
    },
    background: {
      default: 'whitesmoke'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        action: {
          active: "#b28704",
          hover: "#ffcd38",
          hoverOpacity: 0.7,
          focus: "#b28704",
          focusOpacity: 1,
          selected: "#ffcd38",
          selectedOpacity: 1
        },
      },
    },
  },
});

const themeGreen = createTheme({
  
  palette: {
    primary: {
      light: "#357a38", 
      main: "#357a38", 
      dark: "#6fbf73", 
      contrastText: "#fff",
    },
    secondary: {
      main: "#9aa58c", 
      light: "#dcedc8", 
      dark: "#e3f0d3", 
      contrastText: "#fff",
    },
    info: {
      main: "#aeea00",
      light: "#beee33",
      dark: "#79a300",
      contrastText: "#000",
    },
    success: {
      main: "#205723", 
      light: "#2e7d32", 
      dark: "#57975b", 
      contrastText: "#fff",
    },
    warning: {
      main: "#a74b00", 
      light: "#ef6c00", 
      dark: "#f28933", 
      contrastText: "#fff",
    },
    background: {
      default: 'whitesmoke'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        action: {
         active: "#357a38",
          hover: "#6fbf73",
          hoverOpacity: 0.7,
          focus: "#357a38",
          focusOpacity: 1,
          selected: "#6fbf73",
          selectedOpacity: 1
        }
      },
    },
  },
});
  

const themeTosca = createTheme({
  
  palette: {
    primary: {
      light: "#d8edeb", 
      main: "#79d4cb", 
      dark: "#a9dfd9", 
      contrastText: "#fff",
    },
    secondary: {
      main: "#c1e7e2", 
      light: "#f4fbfb", 
      dark: "#ecfaf9", 
      contrastText: "#fff",
    },
    info: {
      main: "#aeea00",
      light: "#beee33",
      dark: "#79a300",
      contrastText: "#000",
    },
    success: {
      main: "#205723", 
      light: "#2e7d32", 
      dark: "#57975b", 
      contrastText: "#fff",
    },
    warning: {
      main: "#a74b00", 
      light: "#ef6c00", 
      dark: "#f28933", 
      contrastText: "#fff",
    },
    background: {
      default: 'whitesmoke'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        action: {
         active: "#357a38",
          hover: "#6fbf73",
          hoverOpacity: 0.7,
          focus: "#357a38",
          focusOpacity: 1,
          selected: "#6fbf73",
          selectedOpacity: 1
        }
      },
    },
  },
});

//Lilac Spring
const themePurple = createTheme({
  
  palette: {
    primary: {
      
      '50': '#fef2f3',
      '100': '#fde6e7',
      '200': '#fbd0d5',
      '300': '#f7aab2',
      '400': '#f27a8a',
      '500': '#ea546c',
      '600': '#d5294d',
      '700': '#b31d3f',
      '800': '#961b3c',
      '900': '#811a39',
      light: "#C44CE9", 
      main: "#9618CC", 
      dark: "#6B08A3", 
      contrastText: "#fff",
    },
    secondary: {
      main: "#F6BDFE", 
      light: "#FEF2FF", 
      dark: "#E385F9", 
      contrastText: "#fff",
    },
    info: {
      main: "#aeea00",
      light: "#beee33",
      dark: "#79a300",
      contrastText: "#000",
    },
    success: {
      main: "#205723", 
      light: "#2e7d32", 
      dark: "#57975b", 
      contrastText: "#fff",
    },
    warning: {
      main: "#a74b00", 
      light: "#ef6c00", 
      dark: "#f28933", 
      contrastText: "#fff",
    },
    background: {
      default: 'whitesmoke'
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        action: {
         active: "#357a38",
          hover: "#6fbf73",
          hoverOpacity: 0.7,
          focus: "#357a38",
          focusOpacity: 1,
          selected: "#6fbf73",
          selectedOpacity: 1
        }
      },
    },
  },
});

//https://imagecolorpicker.com/ --get color code
//https://uicolors.app/ --ui coloring recomendation
/*
'mandy': {
        '50': '#fef2f3',
        '100': '#fde6e7',
        '200': '#fbd0d5',
        '300': '#f7aab2',
        '400': '#f27a8a',
        '500': '#ea546c',
        '600': '#d5294d',
        '700': '#b31d3f',
        '800': '#961b3c',
        '900': '#811a39',
        '950': '#48091a',
    },
  */
/*
https://aicolors.co/
lavaneder
    --primary-100:#8B5FBF;
    --primary-200:#61398F;
    --primary-300:#FFFFFF;
    --accent-100:#D6C6E1;
    --accent-200:#9A73B5;
    --text-100:#4A4A4A;
    --text-200:#878787;
    --bg-100:#F5F3F7;
    --bg-200:#E9E4ED;
    --bg-300:#FFFFFF;
      

turquise
    --primary-100:#26A69A;
    --primary-200:#408d86;
    --primary-300:#cdfaf6;
    --accent-100:#80CBC4;
    --accent-200:#43A49B;
    --text-100:#263339;
    --text-200:#728f9e;
    --bg-100:#E0F2F1;
    --bg-200:#D0EBEA;
    --bg-300:#FFFFFF;
      
*/

const ApplayoutThemes = (theme?: string) => {
    switch (theme) {
    case 'blank': return themeBlank; 
    case 'brown': return themeBrown; 
    case 'green': return themeGreen;
    case 'tosca': return themeTosca;
    case 'purple': return themePurple;
    default: return themeDefault;
  }

}

export default ApplayoutThemes;
