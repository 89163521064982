export default class AppService {

//Apps
    public static appId(){
        return `${process.env.REACT_APP_APPID}` ;  
    } 

    public static isAppFullVersion(){
        return this.appId() === '1';
    }
    
    public static isMobile(){
        return window.screen.width < 485;//window.matchMedia && window.matchMedia('(max-width: 485px)').matches;
    }
    
    public static isMac(){
        return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    }

    public static getUrlQuery(history: any, name: string){
        if (!history?.location.search) return;
        var urlQuery : any = history.location.search.substring(1).split('&');
        return urlQuery.map((d: string) => {return {[d.split('=')[0]]: d.split('=')[1]};})
                .find((d: any)=>{return d?.[name]})[name];
    }

//Date
    public static optionDay = [
        { key: 'mon', value: 'Senin' },
        { key: 'tue', value: 'Selasa' },
        { key: 'wed', value: 'Rabu' },
        { key: 'thu', value: 'Kamis' },
        { key: 'fri', value: 'Jumat' },
        { key: 'sat', value: 'Sabtu' },
        { key: 'sun', value: 'Minggu' },
    ]

    public static optionMonth = [
        { key: 'jan', value: 'Januari', valueshort: 'Jan' },
        { key: 'feb', value: 'February', valueshort: 'Feb' },
        { key: 'mar', value: 'Maret', valueshort: 'Mar' },
        { key: 'apr', value: 'April', valueshort: 'Apr' },
        { key: 'may', value: 'May', valueshort: 'Mei' },
        { key: 'jun', value: 'Juni', valueshort: 'Jun' },
        { key: 'jul', value: 'Juli' , valueshort: 'Jul'},
        { key: 'agu', value: 'Agustus', valueshort: 'Agu' },
        { key: 'sep', value: 'September', valueshort: 'Sep'},
        { key: 'oct', value: 'Oktober', valueshort: 'Okt' },
        { key: 'nov', value: 'November', valueshort: 'Nov' },
        { key: 'dec', value: 'Desember', valueshort: 'Des' },
    ]

    public static days(fieldName: string){
        return this.optionDay.reduce((r: any, d: any) =>  {r.push(d[fieldName]); return r}, [] );
    }

    public static months(fieldName: string){
        return this.optionMonth.reduce((r: any, d: any) =>  {r.push(d[fieldName]); return r}, [] );
    }

    public static dateCurrent(){
        const date = new Date();
        return date.toLocaleDateString('en-CA') + ' ' + date.toTimeString().substring(0,8)
    }

    public static dateISO(date: string, addHours?: number, addMinutes?: number, addSeconds?: number){
        var date1 = new Date(date) //convert to local time 
        
        if (addHours) date1.setHours(date1.getHours() + addHours);
        if (addMinutes) date1.setMinutes(date1.getMinutes() + addMinutes);
        if (addSeconds) date1.setSeconds(date1.getSeconds() + addSeconds);
        return date1.toLocaleDateString('en-CA') + ' ' + date1.toTimeString().substring(0,8)
    }
    
    public static dateFormat(date: string, format?: string){
        if (!date) return '';
        
        date = this.dateISO(date); 
        const month = this.months('value');
        const mon = this.months('valueshort');
        const y = date.substring(0,4);
        const m = date.substring(5,7);
        const d = date.substring(8,10);
        const t = date.substring(11,19);
        const h = date.substring(11,13);
        const n = date.substring(14,16);
        const s = date.substring(17,19);
        
        var fmt = format || 'd-m-y';
        fmt = fmt
        .replace('month', '???')
        .replace('mon', '??')
        .replace('m', '?')
        .replace('y',y)
        .replace('d',d)
        .replace('t',t)
        .replace('h',h)
        .replace('n',n)
        .replace('s',s)
        .replace('???', month[+m-1])
        .replace('??', mon[+m-1])
        .replace('?', m)

        return fmt;
    }

    public static dateDiff(from: string, to?: string){
        var diff =  (to? Date.parse(to) : Date.now())- Date.parse(from); 
        var dateDiff = new Date(diff);
        var diffYear = dateDiff.getFullYear() - 1970;
        var diffMonth = dateDiff.getMonth();
        var diffYearRounded = diffYear + (diffMonth>6? 1 : 0);

        return isNaN(diff) ? {} : {
            milisecond: dateDiff.getMilliseconds(),
            second:  dateDiff.getSeconds(),
            minute: dateDiff.getMinutes(),
            hour: dateDiff.getHours(),
            day: dateDiff.getDate(),
            month: diffMonth,
            year: diffYear,
            yearrounded: diffYearRounded,
        }
    }

//Currency & Number
    public static thousandSeparator = '.';
    public static decimalSeparator = ',';
    public static currencySymbol = 'Rp';
        
    public static strToNumber(value: string) {
        return value? (Number(value).toString().replace(/[^0-9,-]/g, _ => '')+'') : '0';
    }

    public static strToMoney(value: string) {
        let i = 0;
        const pattern = '###.###.###.###.###'.replaceAll('.', this.thousandSeparator);
        const v = this.strToNumber(value);
        const x = (v.length + Math.floor((v.length-1) / 3));
        const patternNew = pattern.substr(pattern.length - x, x);
        return patternNew.replace(/#/g, _ => v[i++] || '');
    }

//String
    public static strCapitalize(value: string){
        return value?.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    }

    public static strPluralize(value: string){
        return value.slice(-1)==='y'? value.substring(0,value.length-1)+'ies' : (value + ( value.slice(-1)==='z'? 'zes': value.slice(-1)==='s'? 'es' : 's'));
    }

    public static emailMasking(value: string){
        return value?.replace(/^(.)(.*)(.@.*)$/, (_, a, b, c) => a + b.replace(/./g, '*') + c);
    }

    public static replaceValue(text: string, value: any){        
        if (value && text) Object.keys(value).forEach((key)=> text = text?.replaceAll('['+key+']', value[key]) );
        return text;
    }
    
    public static randomId (length = 6) {
        return Math.random().toString(36).substring(2, length+2);
    };

//Array    
    public static JSONParse(data: string){
        data = data.replace("'",'"');
        var result;
        try { result = JSON.parse(data)} catch (e) { result = JSON.parse(JSON.stringify(data))}
        return result;
    }
}