import React from 'react';
import { History } from 'history';
import { Input, InputFile, InputNumber, InputMultiline, InputSelect, Layout, Switch, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './report-model';  //editable..
import ReportList from './report-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class ReportDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} caption={this.state.dataRow.parent && 'Delete '+Model.caption+' Section'} 
                model={Model} getDataRow={this.getDataRow} disableCard>              

                <Layout history={this.props.history} direction={this.state.dataRow.parent? 'column' : 'row'} content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        {this.state.dataRow.parent &&
                        <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/>
                        }
                        <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                        <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} readOnly/>
                        <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={this.state.dataRow.parent? Model.optionTypeSection : Model.optionType} readOnly/>
                        <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' readOnly/>
                        <Input name='imagetitle' label='Image Title' value={this.state.dataRow.imagetitle}/>

                    </>
                    },

                    {caption: 'Sections', hide: this.state.dataRow.parent>0, child: 
                    this.state.dataRow.id>0 &&
                        <ReportList history={this.props.history} root={this.props.root} caption='Report Section' parentId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                    },

                    ]}
                    
                />                
                                
            </BaseDelete>
        );
    }
}