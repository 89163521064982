import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import JournalList from './journal-list.component';
import JournalCreate from './journal-create.component';
import JournalView from './journal-view.component';
import JournalEdit from './journal-edit.component';
import JournalCopy from './journal-copy.component';
import JournalDelete from './journal-delete.component';

interface Props {
  root?: string;
}

export const JournalRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/journal/create'} component={JournalCreate} />
      <Route path={(props.root||'')+'/journal/view'} component={JournalView} />
      <Route path={(props.root||'')+'/journal/edit'} component={JournalEdit} />
      <Route path={(props.root||'')+'/journal/copy'} component={JournalCopy} />
      <Route path={(props.root||'')+'/journal/delete'} component={JournalDelete} />
      <Route path={(props.root||'')+'/journal'} component={JournalList} />
    </Switch>
  );
};
