import React from 'react';
import * as toastr from 'toastr';
import { Form } from '../../components';
import ApiService from '../../services/api.service';

interface IProps { 
    history?: any;
    id?: number;
    caption?: string;
    captionColor?: string;
    buttonCaption?: string;
    endPoint?: string;
    endPointFilter?: string;
    model: any;
    getDataRow?: (data: any) => void;
    getDataError?: (data: any) => void;
    onSave?: (data: any) => void;
    save?: boolean;
    buttonOnBottom?: boolean;
    disableAutoBack?: boolean;
    disableBackButton?: boolean;
    disableCard?: boolean;
    children?: React.ReactNode;
}

interface IState {
}

export default class BaseShare extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    private id = this.props.id?.toString() || this.props.history?.location.state?.id || '';
    private isLoading: boolean = false;
    private isSaving: boolean = false;
    
    private onSave = () => {
        this.props.onSave?.(null);
        if (!this.props.disableAutoBack) this.props.history?.goBack();
        this.isSaving = false;
    }

    public componentDidMount() { 
        if (this.isLoading) return;
        this.isLoading = true;
        
        ApiService.get<typeof this.props.model>((this.props.endPoint||this.props.model.endPoint)+(this.props.endPointFilter||'?populate=*&filters[id]='), this.id).then(
            (rp) => {
                if (rp.Status) { if (rp.Data.length>0) var data = new this.props.model(rp.Data[0]); this.props.getDataRow?.(data);} 
                else { this.props.getDataError && rp.Errors? this.props.getDataError(rp.Errors) : rp.Messages && toastr.error(rp.Messages); }
                this.isLoading = false;
            }
        );
    }
    
    public componentDidUpdate() {
        if (this.props.save) {
            if (this.isSaving) return;
            this.isSaving = true;
            this.onSave();
        }
    }

    render() {
        return (
        <Form history={this.props.history} caption={this.props.caption || 'Share '+ this.props.model.caption} captionColor={this.props.captionColor} buttonCaption={this.props.buttonCaption || 'share'} buttonOnBottom={this.props.buttonOnBottom} onSubmit={this.onSave} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
            {this.props.children}
        </Form>
        );
    }
}