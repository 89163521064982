import React from 'react';
import { History } from 'history';
import { InputMoney, InputMultiline, InputNumber, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './transactiondetail-model';  //editable..
import ProductPicklist from '../product/product-picklist.component';
import ProductList from '../product/product-list.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactiondetailEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        
        this.onChange = this.onChange.bind(this);
    }
    
    private validations = [...Model.validations, {}];

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
        this.validations.splice(1, 1, { name: 'qty', maxValue: data.productQty });
    }
    
    private getDataRowProduct = (data: any) => {
        const price = this.state.dataRow.transactiontypePricetype==='pricesale'? data?.pricesale : data?.pricebase;
        this.setState({ dataRow: { ...this.state.dataRow, 'product': data?.id || null, 'productCaption': data?.name || '', 
            'price': price, 'value': price * this.state.dataRow.qty, 'productLength': data?.product.length }  
        });
        this.validations.splice(1, 1, { name: 'qty', maxValue: data?.qty });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private onChange(fieldName: string, value: string) { 
        var  valueField = this.state.dataRow.value;
        if (fieldName==='price') valueField= this.state.dataRow.qty * Number(value)
        else if (fieldName==='qty') valueField= this.state.dataRow.price * Number(value)

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, value: valueField,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} 
                endPointFilter={Model.endPointFilter2} model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} validations={this.validations}>              
                
                <Picklist name='product' label='Product' value={this.state.dataRow.product} caption={this.state.dataRow.productCaption} error={this.state.dataError?.product} onChange={this.onChange} >
                    <ProductPicklist currentId={this.state.dataRow.product} pricetype={this.state.dataRow.transactiontypePricetype} getDataRow={this.getDataRowProduct}/>
                </Picklist>                 
                <InputMoney name='price' label='Price' value={this.state.dataRow.price} error={this.state.dataError?.price} onChange={this.onChange} />
                <InputNumber name='qty' label='Qty' value={this.state.dataRow.qty} error={this.state.dataError?.qty} onChange={this.onChange} />
                <InputMoney name='value' label='Total' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onChange} />
                <InputMultiline name='note' label='Note' value={this.state.dataRow.note} error={this.state.dataError?.note} onChange={this.onChange} />
                {this.state.dataRow.productLength>0 &&
                    <ProductList history={this.props.history} caption='Sub Product' parentId={this.state.dataRow.product} actions={[]} disableBackButton/>
                }
            </BaseEdit>
        );
    }
}