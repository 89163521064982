import * as React from 'react';
import { History } from 'history';
import Model from './event-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  url?: string;
  parentId?: number;
  actions?: any;
  astemplate?: string;
  disableBackButton?: boolean;
  disableCard?: boolean;
  getDataRow?: (data: any) => void;
}

interface IState {
}

export default class EventList extends React.Component<IProps, IState> {
    
  constructor(props: IProps) {
    super(props);
  }

  private getDataSet = (data:any) => {
  }

  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} parentId={this.props.parentId} caption={this.props.caption} endPointFilter={this.props.parentId? Model.endPointFilter2+this.props.parentId : Model.endPointFilter(this.props.astemplate)} url={(this.props.root||'')+(this.props.url || Model.url)} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard} getDataSet={this.getDataSet}/>
    );
  }
}
