import * as React from 'react';
import { Grid } from '@mui/material';
import { Icon } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  color?: any;
  width?: number;
  onClick?: (data: any) => void;
  header?: React.ReactNode;
  children?: React.ReactNode;
}

export const SideBar: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const widthCorner = isMobile? 0 : 40;
  const width = isMobile? 400 : (props.width || 350);
  const [collapse, setCollapse] = React.useState(false);
  
  const onClick = () => {
    setCollapse(!collapse);
    props.onClick?.(!collapse);
  }

  return (
    <Grid id={props.id} container position='fixed' height='100vh' width={width} bgcolor='secondary.light'>
      <Grid item width={width - widthCorner}>
        <Grid item container alignItems='center' justifyContent='space-between' p={1}>
          <Grid item>{props.header}</Grid>
          {!isMobile &&
          <Grid item sx={{cursor: 'pointer'}} onClick={onClick}><Icon name={collapse? 'unfold_more' : 'unfold_less'} color={'primary.main'} rotate={90}/></Grid>
          }
        </Grid>
        <Grid item p={1} sx={{backgroundColor: props.color || 'secondary.light', overflowY: 'auto'}}>
          {props.children}
        </Grid>
      </Grid>
      <Grid item width={widthCorner} sx={{bgcolor: 'background.default', borderTopLeftRadius: 100, borderBottomLeftRadius: 100, boxShadow: '-1px 0 0 0 silver' }}/>
    </Grid>
  );
};
