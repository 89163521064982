import React from 'react';
import { History } from 'history';
import { InputMoney, InputPercentage, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './transactionamount-model';  //editable..
import AmounttypeModel from '../amounttype/amounttype-model';
import AmounttypePicklist from '../amounttype/amounttype-picklist.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactionamountEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowAmounttype = (data: any) => {
        const value = AmounttypeModel.getCalcValue(data?.percentage, data?.value, this.state.dataRow.transactionTotaldetail)
        this.setState({ dataRow: { ...this.state.dataRow, 'amounttype': data?.id || null, 'amounttypeCaption': data?.name || '', 'percentage': data?.percentage, 'value': value, 'usepercentage': data?.usepercentage }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        var  valueField = this.state.dataRow.value;
        if (fieldName==='percentage') valueField= this.state.dataRow.transactionTotaldetail * Number(value) / 100
        else if (fieldName==='value') valueField= Number(value);

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, value: valueField
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Picklist name='amounttype' label='Type' value={this.state.dataRow.amounttype} caption={this.state.dataRow.amounttypeCaption} error={this.state.dataError?.amounttype} onChange={this.onChange} >
                    <AmounttypePicklist currentId={this.state.dataRow.amounttype} getDataRow={this.getDataRowAmounttype}/>
                </Picklist>        
                {this.state.dataRow.usepercentage &&        
                <InputPercentage name='percentage' label='Percentage' value={this.state.dataRow.percentage} error={this.state.dataError?.percentage} onChange={this.onChange} />
                }
                <InputMoney name='value' label='Amount' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onChange} readOnly={this.state.dataRow.usepercentage}/>    
            </BaseEdit>
        );
    }
}