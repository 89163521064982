import * as React from 'react';

interface Props {
  id?: string;
}

export const Spinner: React.FunctionComponent<Props> = (props) => {
  const [spinner2, setSpinner2] = React.useState('');
  const [spinner3, setSpinner3] = React.useState('');

  setTimeout(() => setSpinner2('spinner-grow spinner-grow-sm text-muted'), 100); 
  setTimeout(() => setSpinner3('spinner-grow spinner-grow-sm text-muted'), 1000); 

  return (
    <>
      <div className='spinner-grow spinner-grow-sm text-muted'/>
      <div className={spinner2}/>
      <div className={spinner3}/>
    </>
  )
    
};



