import * as React from 'react';
import { IconButton, TextField } from '@mui/material';
import { Dialog, Icon } from '.';


interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value: string;
  caption: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  box?: boolean;
  color?: string;
  readOnly?: boolean;
  disableAutoClose?: boolean;
  clearValue?: (data: any) => void;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
  children?: React.ReactNode;
}

export const Picklist: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  
  const fontSize = () =>{
    switch (props.size) {
      case 'extrasmall': return 16;
      case 'small': return 18;
      case 'medium': return 24;
      case 'large': return 36;
      case 'extralarge': return 48;
      default : return 18;
    }
  }
    
  const onClickClear = () => {props.clearValue?.(null)};
  const onClick = () => {setOpen(true);};
  const onClose = () => {setOpen(false)};
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
  };
  
  React.useEffect(()=>{
    if (!props.disableAutoClose) onClose(); 
  }, [props])

  return (
    <TextField
      id={props.id || props.name}
      name={props.name}
      label={props.label}
      type={'input' }
      value={props.caption || ''}
      placeholder={props.placeHolder}
      InputProps={
        {
          readOnly: true,
          endAdornment: !props.readOnly &&
            <>
            {(props.clearValue && props.value) &&
              <IconButton onClick={onClickClear} style={{padding: 2}}>
                <Icon name='clear' size='small' color='primary.main'/>
              </IconButton>
            }
            <IconButton onClick={onClick} style={{padding: 0}}>
              <Icon name='dataset_outlined' size='small' color='primary.main'/>
            </IconButton>
            <Dialog open={open} onClose={onClose}>{open && props.children}</Dialog>
            </>
          ,
          sx: {fontSize: {xs: fontSize()-2, md: fontSize()}, bgcolor: props.color},
        }
      }
      InputLabelProps={{
        sx: {fontSize: {xs: fontSize()-2, md: fontSize()}}
      }}
      margin='normal'
      variant={props.box? undefined : 'standard'}
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onChange={onChange(props)}
    />
  )
};