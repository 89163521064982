import React from 'react';
import { History } from 'history';
import { Grid, Typography } from '@mui/material';
import { InputNumber } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseEdit from '../base/base-edit.component';
import BaseView from '../base/base-view.component';
import BaseList from '../base/base-list.component';
import Model from './transactiondetail-model';  //editable..
import TransactionModel from '../transaction/transaction-model';
import ProductModel from '../product/product-model';
                
interface IProps { 
    history: History;
    productId?: number;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowTransaction: TransactionModel;
    job: string;
}

export default class TransactiondetailCreateByUser extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowTransaction: TransactionModel.defaultValue(),
            job: '',
        }
        
        this.onChange = this.onChange.bind(this);
    }
    
    private validations = [...Model.validations, {}];

    private getDataSet = (data:any) => {
        this.setState({ dataRow: { ...this.state.dataRow, ...data?.[0]}});
    }
    
    private getDataSetTransaction = (data:any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 
            'transaction': data?.[0]?.id || null, 
            'transactiontype': data?.[0]?.transactiontype || null, 
            'transactiontypePricetype': data?.[0]?.transactiontypePricetype || null}  
        });
    }
    
    private getDataRowProduct = (data: any) => {
        const price = this.state.dataRow.transactiontypePricetype==='pricesale'? data?.pricesale : data?.pricebase;
        this.setState({ dataRow: { ...this.state.dataRow, 'product': data?.id || null, 'productCaption': data?.name || '', 
            'price': price, 'value': price * this.state.dataRow.qty, 'productLength': data?.product.length }  
        });
        this.validations.splice(1, 1, { name: 'qty', maxValue: data?.qty });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private onChange(fieldName: string, value: string) { 
        var  valueField = this.state.dataRow.value;
        if (fieldName==='price') valueField= this.state.dataRow.qty * Number(value)
        else if (fieldName==='qty') valueField= this.state.dataRow.price * Number(value)
        
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, value: valueField,
            }
        };
        this.setState(nextState);
    }
    

    render() {
        return (
            //editable..
            <Grid item xs={12} md={4} sx={{justifyContent: {xs: 'center', md: 'left'}}}>
            
            <BaseList caption=' ' endPointFilter={TransactionModel.endPointFilter2()} model={TransactionModel} getDataSet={this.getDataSetTransaction} disableView/>
            {this.state.job==='transaction' &&
            <BaseCreate caption=' ' buttonCaption=' '
                model={TransactionModel} dataRow={this.state.dataRowTransaction} getDataError={this.getDataError} validations={this.validations} save disableAutoBack disableBackButton disableCard/>           
            }
            <BaseView id={this.props.productId} caption=''
                model={ProductModel} getDataRow={this.getDataRowProduct}/>
            {this.state.dataRow.transaction>0 && this.props.productId &&
            <>
            <BaseList caption=' ' endPointFilter={Model.endPointFilter+this.state.dataRow.transaction+'&filters[product]='+this.props.productId} model={Model} getDataSet={this.getDataSet} disableView/>
            {this.state.dataRow.id>0?
                <BaseEdit id={this.state.dataRow.id} caption=' ' buttonCaption='Masukan keranjang'
                    model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} validations={this.validations} buttonOnBottom disableAutoBack disableBackButton disableCard>
                    <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'} }}>Qty:
                    </Typography>         
                    <InputNumber name='qty' label='' value={this.state.dataRow.qty} box error={this.state.dataError?.qty} onChange={this.onChange} />
                        
                </BaseEdit>
            :
                <BaseCreate caption=' ' buttonCaption='Masukan keranjang'
                    model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} validations={this.validations} buttonOnBottom disableAutoBack disableBackButton disableCard>
                    
                    <InputNumber name='qty' label='' value={this.state.dataRow.qty} box error={this.state.dataError?.qty} onChange={this.onChange} />
                </BaseCreate>
            }
        </>
            }

            </Grid>
            
        );
    }     
     
}