import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputFile, InputMultiline, InputSelect, Picklist, Switch } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './message-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import AppService from '../../services/app.service';
import OwnerPicklist from '../owner/owner-picklist.component';

interface IProps { 
    history: History;
    caption?: string;
    astemplate?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class MessageCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: {...Model.defaultValue(), astemplate: this.props.astemplate || 'no', sent: this.props.astemplate==='yes'? 'no' : 'yes'},
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private getFiles = (data:any) => {
        this.setState({
            dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow: {id: this.state.dataRow.attachmentId, files: data, ref: Model.endPointMedia, field:'attachments'}},
            dataRow: {...this.state.dataRow, 'attachments': data}, 
        });
    }

    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={this.props.caption} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataError={this.getDataError}>               
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                }
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} onChange={this.onChange} />
                {this.props.astemplate!=='yes' &&
                <Switch name='sent' label='Send Now?' value={this.state.dataRow.sent} defaultTrue='yes' defaultFalse='no' error={this.state.dataError?.sent} onChange={this.onChange}/>
                }
                {this.state.dataRow.type==='email'?
                <>
                <InputEmail name='to' label='To' value={this.state.dataRow.to} error={this.state.dataError?.to} onChange={this.onChange} />
                <InputEmail name='cc' label='Cc' value={this.state.dataRow.cc} error={this.state.dataError?.cc} onChange={this.onChange} />
                <InputEmail name='bcc' label='Bcc' value={this.state.dataRow.bcc} error={this.state.dataError?.bcc} onChange={this.onChange} />
                <InputEmail name='replyto' label='Reply to' value={this.state.dataRow.replyto} error={this.state.dataError?.replyto} onChange={this.onChange} />
                </>
                : 
                <Input name='to' label='To' value={this.state.dataRow.to} error={this.state.dataError?.to} onChange={this.onChange} />
                }
                <Input name='subject' label='Subject' value={this.state.dataRow.subject} error={this.state.dataError?.subject} onChange={this.onChange} />
                <InputMultiline name='text' label='Text' value={this.state.dataRow.text} error={this.state.dataError?.text} onChange={this.onChange} />
                <InputFile name='attachmentUrl' label='Attachment' value={this.state.dataRow.attachmentUrl} getFiles={this.getFiles} error={this.state.dataError?.attachments} encode onChange={this.onChange} />

           </BaseCreate>
        );
    }     
     
}