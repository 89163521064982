import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import AppList from './app-list.component';
import AppCreate from './app-create.component';
import AppView from './app-view.component';
import AppEdit from './app-edit.component';
import AppCopy from './app-copy.component';
import AppDelete from './app-delete.component';

interface Props {
  root?: string;
}

export const AppRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/app/create'} component={AppCreate} />
      <Route path={(props.root||'')+'/app/view'} component={AppView} />
      <Route path={(props.root||'')+'/app/edit'} component={AppEdit} />
      <Route path={(props.root||'')+'/app/copy'} component={AppCopy} />
      <Route path={(props.root||'')+'/app/delete'} component={AppDelete} />
      <Route path={(props.root||'')+'/app'} render={(params)=> <AppList {...params} root={props.root} />} /> 
    </Switch>
  );
};
