import React from 'react';
import { History } from 'history';
import BaseCopy from '../base/base-copy.component'; 
import Model from './eventquestion-model';  //editable..

interface IProps { 
    history: History;
    caption?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class EventquestionCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} 
                endPointFilter={Model.endPointFilter} model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>                 
                
            </BaseCopy>
        );
    }
}