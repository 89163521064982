import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import TransactiontypeList from './transactiontype-list.component';
import TransactiontypeCreate from './transactiontype-create.component';
import TransactiontypeView from './transactiontype-view.component';
import TransactiontypeEdit from './transactiontype-edit.component';
import TransactiontypeCopy from './transactiontype-copy.component';
import TransactiontypeDelete from './transactiontype-delete.component';

interface Props {
  root?: string;
}

export const TransactiontypeRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
        <Route path={(props.root||'')+'/transactiontype/create'} component={TransactiontypeCreate} />
        <Route path={(props.root||'')+'/transactiontype/view'} component={TransactiontypeView} />
        <Route path={(props.root||'')+'/transactiontype/edit'} component={TransactiontypeEdit} />
        <Route path={(props.root||'')+'/transactiontype/copy'} component={TransactiontypeCopy} />
        <Route path={(props.root||'')+'/transactiontype/delete'} component={TransactiontypeDelete} />
        <Route path={(props.root||'')+'/transactiontype'} render={(params)=> <TransactiontypeList {...params} root={props.root} />} /> 
    </Switch>
  );
};
