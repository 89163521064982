import * as React from 'react';
import Model from './personalinfo-model';
import BasePicklist from '../base/base-picklist.component';

interface IProps {
  personalinfotypeType?: string; 
  currentId?: number;  
  scheduleId?: number;  
  getDataRow: (data: any) => void;
}

interface IState {
}

export default class PersonalinfoPicklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  public render(): React.ReactNode {
    return (
      <BasePicklist endPointFilter={Model.endPointFilter()+(this.props.personalinfotypeType? '&filters[personalinfotype][type]='+this.props.personalinfotypeType : '')+(this.props.scheduleId? '&filters[schedule]='+this.props.scheduleId : '')} model={Model} currentId={this.props.currentId} getDataRow={this.props.getDataRow} disableBackButton/>
    );
  }
}
