import * as React from 'react';
import { History } from 'history';
import { Box, Container, Grid, Typography } from '@mui/material';
import { AppBar, Button, Card, Dialog, Table, Trapezoid, Transition, ParallaxMouseMove } from '../../../components';
import AppModel from '../../app/app-model';
import OwnerModel from '../../owner/owner-model';
import UserModel from '../../user/user-model';
import UserAuthSignin from '../../user/user-auth-signin.component';
import BaseView from '../../base/base-view.component';
import EventPicklistCard from '../../event/event-picklistcard.component';
import ProductPicklistCard from '../../product/product-picklistcard.component';
import ApplayoutLogo from '../applayout-logo.component';
import AppService from '../../../services/app.service';
import TransactionCreateByuserEvent from '../../transaction/transaction-create-byuser-event.component';

interface IProps {
  history: History;
  logoUrl?: string;
}

interface IState {
  triggerTransition: boolean;
  dataRowApp: AppModel;
  dataRowOwner: OwnerModel;
  dataRowUser: UserModel;
  eventLength: number;
  openDialog: boolean;
}

export default class Home extends React.Component<IProps, IState > {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      triggerTransition : true,
      dataRowApp: AppModel.defaultValue(),
      dataRowOwner: OwnerModel.defaultValue(),
      dataRowUser: UserModel.defaultValue(),
      eventLength: 0,
      openDialog: false,
    }
  }
  
  private isMobile: boolean = AppService.isMobile();

  private featureHeader = [
    {name: 'imageUrl', type: 'media'}
  ];

  private featureDataSet = [
    {id: 1, imageUrl: 'https://img.freepik.com/free-vector/wedding-invitation-announcement-card-with-vintage-background-artwork_1217-823.jpg?w=360&t=st=1707195724~exp=1707196324~hmac=3ddda9e98ab7edef85db20b2d0f79512fae5480bb50a031d26285dbd641f8bf9'},
    {id: 2, imageUrl: 'https://img.freepik.com/free-vector/wedding-invitation-announcement-card-with-floral-background-artwork_1217-857.jpg?w=360&t=st=1707195628~exp=1707196228~hmac=b00ca8e4a577d9292ad04d80c795344a2f47e3054ed81be0eab8b8bd7dd488a8'},
    {id: 3, imageUrl: 'https://img.freepik.com/free-vector/wedding-invitation-announcement-card-with-vintage-background-artwork_1217-822.jpg?w=360&t=st=1707195701~exp=1707196301~hmac=0ccefc208ba7df478f2ffddd9c59cd588ccbc784bd7fe40499895590e16a0d1e'},
  ];  
  
  private invitationHeader = [
    {name: 'imageUrl', type: 'media'}
  ];

  private invitationDataSet = [
    {id: 1, imageUrl: 'https://img.freepik.com/free-photo/beautiful-wedding-bouquet-flowers_1328-3611.jpg?w=360&t=st=1707194501~exp=1707195101~hmac=1533c31a1d78e86fe79eebd9fd7d112eec88d5a31dab4f19d89b9bcb7144e7f0'},
    {id: 2, imageUrl: 'https://img.freepik.com/free-photo/engagement-ring-with-stone-gentle-bride-s-hand_78826-2241.jpg?w=360&t=st=1707194658~exp=1707195258~hmac=6cc17b04b18d9291923fb54b476b158fc22d87e970b0a59af2139192fea3039a'},
    {id: 3, imageUrl: 'https://img.freepik.com/premium-photo/wedding-romantic-couple-wear-wedding-rings_62669-163.jpg?w=360'},
  ];

  private getDataRowApp = (data:any) => {
    this.setState({dataRowApp: data})
  }

  private getDataRowOwner = (data:any) => {
    this.setState({dataRowOwner: data})
  }

  private getDataRowUser = (data:any) => {
      this.setState({dataRowUser: data})
      UserModel.setUserImage(data?.imageUrl || '')
  }

  private getDataRowEvent = (data: any) => {
    if (data.eventtypeType) setTimeout(() => this.props.history.push('/'+data.eventtypeType, {id: data.id}), 1);
  };

  private getDataSetEvent = (data: any, pageInfo: any) => {
    this.setState({ eventLength: pageInfo.total})
  };

  private getDataRowProduct = (data: any) => {
    setTimeout(() => this.props.history.push('/product/viewbyuser', {id: data.id}), 1);
  };

  private onClickAppBar = () =>{
    setTimeout(() => this.setState({ triggerTransition: true}), 100);
  }

  private onScrollEndAppBar = () =>{
    this.setState({ triggerTransition: false })
  }

  private avatarClick = () =>{
    if (UserModel.isSignin()) this.props.history.push('/user/profile')
    else this.setState({openDialog: true});
  }

  private UserSignin(){
    return (
      <Dialog open={this.state.openDialog} onClose={()=>this.setState({openDialog: false})}>
        <Card borderColor='white'>
          <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.main' mt={3} mb={3}>Login</Typography>
          <UserAuthSignin caption=' ' history={this.props.history} root='/' onSave={()=>this.setState({openDialog: false})} disableBackButton disableAutoBack/>
        </Card>
      </Dialog>
    )
  }

  private header(color: any, colorOthers: any){
    return (
      <Trapezoid position='bottomLeft' color='secondary.light' colorOthers={colorOthers} fullWidth>
        <Grid container sx={{pt: {xs: 0, md:20}}}>
          <Grid item xs={12} md={6} sx={{ pl: {xs: 5, md:10}}}>
            <Transition variant='growCenter' direction='down' trigger>
              <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} component='div' mb={3}>
                <b>Ayo kita <></>
                <Grid display='inline' color='primary.light'>Kemon</Grid> !
                </b>
              </Typography>
            </Transition>
            <Transition variant='slide' direction='right' trigger>
              <Box>
                <Typography sx={{typography: {xs: 'h4', md: 'h2'}, lineHeight: {md: 0.8}}}>
                  <b>Buat acara</b>mu <br/>
                  jadi berbeda <br/>
                </Typography>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h5'}, mt:{xs: 1, md: 3}, mb:{xs: 1, md: 3}}} >
                  Dengan cara berbeda, kini kamu bisa membuat undangan secara digital kapan dan dimana saja. <br/>
                  Dapatkan berbagai Tema Gratis maupun Eksklusif hanya untuk kamu.
                </Typography>
                <Button variant='contained' color='secondary' label='Buat Undangan Gratis Sekarang !' onClick={()=>document.getElementById('products')?.scrollIntoView(false)}/>
              </Box>
            </Transition>
          </Grid>
          <Grid item xs={12} md={6}>
            <Transition variant='slide' direction='down' trigger>
              <img src="https://png.pngtree.com/png-clipart/20221121/ourmid/pngtree-illustration-of-muslim-romantic-couple-png-image_6473662.png" width='auto' height={this.isMobile? 300:450} alt='img'/>
            </Transition>
            {!this.isMobile &&
            <Transition variant='slide' direction='up' trigger>
              <Box>
                <ParallaxMouseMove imageUrl='https://images.vexels.com/media/users/3/135018/isolated/preview/1971549b5f209b1b3c68521efdd9b85c-cloud-cartoon-04-by-vexels.png' left='45%' top='25%' width='auto' height={450} alt='Parallax1' />
                <ParallaxMouseMove variant='moveAllReverse' imageUrl='https://images.vexels.com/media/users/3/135018/isolated/preview/1971549b5f209b1b3c68521efdd9b85c-cloud-cartoon-04-by-vexels.png' left='60%' top='30%' width='auto' height={450} alt='Parallax2' />
              </Box>
            </Transition>
            }
          </Grid>
          <Card borderColor={this.isMobile? 'primary.main':''} borderRadius={this.isMobile? 15 : 30} padding={this.isMobile? 3 : 5}>
          <TransactionCreateByuserEvent history={this.props.history} caption=' ' transactiontypeType='sales'/>
            
          </Card>
        </Grid>
      </Trapezoid>
    )
  }

  private events(color: any, colorOthers: any){
    return (
      <Box id='events' bgcolor={color} p={3}>
        <Transition parentId='events' trigger={this.state.triggerTransition}>
          <Container >
          {UserModel.isSignin() &&
            <Card color='secondary.light'>
              <EventPicklistCard caption='Acara Kamu' personalinfoType='customer' getDataRow={this.getDataRowEvent} getDataSet={this.getDataSetEvent}/>
            </Card>
          }
          </Container>
        </Transition>
    </Box>
    )
  }

  private products(color: any, colorOthers: any){
    return (
      <Box id='products' bgcolor={color} p={3}>
        <Transition parentId='products' variant='slide' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container spacing={2}>
              <Transition parentId='products' addTime={1000}>
                <Typography sx={{typography: {xs: 'h5', md: 'h4'}}} textAlign='center'>
                  <b>Pilih tema sesuai kebutuhan kamu</b>
                </Typography>
              </Transition>
              <Grid container item mt={2}>
                <ProductPicklistCard caption='Paling banyak dipakai' pricetype='pricesale' cardCount={3} pageSize={this.isMobile? 2 : 0} swipe='horizontal' getDataRow={this.getDataRowProduct}/>
              </Grid>
              <Grid container item mt={2}>
                <ProductPicklistCard caption='Modern' cardCount={6} pricetype='pricesale' getDataRow={this.getDataRowProduct}/>
              </Grid>
              <Grid container item mt={2}>
                <ProductPicklistCard caption='Klasik' cardCount={6} pricetype='pricesale' getDataRow={this.getDataRowProduct}/>
              </Grid>
            </Grid>
          </Container>
        </Transition>
      </Box>
    )
  }

  private features(color: any, colorOthers: any){
    return (
      <Trapezoid id='features' position='topLeft' color={color} colorOthers={colorOthers}>
        <Transition parentId='features' variant='fade' trigger={this.state.triggerTransition}>
          <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center' p={3} pb={10}>            
            <Grid item xs={12} md={3} textAlign='right'>
              <Transition parentId='features' addTime={2000}>
                <Typography sx={{typography: {xs: 'h5', md: 'h4'}, lineHeight: {xs: 1, md: 1}}} >
                  lebih banyak
                </Typography>
              </Transition>
              <Transition parentId='features' addTime={3000}>
                <Typography sx={{typography: {xs: 'h4', md: 'h3'}, lineHeight: {xs: 1, md: 1}}} >
                  <b>fitur menarik</b>
                </Typography>
              </Transition>
              <Transition parentId='features' addTime={4000}>
                <Typography sx={{typography: {xs: 'h5', md: 'h4'}, lineHeight: {xs: 1, md: 1}}} color='secondary.light'>
                undanganmu makin <br/><b>estetik</b>
                </Typography>
              </Transition>
            </Grid>
             <Table header={this.featureHeader} dataSet={this.featureDataSet} isReady cardCount={3} readOnly cardView swipe={'horizontal'}/> 
          </Grid>
        </Transition>
      </Trapezoid>
    )
  }
  private invitations(color: any, colorOthers: any){
    return (
      <Box id='invitations' bgcolor={color} p={3}>
        <Transition parentId='invitations' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container direction='row' spacing={1} alignItems='center' justifyContent='center'>    
              <Table header={this.invitationHeader} dataSet={this.invitationDataSet} isReady cardCount={3} readOnly cardView swipe={'horizontal'}/> 
              <Grid item xs={12} md={3}>
                <Transition parentId='invitations' addTime={2000}>
                  <Typography sx={{typography: {xs: 'h3', md: 'h2'}, lineHeight: {xs: 1, md: 1}}} color='primary.main' mb={1}>
                    <b>Kisah Indah</b>
                  </Typography>
                </Transition>
                <Transition parentId='invitations' addTime={3000}>
                  <Typography sx={{typography: {xs: 'h5', md: 'h4'}, lineHeight: {xs: 1, md: 1}}}>
                    yang tak terlupakan
                  </Typography>
                </Transition>
              </Grid>
            </Grid>
          </Container>
        </Transition>
      </Box>
    )
  }

  private footer(color: any, colorOthers: any){
    return (
      <Trapezoid id='footer' position='topRight' color={color} colorOthers={colorOthers} fullWidth>
        <Transition parentId='footer' trigger={this.state.triggerTransition}>
          <Grid container spacing={5} sx={{ padding: {xs: 5, md:0}, paddingBottom: {xs: 5, md:5}}}>
            <Transition parentId='footer' addTime={1000}>
              <Grid item xs={12} md={4}>
                <ApplayoutLogo/>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                {this.state.dataRowApp.description}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                {this.state.dataRowOwner.name}
                </Typography>
              </Grid>
            </Transition>
            
            <Transition parentId='footer' addTime={2000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Hubungi Kami</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Call Center <br/>
                  {this.state.dataRowOwner.phone}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Email <br/>
                  {this.state.dataRowOwner.email}
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Instagram
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Facebook
                </Typography>
              </Grid>
            </Transition>

            <Transition parentId='footer' addTime={3000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Kebijakan</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Kebijakan Pribadi
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Syarat & Ketentuan
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Sering ditanyakan
                </Typography>
              </Grid>
            </Transition>
          </Grid>
        </Transition>
      </Trapezoid>
    )
  }

  private home(){
    return (
      <>      
      <BaseView id={+AppService.appId()} caption='' 
        model={AppModel} getDataRow={this.getDataRowApp}>    
      </BaseView>
      
      <BaseView id={+OwnerModel.getOwner()} caption='' 
        model={OwnerModel} getDataRow={this.getDataRowOwner}>    
      </BaseView>

      {UserModel.isSignin() &&
        <BaseView id={+UserModel.getUser()} caption='' 
          model={UserModel} getDataRow={this.getDataRowUser}>    
        </BaseView>
      }
      
      {this.UserSignin()}
      {this.header('secondary.light', 'white')}
      {this.events('white', '')}
      {this.products('white', '')}
      {this.features('secondary.light', 'white')}
      {this.invitations('secondary.dark', '')}
      {this.footer('secondary.main', 'secondary.dark')}
      </>
    )
  }

  public componentDidMount() {
    
    //Clear The History First, Before Back To Home
    if (this.props.history.length>3){
      this.props.history.go(-this.props.history.length+2);
      this.props.history.replace('/');
    }
  }

  public render(): React.ReactNode {    
    return (
      <AppBar 
        color= 'secondary.light'
        menuColor= 'primary.main'
        logo= {<ApplayoutLogo/>}
        menu= {[{caption:UserModel.isSignin()?'Acara':'', section:'events', icon: 'event', badge: this.state.eventLength}, {caption:'Tema', section:'products', icon: 'color_lens'}, {caption:'Fitur', section:'features', icon: 'rocket'}, {caption:UserModel.isSignin()?'Dashboard':'', url:'/dashboard', icon: 'widgets'}]}
        avatar= {{name:this.state.dataRowUser.name, imageUrl:this.state.dataRowUser.imageUrl, onClick: this.avatarClick}}
        onClick={this.onClickAppBar}
        onScrollEnd={this.onScrollEndAppBar}
      >
        {this.home()}
      </AppBar>
    )
  }
}