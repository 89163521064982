import * as React from 'react';
import { History } from 'history';
import { CardMedia, Checkbox, Grid, Typography } from '@mui/material';
import { ActionButton, Button, Card, Chip, Gallery, Icon, InputBulkUpload, InputSearch, Pagination, Spinner } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  history?: History;
  header: any;
  dataSet: Array<any>;
  currentId?: any,
  getDataRow?: (data?: any) => void;
  page?:number;
  pageCount?: number; 
  actions?: any;
  searchText?: string;
  isSearch?: boolean;
  isValidateError?: boolean;
  isLoading?: boolean;
  isReady?: boolean;
  isError?: boolean;
  onChangePage?: (e: React.ChangeEvent<unknown>, value: number) => void;
  onChangeSearch?: (fieldName: string, value: string) => void;
  onChangeSwipe?: (e: React.ChangeEvent<unknown>, value: string) => void;
  onClickSearch?: () => void;
  onClickUpload?: () => void;
  onClickCreate?: (data: any) => void;
  onEndTypingSearch?: (fieldName?: string, value?: string) => void;
  cardCount?: number;
  cardBorderWidth?: number;
  cardBorderRadius?: number;
  cardBoxShadow?: number;
  cardPadding?: number;
  cardSpacing?: number;
  cardWidth?: number;
  cardAlign?: 'left'|'center'|'right';
  cardTextAlign?: 'left'|'center'|'right';
  cardNowrap?: boolean;
  cardIconOnly?: boolean;
  cardRightArrow?: boolean;
  swipe?: string;
  autoSelect?: boolean;
  checklistView?: boolean;
  cardView?: boolean;
  readOnly?: boolean;
  disablePaginationLabel?: boolean;
  
}

export const Table: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [selectedId, setSelectedId] = React.useState<any>(props.currentId);
  const [checkAll, setCheckAll] = React.useState<boolean>(false);
  const [isUpload, setIsUpload] = React.useState<boolean>(true);
  const [cardWidth, setCardWidth] = React.useState(50);
  
  const findId = (id: number) => {
    return selectedId.findIndex((d: { id: number}) => d.id === id);
  }

  const findAction = (action: string) => {
   return props.actions?.filter((d: any) => d.id === action)?.[0];
  }

  const onClick = (dataRow: any) => (e: React.MouseEvent<HTMLButtonElement>)=>{
    if (props.readOnly) return;
    props.getDataRow?.(dataRow);
    if (!props.actions || props.actions.length>1) setAnchorEl(e.currentTarget);
  };
  
  const onClickCard = (dataRow: any) =>{
    if (!props.readOnly)
    if (props.checklistView) {
      var idx = findId(dataRow.id);
      if (idx<0) selectedId.unshift(dataRow);
      else selectedId.splice(idx, 1);
      props.getDataRow?.(selectedId);
    }
    else {
      setSelectedId(dataRow.id);
      props.getDataRow?.(dataRow);
    }
  };

  const onClickCheckAll = () =>{
    const selectedId = checkAll? [] : props.dataSet;
    setSelectedId(selectedId);
    props.getDataRow?.(selectedId);
    setCheckAll(!checkAll);
  }
  
  const onClickUpload = (e: React.MouseEvent<HTMLButtonElement>) =>{
    props.onClickUpload?.();
    setIsUpload(false);
  }

  const getDataSetUpload = (dataRow: any) => {
    setIsUpload(true);
    props.getDataRow?.(dataRow)
  }

  const getCardWidth = (value: number) => {
    setCardWidth(value);
  }

  const tableColumn = () => {
    return (
      <tr>
        {props.checklistView && <th > {!props.readOnly && props.dataSet.length>0 && <Checkbox sx={{padding: isMobile? 0.6 : 1.1, '& .MuiSvgIcon-root': {fontSize: isMobile? 20: 28}, margin: -0.5, marginBottom: isMobile? -0.5: -1.5}} checked={checkAll} onChange={onClickCheckAll}/>} </th>}
          
        {props.header.map((column: any, i: number) => {
          if (column.caption){
            switch (column.type) {
              case 'number':    
              case 'percentage':       
              case 'money': return <th key={i} className='text-end'> {column.caption}&nbsp;&nbsp;</th>          
              case 'state':
              case 'validate': return <th key={i} className='text-center'>{column.caption}</th>
              default : return <th key={i}>{column.caption}</th>
            }
          }
        })}
      </tr>
    )
  }

  const tableContent = () => {
    if (!props.isReady && !props.dataSet[0])
      return <tr><td className='text-center' colSpan={props.header.length+1}><Spinner/></td></tr>
    
    if (props.isError) 
      return <tr><td className='text-center' colSpan={props.header.length+1}><Chip label='Sorry.. Unable to load data, there is something error.' size='small' color='default'/></td></tr>

    if (props.dataSet.length===0) 
      return <tr><td className='text-center' colSpan={props.header.length+1}><Chip label='no options' size='small' color='default'/></td></tr>
    
    return props.dataSet.map((dataRow: any, i: number) => {
      return (
        <tr key={i} style={{verticalAlign: 'middle', backgroundColor: dataRow.id>0 && selectedId===dataRow.id? 'ButtonFace' : ''}} >
          {props.checklistView && <td width={isMobile? 25 : 50} ><Checkbox id={i.toString()} sx={{padding: isMobile? 0.6 : 1.1, '& .MuiSvgIcon-root': {fontSize: isMobile? 20: 28}, margin: -0.5}} checked={findId(dataRow.id)>=0} onChange={()=>onClickCard(dataRow)}/> </td>}
          {props.header.map((column: any, j: number) => {
            if (j===0 && !props.checklistView && !props.readOnly)
              return <td key={j}><Button id={dataRow?.id || null} sx={{textAlign: 'left', padding: 1, paddingLeft: 1, paddingRight: 1, borderRadius: 1}} onClick={onClick(dataRow)} label={dataRow[column.name]}/></td>
            else
              switch (column.type) {
                case 'state': return <td key={j} className='text-center'> <Chip label={dataRow[column.name]} color={dataRow[column.name]===column.default? 'info':'default'} /></td>                  
                case 'number': return <td key={j} className='text-end'> {dataRow[column.name]}&nbsp;&nbsp;</td> 
                case 'money': return <td key={j} className='text-end'> {AppService.strToMoney(dataRow[column.name])}&nbsp;&nbsp;</td> 
                case 'percentage': return <td key={j} className='text-end'> {dataRow[column.name]? dataRow[column.name]+'%' : ''}&nbsp;&nbsp;</td> 
                case 'date': return <td key={j}> {AppService.dateFormat(dataRow[column.name],'d mon y')}</td> 
                case 'time': return <td key={j}> {AppService.dateFormat(dataRow[column.name],'t')}</td> 
                case 'time2': return <td key={j}> {AppService.dateFormat(dataRow[column.name],'h:n')}</td> 
                case 'datetime': return <td key={j}> {AppService.dateFormat(dataRow[column.name],'d mon y, h:n')}</td> 
                case 'validate': return <td key={j} className='text-center'> <Chip label={AppService.strCapitalize(dataRow[column.name]||column.default)} size={isMobile? 'small' : 'medium'} color={dataRow[column.name]? 'error':'default'} /></td>                  
                case 'error': return <td key={j} className='text-danger'> {dataRow[column.name]}</td> 
                default : return <td key={j}> {dataRow[column.name]?.length>100? dataRow[column.name].substring(0,100)+' ...':dataRow[column.name]} </td>
              }
          })}
        </tr>
      )
    });

  };

  const tableContentCardView = () => {
    if (!props.isReady && !props.dataSet[0])
      return <Spinner/>

    if (props.isError) 
      return <div><Chip label='Sorry.. Unable to load data, there is something error.' size='small' color='default'/></div>

    if (props.dataSet.length===0)
      return  <Grid key={0} item xs={12}> <Chip label='no options' size='small' color='default'/> </Grid>
    
    return props.dataSet.map((dataRow: any, i: number) =>{
      let popOver: string = '';
      let cardMedia: React.ReactNode = '';
      let cardIcon: React.ReactNode = '';
      let cardContent: React.ReactNode = '';
      let cardSelected = props.checklistView? findId(dataRow.id)>=0 : ((!selectedId) && props.autoSelect && (i===0)) || (selectedId===dataRow.id);
      
      props.header.forEach((column: any, j: number) => {  
        if (dataRow[column.name])
        if (column.type==='state')
          cardContent = <>{cardContent} <Chip key={j} label={dataRow[column.name]} size={isMobile? 'small' : 'medium'} color={dataRow[column.name]===column.default? 'info':'default'} sx={{marginTop: 1, marginBottom: 1}} /> </>            
        else if (column.type==='caption') {
          cardContent = <>{cardContent} <Grid container direction='row' alignItems='center'>{cardIcon}<Grid item xs md><Typography key={j} sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs: 1, md: 1}}} textAlign={dataRow[column.name].length===1? 'center' : props.cardTextAlign}><b>{dataRow[column.name]}</b></Typography></Grid></Grid></>
          popOver = props.cardIconOnly? dataRow[column.name] : undefined;
        }
        else if (column.type==='number')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary' mt={cardContent? 1 : 0}> {dataRow[column.name]}</Typography>  </>                
        else if (column.type==='money')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary' mt={cardContent? 1 : 0}> {AppService.currencySymbol + ' ' + AppService.strToMoney(dataRow[column.name])}&nbsp;&nbsp;</Typography></>                
        else if (column.type==='percentage')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary' mt={cardContent? 1 : 0}> {dataRow[column.name]? dataRow[column.name]+'%' : ''}</Typography>  </>                
        else if (column.type==='date')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary' mt={cardContent? 1 : 0}> <Icon name='calendar_month'/> {AppService.dateFormat(dataRow[column.name],'d mon y')}&nbsp;&nbsp;</Typography></>                
        else if (column.type==='time')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary' mt={cardContent? 1 : 0}> <Icon name='schedule'/> {AppService.dateFormat(dataRow[column.name],'t')}&nbsp;&nbsp;</Typography></>                
        else if (column.type==='time2')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary' mt={cardContent? 1 : 0}> <Icon name='schedule'/> {AppService.dateFormat(dataRow[column.name],'h:n')}&nbsp;&nbsp;</Typography></>                
        else if (column.type==='datetime')
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'body2', md: 'subtitle1'}}} color='text.secondary' mt={cardContent? 1 : 0}> <Icon name='calendar_month'/> {AppService.dateFormat(dataRow[column.name],'d mon y, h:n')}&nbsp;&nbsp;</Typography></>                
        else if (column.type==='icon')
          cardIcon = <Icon name={dataRow[column.name]} size={props.cardIconOnly? 'medium' : 'large'} marginRight/>              
        else if (column.type==='media')
          cardMedia = <CardMedia key={j} image={dataRow[column.name]} width='100%' height='auto' component='img'/> 
        else 
          cardContent = <>{cardContent} <Typography key={j} sx={{typography: {xs: 'caption', md: 'body2'}, lineHeight: {xs: 1, md: 1}}} color='text.secondary' textAlign={props.cardTextAlign} mt={cardContent? 1 : 0}>{dataRow[column.name]?.length>100? dataRow[column.name].substring(0,100)+' ...':dataRow[column.name]}</Typography></>
      })
      
      if (props.cardRightArrow) 
        cardContent = <Grid container alignItems='center' justifyContent='space-between'><Grid>{cardContent}</Grid><Icon name='navigate_next' /></Grid>
      
      return (
        <Grid key={i} item width={props.cardIconOnly? 80: cardWidth} height={props.cardIconOnly? 80: 'auto'} sx={{cursor: 'pointer', pointerEvents: (props.readOnly? 'none' : 'auto')}} onClick={()=>onClickCard(dataRow)}> 
            <Card color={cardSelected? 'secondary.dark' : 'white'} popOver={popOver} borderColor= {cardSelected? 'secondary.dark':''} borderWidth={(props.cardBorderWidth||0) + (cardSelected? 4:0)} borderRadius={props.cardIconOnly? 50: props.cardBorderRadius} boxShadow={props.cardBoxShadow || 1} padding={props.cardPadding} fullHeight media={cardMedia}>
              {props.cardIconOnly? cardIcon : cardContent }
            </Card>
        </Grid>
      )
    });

  };

  const table = () =>{
    const actionCreate = findAction('create');
    const actionUpload = findAction('upload');
    
    if (props.cardView) return (
      <Gallery dataLength={props.dataSet.length} cardCount={props.cardCount} cardBorderWidth={props.cardBorderWidth} spacing={props.cardSpacing} justifyContent={props.cardIconOnly? 'center' : props.cardAlign} nowrap={props.cardNowrap} swipe={props.swipe} getCardWidth={getCardWidth} onChange={props.onChangeSwipe}>
        {tableContentCardView()}   
      </Gallery>
    )
    else return (
      <>
      {((props.pageCount||0)>1 || props.searchText) && props.isSearch &&
        <InputSearch name='searchText' value={props.searchText} onChange={props.onChangeSearch} onClick={props.onClickSearch} onEndTyping={props.onEndTypingSearch} readOnly={props.isLoading}/>
      }
      {actionCreate &&
        <Button variant='outlined' startIcon={<Icon name='add'/>} onClick={()=>{props.onClickCreate?.(actionCreate)}} label='Add New' /> 
      }
      {actionUpload &&
        <InputBulkUpload name='upload' label='Select file' getDataSet={getDataSetUpload} buttonView/>
      }
      <Typography component='div' sx={{typography: {xs: 'caption', md: 'body2'}}}>
        <table className='table table-sm table-hover mt-3'>
          <thead>
            {tableColumn()}
          </thead>
          <tbody>
            {tableContent()}
          </tbody>
        </table>
      </Typography>
      </>
    )
  }

  React.useEffect(()=>{ 
    if (!props.checklistView && props.currentId && props.currentId!== +selectedId) setSelectedId(props.currentId);
    if (props.checklistView && props.currentId && props.currentId!==selectedId) setSelectedId(props.currentId);
    if (props.checklistView) setCheckAll(false);
    
  }, [props.currentId, props.checklistView, selectedId])

  return (
    <>
    {table()}
    {!props.isLoading && 
      <Pagination pageCount={props.pageCount} page={props.page} disablePageLabel={props.disablePaginationLabel} disablePageButtons={props.cardIconOnly} onChange={props.onChangePage}/>
    }
    {findAction('upload') && !props.isValidateError && props.isReady && isUpload &&
      <Button variant='contained' sx={{marginTop: 2, marginBottom: 3}} startIcon={<Icon name='cloud_upload'/>} onClick={onClickUpload} label='Upload' /> 
    } 
    {props.actions &&
      <ActionButton history={props.history} anchorEl={anchorEl} action= {props.actions} onClose={()=>setAnchorEl(null)} />      
    }
    </>
  )
};
