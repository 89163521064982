import OwnerModel from '../owner/owner-model';
import UserModel from '../user/user-model';

export default class MenugroupModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    menu: any;
    menuCaption: string;
    accessowner: any;
    accessownerCaption: string;
    user: any;
    userCaption: string;
    icon: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.menu = dataRow.attributes.menu.data || null;
        this.menuCaption = this.menu?.length===0? '' : this.menu?.[0]?.attributes.name + (this.menu?.length>1? ', ...': '');      
        this.accessowner = dataRow.attributes.accessowner.data || null;
        this.accessownerCaption = this.accessowner?.length===0? '' : this.accessowner?.[0]?.attributes.name + (this.accessowner?.length>1? ', ...': '');      
        this.user = dataRow.attributes.user.data || null;
        this.userCaption = this.user?.length===0? '' : this.user?.[0]?.attributes.name + (this.user?.length>1? ', ...': '');
        this.icon = dataRow.attributes.icon || 'window';
                
    } 

    public static endPoint: string = 'menu-groups' 
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[owner]='+owner);
    }  
    public static endPointFilter2 = ()=>{
        const owner = OwnerModel.getOwner();
        const user = UserModel.getUser();
        return ( '?populate=*&filters[accessowner][id]='+owner+'&filters[user][id]='+user)
    } 
    public static url: string = '/menugroup';
    public static caption: string = 'Menu Group';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: +OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        menu: [],
        menuCaption: '',   
        accessowner: [{id: +(OwnerModel.getOwner()||0)}],
        accessownerCaption: '',   
        user: [],
        userCaption: '',   
        icon: 'window',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static columnPicklistCard = [
        { name: 'icon', type: 'icon' },
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

}