import * as React from 'react';
import Model from './event-model';
import BasePicklist from '../base/base-picklist.component';

interface IProps { 
  currentId?: number;  
  astemplate?: string;
  getDataRow: (data: any) => void;
}

interface IState {
}

export default class EventPicklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }



  public render(): React.ReactNode {
    return (
      <BasePicklist endPointFilter={Model.endPointFilter(this.props.astemplate)} model={Model} currentId={this.props.currentId} getDataRow={this.props.getDataRow} disableBackButton/>
    );
  }
}
