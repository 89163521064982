import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PersonalinfoList from './personalinfo-list.component';
import PersonalinfoCreate from './personalinfo-create.component';
import PersonalinfoView from './personalinfo-view.component';
import PersonalinfoEdit from './personalinfo-edit.component';
import PersonalinfoCopy from './personalinfo-copy.component';
import PersonalinfoDelete from './personalinfo-delete.component';

interface Props {
  root?: string;
}

export const PersonalinfoRouteVendor: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>     
      <Route path={`${props.root||''}/vendor/create`} render={(params)=> <PersonalinfoCreate {...params} key={params.history.location.key} root={props.root} caption='Create Vendor' personalinfotypeType='vendor' />} />    
      <Route path={`${props.root||''}/vendor/view`} render={(params)=> <PersonalinfoView {...params} key={params.history.location.key} root={props.root} caption='View Vendor' />} />        
      <Route path={`${props.root||''}/vendor/edit`} render={(params)=> <PersonalinfoEdit {...params} key={params.history.location.key} root={props.root} caption='Edit Vendor' personalinfotypeType='vendor' />} />        
      <Route path={`${props.root||''}/vendor/copy`} render={(params)=> <PersonalinfoCopy {...params} key={params.history.location.key} root={props.root} caption='Duplicate Vendor' personalinfotypeType='vendor' />} />     
      <Route path={`${props.root||''}/vendor/delete`} render={(params)=> <PersonalinfoDelete {...params} key={params.history.location.key} root={props.root} caption='Delete Vendor'/>} />        
      <Route path={(props.root||'')+'/vendor'} render={(params)=> <PersonalinfoList {...params} key={params.history.location.key} root={props.root} caption='Vendor' personalinfotypeType='vendor' />} /> 
    </Switch>
  );
};
