import React from 'react';
import { History } from 'history';
import { InputSelect, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './journalgroupdetail-model';  //editable..
import BaseView from '../base/base-view.component';
import JournalgroupModel from '../journalgroup/journalgroup-model';
import TransactiontypePicklist from '../transactiontype/transactiontype-picklist.component';
import CoaPicklist from '../coa/coa-picklist.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class JournalgroupdetailCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;
    
    private getDataRowJournalgroup = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'journalgroup': data?.id || null, 'journalgroupCaption': data?.name || ''  }  });
    };
    
    private getDataRowTransactiontype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transactiontype': data?.id || null, 'transactiontypeCaption': data?.name || ''  }  });
    };
    
    private getDataRowCoa = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'coa': data?.id || null, 'coaCaption': data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(): void {        
        this.getDataRowJournalgroup({'id': this.historyState?.id});
    }

    render() {
        return (
            //editable..   
            <>
            {this.historyState?.id>0 &&
            <BaseView history={this.props.history} caption=''
                model={JournalgroupModel} getDataRow={this.getDataRowJournalgroup}/>
            }                 
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Picklist name='transactiontype' label='Transaction Type' value={this.state.dataRow.transactiontype} caption={this.state.dataRow.transactiontypeCaption} error={this.state.dataError?.transactiontype} onChange={this.onChange} >
                    <TransactiontypePicklist currentId={this.state.dataRow.transactiontype} getDataRow={this.getDataRowTransactiontype}/>
                </Picklist>   
                <InputSelect name='sign' label='Debet/Credit' value={this.state.dataRow.sign} options={Model.optionSign} error={this.state.dataError?.sign} onChange={this.onChange} />
                <Picklist name='coa' label='COA' value={this.state.dataRow.coa} caption={this.state.dataRow.coaCaption} error={this.state.dataError?.coa} onChange={this.onChange} >
                    <CoaPicklist currentId={this.state.dataRow.coa} getDataRow={this.getDataRowCoa}/>
                </Picklist> 
            </BaseCreate>
            </>
        );
    }     
     
}