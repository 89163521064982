import * as React from 'react';
import { Button, Box, Card as CardMui, CardMedia, Grid, IconButton, Typography} from '@mui/material';
import { Icon } from '.';

interface Props {
  id?: string;
  name: string;
  label?: string;
  value: string;
  type?: 'image'|'video'|'audio';
  readOnly?: boolean;
  getFiles?: (data: any) => void;
  encode?: boolean;
  maxHeight?: number;
  circle?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
  children?: React.ReactNode;
}

export const InputFile: React.FunctionComponent<Props> = (props) => {
  const [value, setValue] = React.useState<any>(null);
  const [type, setType] = React.useState<string>(props.type||'');
  const inputRef = React.useRef<any>();

  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    if (e.target.files?.length===1) {
      var currentFiles: any = e.target.files[0];
      const currentType = currentFiles?.type?.split('/')[0];
      const currentValue = currentType==='image'? URL.createObjectURL(currentFiles) : currentFiles?.name;
      const reader = new FileReader();

      if (props.encode) {
        reader.readAsDataURL(currentFiles); 
        reader.onload = () => {
          currentFiles.path = reader.result; 
          currentFiles.filename = currentFiles?.name;
        }
      }
      setValue(currentValue);
      setType(currentType);
      props.onChange?.(props.name, currentValue);
      props.getFiles?.(currentFiles);
    }
  };

  const onDelete = () => {
    setValue(null);
    inputRef.current.value = null;
    props.getFiles?.(null);
  }

  const iconName = () => {
    switch (type){
      case 'image': return props.readOnly? 'hide_image' : 'add_photo_alternate';
      case 'audio': return value? 'audiotrack' : 'music_off';
      case 'video': return value? 'videocam' : 'videocam_off';
      default: return 'file_present';      
    }
  }

  React.useEffect(() => {
    setValue(props.value);

  },[props.value])
  
  return (
    <Grid id={props.id || props.name}>   
      <Typography variant='caption' color='text.secondary' display='block'>
        {props.label} 
      </Typography>
      <Grid container={!props.readOnly}>
      <Button component='label' disabled={props.readOnly} sx={{padding: props.label? 1 : 0}}>
        <input hidden accept={props.type? props.type+'/*' : '*'} type='file' ref={inputRef} onChange={onChange(props)}/>
        {props.encode && !value? <Icon name='attach_file' color={props.readOnly? 'gray' : 'primary.main'}/>
        :
          <CardMui variant='outlined' sx={{ minHeight: props.maxHeight || 150, maxHeight: props.maxHeight || 280, minWidth: props.maxHeight || 150, maxWidth: props.maxHeight || 280, backgroundColor: 'whitesmoke', borderRadius: props.circle? '50%':''}}>
            {value?.includes('http')? <CardMedia component='img' image={value} /> 
            : <Typography component='div' textAlign='center' textTransform='lowercase' m={1} mt={5}><Icon name={iconName()} color={props.readOnly? 'gray' : 'primary.light'} size='large'/><br/>{value}</Typography>
            }
          </CardMui>
        }
      </Button>    
      {value && !props.readOnly ? 
        <Box sx={{ position: 'absolute', backgroundColor:'red', borderRadius:'50%'}} >
          <IconButton onClick={onDelete}><Icon name='delete_forever' size='small' color='white'/></IconButton>
        </Box>
      :''}
      </Grid>  
    </Grid>    
  )
};


