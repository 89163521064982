import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import ScheduleList from './schedule-list.component';
import ScheduleCreate from './schedule-create.component';
import ScheduleView from './schedule-view.component';
import ScheduleEdit from './schedule-edit.component';
import ScheduleCopy from './schedule-copy.component';
import ScheduleDelete from './schedule-delete.component';

interface Props {
  root?: string;
}

export const ScheduleRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/schedule/create'} component={ScheduleCreate} />
      <Route path={(props.root||'')+'/schedule/view'} component={ScheduleView} />
      <Route path={(props.root||'')+'/schedule/edit'} component={ScheduleEdit} />
      <Route path={(props.root||'')+'/schedule/copy'} component={ScheduleCopy} />
      <Route path={(props.root||'')+'/schedule/delete'} component={ScheduleDelete} />
      <Route path={(props.root||'')+'/schedule'} render={(params)=> <ScheduleList {...params} root={props.root} />} /> 
    </Switch>
  );
};
