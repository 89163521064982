import React from 'react';
import { History } from 'history';
import { Input, InputFile, InputNumber, InputMultiline, InputSelect, Layout, Switch } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './question-model';  //editable..
import QuestionoptionList from '../questionoption/questionoption-list.component';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class QuestionView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} 
                model={Model} getDataRow={this.getDataRow} disableCard>              
                
                <Layout history={this.props.history} content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                        <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' readOnly/>
                        <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} readOnly/>
                        <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                        
                    </>
                    },

                    {caption: 'Question Options', child: 
                    this.state.dataRow.id>0 &&
                        <QuestionoptionList history={this.props.history} root={this.props.root} questionId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>                            
                    },
                    ]}

                />
                             
            </BaseView>
        );
    }
}