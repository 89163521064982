import OwnerModel from '../owner/owner-model';

export default class EventresultModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    title: string;
    content: string;
    seq: number;
    valuemin: number;
    valuemax: number;
    scale: number;
    factor: number;
    tag: string;
    questionoption: any;
    questionoptionCaption: string;
    questionoptionValue: number;
    eventresult: any;
    eventresultCaption: string;
    eventsection: any;
    eventsectionCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.seq = dataRow.attributes.seq;
        this.valuemin = dataRow.attributes.valuemin;
        this.valuemax = dataRow.attributes.valuemax;
        this.scale = dataRow.attributes.scale;
        this.factor = dataRow.attributes.factor<=0? 1: dataRow.attributes.factor;
        this.tag = dataRow.attributes.tag;
        this.questionoption =  dataRow?.attributes.questionoption?.data || null;
        this.questionoptionCaption =  '';
        this.questionoptionValue = 
            this.questionoption?.reduce( (t: any, d: any) => {
                var id = d.attributes.question?.data?.id;
                var idx = t.findIndex((d2: any) => d2.id === id);
                if(d.attributes.question?.data?.attributes.type === 'multiplematch') {
                    if (idx<0) t.push( {id: id, value: d.attributes.value })
                    else t[idx].value = t[idx].value===0 || d.attributes.value===0? 0 : 1;
                }
                else if (d.attributes.value>0) t.push( {id: id, value: d.attributes.value });
                return t;
            }, []).reduce( (v: number, d: any) => { return v + d.value; }, 0) || 0;
        this.questionoptionValue = Math.round(this.questionoptionValue/this.factor);
        this.eventresult = dataRow.attributes.eventresult?.data?.filter((d: any) => this.questionoptionValue>= d.attributes.valuemin && this.questionoptionValue<= d.attributes.valuemax)|| null;
        this.eventresultCaption =  '';
        this.eventsection = this.questionoption?.[0]?.attributes.question?.data?.attributes.eventsection?.data || null;
        this.eventsectionCaption =  '';
        
    } 

    public static endPoint: string = 'event-results' 
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[owner]='+owner+'&filters[parent][id][$null]=true');
    } 
    public static endPointFilter2: string = '?populate=*&filters[parent]=';
    
    public static endPointFilter3: string = '?sort=seq'+
                                            '&populate[eventresult][sort]=seq'+
                                            '&populate[questionoption][populate][question][populate]=eventsection'+
                                            '&filters[parent][id][$null]=true'+                                           
                                            '&populate[questionoption][filters][eventquestion][name][$startsWith]=';
    public static url: string = '/eventresult';
    public static caption: string = 'Event Result';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),   
        title: '',
        content: '',
        seq: 0,
        valuemin: 0,
        valuemax: 0,
        scale: 0,
        factor: 1,
        tag: '',
        questionoption: null,
        questionoptionCaption: '',
        questionoptionValue: 0,  
        eventresult: null,
        eventresultCaption: '',
        eventsection: null,
        eventsectionCaption: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]
}