import React from 'react';
import {Route, Switch } from 'react-router-dom';
import { Box } from '@mui/material';
import Home from './home.component';
import ApplayoutThemes from '../applayout-themes.component';

interface IProps { 
    getThemes: (data: any) => void;
}

interface IState {
}

export default class Index extends React.Component<IProps, IState> {

    constructor(props: IProps) {
      super(props);
      this.state = {
      }
      this.props.getThemes(ApplayoutThemes(''))
    }

    render() {
      return (
        //editable..
        <Box overflow='hidden'>
          <Switch>
          <Route path='/' component={Home} exact/>  
          </Switch>
        </Box>
      )
    }
}