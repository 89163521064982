import React from 'react';
import { History } from 'history';
import { InputDate, InputMoney, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './coabalance-model';  //editable..
import CoaPicklist from '../coa/coa-picklist.component';
                
interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class CoabalanceCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataRowCoa = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'coa': data?.id || null, 'coaCaption': data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <InputDate name='balancedate' label='Date' value={this.state.dataRow.balancedate} error={this.state.dataError?.balancedate} onChange={this.onChange} />
                <InputMoney name='value' label='Value' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onChange} />
                <Picklist name='coa' label='COA' value={this.state.dataRow.coa} caption={this.state.dataRow.coaCaption} error={this.state.dataError?.coa} onChange={this.onChange} >
                    <CoaPicklist currentId={this.state.dataRow.coa} getDataRow={this.getDataRowCoa}/>
                </Picklist>         
            </BaseCreate>
        );
    }     
     
}