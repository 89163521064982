import * as React from 'react';
import Model from './event-model';
import BasePicklistCard from '../base/base-picklistcard.component';

interface IProps {
  caption?: string;
  currentId?: number;
  astemplate?: string;
  personalinfoType?: string;
  getDataRow?: (data: any) => void;
  getDataSet?: (data: any, page?: any) => void;
}

interface IState {
}

export default class EventPicklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private getDataRow = (data: any) => {
    this.props.getDataRow?.(data)
  };

  public render(): React.ReactNode {
    return (
      <BasePicklistCard caption={this.props.caption} captionColor='primary.light' endPointFilter={Model.endPointFilter5(this.props.personalinfoType)} model={Model} currentId={this.props.currentId} getDataRow={this.getDataRow} getDataSet={this.props.getDataSet} header={Model.columnPicklistCard} cardCount={4} swipe='horizontal' disableBackButton/>
    );
  }
}
