import OwnerModel from '../owner/owner-model';
import AppService from "../../services/app.service";
import ApiService from '../../services/api.service';

export default class AppModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    expireddate: any;
    image: any;
    imageId: number;
    imageUrl: string;    
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.expireddate = dataRow.attributes.expireddate;
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ApiService.svrUrl+this.image.attributes.url : '';
        

    } 

    public static endPoint: string = 'apps';
    public static endPointMedia: string = 'api::app.app';
    public static url: string = '/app';
    public static caption: string = 'Application';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),      
        expireddate: AppService.dateCurrent(),
        image: null,
        imageId: 0,
        imageUrl: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]
}