import OwnerModel from '../owner/owner-model';

export default class JournalgroupModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        
    } 

    public static endPoint: string = 'journal-groups' 
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[owner]='+owner);
    } 
    public static url: string = '/journalgroup';
    public static caption: string = 'Journal Group';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

}