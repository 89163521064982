import * as React from 'react';
import { Link } from 'react-router-dom';
import {AppBar as AppBarMui, Badge, Grid, Toolbar, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Icon, InputFile, Transition } from '.';
import AppService from '../services/app.service';

interface Props {
  color?: any;
  logo: React.ReactNode;
  avatar?:{
    name?: string,
    imageUrl?: string,
    onClick?: () => void,
  }
  menu?: {
    caption: string, 
    url?: string,
    section?: string,
    icon?: string,
    badge?: number,
  }[];
  onClick?: () => void;
  onScrollEnd?: () => void;
  menuColor?: any;
  window?: () => Window;
  children: React.ReactNode;
}


export const AppBar: React.FunctionComponent<Props> = (props) => {
  const [isMobile, setIsMobile] = React.useState(AppService.isMobile());
  const [isOnTop, setIsOnTop] = React.useState(true);
  const menuColor = props.menuColor || 'primary.contrastText';
  const isWebSignin = !isMobile && props.avatar?.name;

  const logo = () => {
    return (
      <Typography component='div' sx={{typography: {xs: 'subtitle1', md: 'h6', flexGrow: 1 }}}>
        {props.logo}
      </Typography>
    )
  }

  const menu = () => {
    if (props.menu)
    return (
      <>
      {props.menu?.map(menu => {
        if (menu.caption!=='')
        return (
          <Link key={menu.caption} to={menu.url||'/'} style={{textDecoration: 'none'}} onClick={()=>goSection(menu.section||'')}>
            <Typography sx={{typography: {xs: 'caption', md: 'h6'}, lineHeight: {xs: 1, md: 1}}} color= {menuColor} textAlign='center' mr={3}>
              <Badge color='warning' badgeContent={menu.badge} anchorOrigin={{vertical: 'top', horizontal: 'left'}}>
              {menu.icon && <Icon name={menu.icon} size={isMobile? 'medium' : 'small'} marginRight={!isMobile} />} 
              </Badge>
              {menu.icon && isMobile && <br/>} {menu.caption}
            </Typography>
          </Link>
        )
      })}
      </>
    );
  }

  const avatar = () => {
    
    if (props.avatar)
    return (
      <Grid>
      { isWebSignin && 
      <Typography variant='overline' color={props.color} bgcolor={menuColor} borderRadius={20} padding={2} paddingRight={6} marginRight={-6}>
        {props.avatar.name}
      </Typography>
      }
      <IconButton size='large' color='secondary' onClick={props.avatar.onClick} sx={{padding:0}}>
        {props.avatar.imageUrl? <InputFile name='imageUrl' value={props.avatar.imageUrl ||''} type='image' maxHeight={50} circle readOnly/>
        : <Icon name='account_circle' size='large' color={isWebSignin? props.color : menuColor}/>
        }
      </IconButton>
      </Grid>
    )
  }
  
  const goSection = async (id: string) => {
    await props.onClick?.();
    document.getElementById(id)?.scrollIntoView(true);
    await props.onScrollEnd?.();
  };

  const onResize = React.useCallback((e: UIEvent) => {setIsMobile(AppService.isMobile())}, []);  
  const onScroll = React.useCallback((e: Event) => {setIsOnTop(window.scrollY<100)}, []);  

  React.useEffect(() => {
    window.addEventListener('resize', onResize);
    window.addEventListener('scroll', onScroll);

    return () => {
      window.addEventListener('resize', onResize);
      window.addEventListener('scroll', onScroll);
    }
    
  },[onResize, onScroll])

  return (
    <>
    
    {isMobile?
    <>
      <Transition variant='hideOnScroll' >
        <AppBarMui sx={{ top: 'auto', bottom: 0, borderTop: 1}}>
          <Toolbar sx={{backgroundColor : props.color, justifyContent:'space-around', padding: 1}}>
            {menu()}
            {avatar()}
          </Toolbar>
        </AppBarMui>
      </Transition>
      <Toolbar sx={{backgroundColor : props.color || 'primary.main', padding:3}}> {logo()} </Toolbar>
    </>

    : //Web view
    <>
      <Transition variant='hideOnScroll' direction={'down'}>
        <AppBarMui elevation= {isOnTop? 0:4} sx={{background : isOnTop? 'transparent' : ''}}>
          <Toolbar sx={{backgroundColor : isOnTop? '' : props.color}}>
            {logo()}
            {menu()}
            {avatar()}
          </Toolbar>
        </AppBarMui>
      </Transition>
    </>
    }
    
     {props.children}
    </>
  );
}