import * as React from 'react';
import { History } from 'history';
import { Grid, Typography  } from '@mui/material';
import { Card, Pagination, ProgressBar, Spinner, TextToHtml } from '../../components';
import Model from './eventsection-model'; //editable..
import EventquestionModel from '../event/event-model';
import BaseList from '../base/base-list.component';
import EventCreateByuser from '../event/event-create-byuser.component';
import QuestionListByuser from '../question/question-list-byuser.component';

interface IProps {  
  history: History;
  root?: string;
  parentId?: number;
  eventParentId?: number;
  eventId: number;
  viewStyle: string;
  getDataRow?: (data: any) => void;
  
}

interface IState {
  dataSet: Array<Model>;
  dataRowEventquestion: EventquestionModel;
  pageInfo: any;
  pageSize: number;
  page: number;
  isReady: boolean;
}

export default class EventsectionListByuser extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    
    this.state = {
      dataSet: [],
      dataRowEventquestion: EventquestionModel.defaultValue(),
      pageInfo: null,
      pageSize: 20,
      page: 1,
      isReady: false,
    }
  }

  private historyState: any = this.props.history.location.state;

  private getDataSet = (data:any, pageInfo: any) => {
    var finish = data?.length>0 && data?.filter((d: any) => (d?.eventEnddate===null)).length===0;
    
    this.setState({dataSet: data, pageInfo: pageInfo, isReady: true});
    this.props.getDataRow?.({'total':pageInfo.total, 'finish': finish});
    document.getElementById('Top')?.scrollIntoView(false);
  }

  private onChangePage = async(e: React.ChangeEvent<unknown>, value: number) => {
    await this.props.history.replace(this.props.history.location.pathname, {...this.historyState, page: value})
    this.setState({page: value}); 
  };

  public componentDidMount() {
    if (this.historyState?.page) this.setState({page: this.historyState?.page})
  }

  public render(): React.ReactNode {
    var isNext: boolean = true;
    
    return (
      <Grid container id='Top' justifyContent='center' spacing={1} m={0} mt={1}>
        {this.props.parentId?
          <BaseList history={this.props.history} parentId={this.props.parentId} caption=' ' endPointFilter={Model.endPointFilter4(this.props.eventId || 0)+this.props.parentId} model={Model} page={this.state.page} pageSize={this.state.pageSize} getDataSet={this.getDataSet} disableView/>
          : <BaseList history={this.props.history} caption=' ' endPointFilter={Model.endPointFilter5(this.props.eventId || 0)+this.props.eventParentId} model={Model} page={this.state.page} pageSize={this.state.pageSize} getDataSet={this.getDataSet} disableView/>
        }
        
        {!this.state.isReady && <Spinner/>}
        {this.state.dataSet.map((dataRow: any) => { 
          if (dataRow.id>0 && isNext) {
            if (this.props.viewStyle.includes('onebyone') && (!dataRow.eventEnddate)) isNext = false;

            if (!(dataRow.questionLength===1 && dataRow.eventEnddate))
            return (
                <Card key={dataRow.id} caption={this.props.viewStyle.includes('editable')? dataRow.name : dataRow.title}>
                  <Grid container={!this.props.viewStyle.includes('editable')} justifyContent='center'>
                    {dataRow.questionLength>1 &&
                      <ProgressBar value={dataRow.eventEnddate? dataRow.questionLength : dataRow.eventquestionLengthAnswered} totalValue={dataRow.questionLength} />
                    }
                    {dataRow.eventsectionLength>0 &&
                      <ProgressBar value={dataRow.eventEnddate? dataRow.eventsectionLength : dataRow.eventLengthEnddate+(dataRow.eventLength>dataRow.eventLengthEnddate? 0.5 : 0)} totalValue={dataRow.eventsectionLength} />
                    }
                    {!dataRow.eventEnddate &&
                    <>
                    {!this.props.viewStyle.includes('editable') &&
                    <Typography component='div' width='100%' sx={{typography: {xs: 'caption', md: 'body2'}}} mt={3} mb={3}>
                      <TextToHtml content={dataRow.content}/>
                    </Typography>
                    }
                    {dataRow.questionLength>0 &&
                    <QuestionListByuser history={this.props.history} eventsectionId={dataRow.id} viewStyle={dataRow.viewstyle} wrap readOnly/>                
                    }
                    <EventCreateByuser history={this.props.history} parentId={this.props.eventId} eventsectionId={dataRow.id} disableView={!this.props.viewStyle?.includes('editable')}/>
                    </>
                    }
                  </Grid>
                </Card>
            )
          }
          return null;
        })}

        <Pagination pageCount={this.state.pageInfo?.pageCount} page={this.state.page} pageInfoCaption='Hal.' prevCaption='Sebelumnya' nextCaption='Berikutnya' disablePageButtons onChange={this.onChangePage}/>
      </Grid>
    );
  }
}
