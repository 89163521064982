import React from 'react';
import { History } from 'history';
import { Input, InputMoney, InputSelect, InputMultiline, Layout, Switch, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './coa-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import AppService from '../../services/app.service';
import CoaList from './coa-list.component';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class CoaEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
        
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} disableCard>              
                
                <Layout history={this.props.history} content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange}>
                            <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                        </Picklist>
                        }
                        <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                        {this.state.dataRow.parent &&
                        <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/>
                        }
                        <InputMoney name='coabalancevalue' label='Last Balance' value={this.state.dataRow.coabalancevalue} onChange={this.onChange} />
                    </>
                    },

                    {caption: 'Sub Account', child: 
                    this.state.dataRow.id>0 &&
                        <CoaList history={this.props.history} root={this.props.root} caption='Sub Account' parentId={this.state.dataRow.id} disableBackButton disableCard/>
                    },

                    ]}
                    
                />        
            </BaseEdit>
        );
    }
}