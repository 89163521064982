import * as React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { Card, Swipe, Tabs } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  history?: History;
  direction?: 'row'|'row-reverse'|'column'|'column-reverse';
  content: {
    caption: string, 
    hide?: boolean,
    leftPosition?: boolean,
    child?: React.ReactNode,
  }[];
  reverseChild?: boolean;
}


export const Layout: React.FunctionComponent<Props> = (props) => {
  var historyState: any = props.history?.location.state;
  const isMobile = AppService.isMobile();
  const isColumn = props.direction ==='column';
  const [isMove, setIsMove] = React.useState(false);
  const [tabIdx, setTabIdx] = React.useState(isMobile? historyState?.tab || 0 : -1);
  const content = props.content.filter((d: any) => !d.hide);

  const getTabIndex = (index: number) => {
    setTabIdx(index); 
  };
  
  const onChangeSwipe = (e: React.ChangeEvent<unknown>, value: string) => {
    var tabIdxNew = tabIdx;
    
    setIsMove(value.startsWith('move'));
    if (value.startsWith('end') && isMove) {
      if (value.endsWith('left') && tabIdx < content.length-1) tabIdxNew = tabIdx + 1;
      else if (value.endsWith('right') && tabIdx > 0) tabIdxNew = tabIdx - 1;
      if (isMobile && tabIdxNew !== tabIdx) {
        getTabIndex(tabIdx);
        props.history?.replace(props.history?.location.pathname, {...historyState, tab: tabIdxNew}); 
      }
      setIsMove(false);
    }
  }

  return (
    <Swipe onChange={onChangeSwipe}>
      {tabIdx>=0 && <Tabs id={props.id} value={tabIdx} tab={content} getTabIndex={getTabIndex}/>}
      <Grid container direction={props.direction} spacing={2} mt={1}>
        <Grid item container xs={12} md={4} spacing={2} alignContent='flex-start'>
          {content?.map((data, i) => {
            if ((i===0 || (i>0 && data.leftPosition)) && [-1,i].includes(tabIdx) && !data.hide)
            return (
              <Grid key={i} item container minHeight={isMobile? '100vh' : ''}>
                <Card>
                  {data.child}
                </Card>
              </Grid>
            )
          })}
        </Grid>
        
        <Grid item container direction={props.reverseChild? 'column-reverse' : 'column'} xs={isColumn? 0: 12} md={isColumn? 0: 8} spacing={2} justifyContent='start'>
            {content?.map((data, i) => {
              if (i>0 && !data.leftPosition && [-1,i].includes(tabIdx) && !data.hide)
              return (
                <Grid key={i} item container minHeight={isMobile? '100vh' : ''}>
                  <Card>
                    {data.child}
                  </Card>
                </Grid>
              )
            })}
        </Grid>
      </Grid>  
    </Swipe>
  );
}