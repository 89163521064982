import * as React from 'react';
import { History} from 'history';
import { Grid } from '@mui/material';
import { FormHeader, SideBar } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  history: History;
  root?: string;
  caption?: string;
  color?: any;
  width?: number;
  getSidebarCollapse?: (data: any) => void;
  header?: React.ReactNode;
  sidebar?: React.ReactNode;
  defaultView?: React.ReactNode;
  children?: React.ReactNode;
}

export const Dashboard: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const isRootPath = props.history.location.pathname===props.root;
  const widthDefault = isMobile? 400 : (props.width || 350);
  const [width, setWidth] = React.useState(widthDefault);

  const onClick = (data: any) => {
    setWidth(data? 160 : widthDefault);
    props.getSidebarCollapse?.(data);
  }

  return (
    <>
    {(!isMobile || isRootPath) && <Grid position='fixed' height={16} width='100%' bgcolor='secondary.light'/>}
    <Grid container pt={2}>
        {(!isMobile || isRootPath) &&
        <Grid item width={width}>
            <SideBar header={props.header} width={width} onClick={onClick}>
                {isMobile && isRootPath && <FormHeader history={props.history} label={props.caption} color={props.color || 'primary.light'}/>}
                {props.sidebar}
            </SideBar>
        </Grid>
        }
        <Grid item xs={12} md={9} p={1}>
            {isRootPath && 
            <>
            <FormHeader history={props.history} label={props.caption}/>
            {props.defaultView}
            </>
            }
            {props.children}
        </Grid>
    </Grid>
    </>
  );
};
