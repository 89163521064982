import * as React from 'react';
import { History } from 'history';
import Model from './transaction-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  transactiontypeType?: string;
  status?: string;
  personalinfotypeType?: string,
  personalinfoId?: number;
  url?: string;
  actions?: any;
  disableBackButton?: boolean;
  disableCard?: boolean;
}

interface IState {
}

export default class TransactionList extends React.Component<IProps, IState> {

  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} caption={this.props.caption} endPointFilter={Model.endPointFilter(this.props.transactiontypeType, this.props.personalinfotypeType, this.props.personalinfoId)} url={(this.props.root||'')+(this.props.url || Model.url)} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}/>
    );
  }
}
