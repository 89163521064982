import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import AddressList from './address-list.component';
import AddressCreate from './address-create.component';
import AddressView from './address-view.component';
import AddressEdit from './address-edit.component';
import AddressCopy from './address-copy.component';
import AddressDelete from './address-delete.component';

interface Props {
  root?: string;
}

export const AddressRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/address/create'} component={AddressCreate} />
      <Route path={(props.root||'')+'/address/view'} component={AddressView} />
      <Route path={(props.root||'')+'/address/edit'} component={AddressEdit} />
      <Route path={(props.root||'')+'/address/copy'} component={AddressCopy} />
      <Route path={(props.root||'')+'/address/delete'} component={AddressDelete} />
      <Route path={(props.root||'')+'/address'} component={AddressList} /> 
    </Switch>
  );
};
