import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import MessageList from './message-list.component';
import MessageCreate from './message-create.component';
import MessageView from './message-view.component';
import MessageEdit from './message-edit.component';
import MessageCopy from './message-copy.component';
import MessageDelete from './message-delete.component';

interface Props {
  root?: string;
}

export const MessageRouteTemplate: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={`${props.root||''}/messagetemplate/create`} render={(params)=> <MessageCreate {...params} caption='Create Message Template' astemplate='yes'/>} />    
      <Route path={`${props.root||''}/messagetemplate/view`} render={(params)=> <MessageView {...params} caption='View Message Template' astemplate='yes'/>} />        
      <Route path={`${props.root||''}/messagetemplate/edit`} render={(params)=> <MessageEdit {...params} caption='Edit Message Template' astemplate='yes'/>} />        
      <Route path={`${props.root||''}/messagetemplate/copy`} render={(params)=> <MessageCopy {...params} caption='Duplicate Message Template' astemplate='yes'/>} />     
      <Route path={`${props.root||''}/messagetemplate/delete`} render={(params)=> <MessageDelete {...params} caption='Delete Message Template' astemplate='yes'/>} />        
      <Route path={(props.root||'')+'/messagetemplate'} render={(params)=> <MessageList {...params} root={props.root} caption='Message Template' astemplate='yes' url='/messagetemplate'/>} /> 
    </Switch>
  );
};
