import ApiService from '../../services/api.service';
import AppService from '../../services/app.service';
import OwnerModel from '../owner/owner-model';

export default class UserModel {
    id: number;
    name: string;
    useremail: string;
    useremailmasking: string;
    status: string;
    statusCaption: string;
    username: string;
    email: string;
    identifier: string;
    code: string;
    password: string;
    currentPassword: string;
    passwordConfirmation: string;
    confirmation: string;
    owner: any;
    ownerCaption: string;
    image: any;
    imageId: number; 
    imageUrl: string;
    menugroupLength: number;
    personalinfo: any;
    personalinfoCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.name;
        this.useremail = dataRow.useremail;
        this.useremailmasking = AppService.emailMasking(dataRow.useremail);
        this.status = dataRow.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.username = dataRow.username;
        this.email = dataRow.email;
        this.identifier = dataRow.identifier;
        this.code = dataRow.code;
        this.password = dataRow.password;
        this.currentPassword = dataRow.password;
        this.passwordConfirmation = dataRow.password;
        this.confirmation = dataRow.confirmationToken;
        this.owner = dataRow.owner?.id || null;
        this.ownerCaption = dataRow.owner?.name || dataRow.owner?.id    
        this.image = dataRow.image?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ApiService.svrUrl+this.image.url : '';
        this.menugroupLength = dataRow.menugroup?.length || 0;
        this.personalinfo = dataRow.personalinfo?.[0]?.id || null;
        this.personalinfoCaption = dataRow.personalinfo?.[0]?.name || dataRow.personalinfo?.[0]?.id; 

    } 

    public static endPoint: string = 'users';
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[owner]='+owner);
    }     
    public static endPointFilter2 = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[personalinfo][id][$null]=true&filters[owner]='+owner)
    }
    public static endPointAuth: string = 'auth';
    public static endPointMedia: string = 'plugin::users-permissions.user';
    public static url: string = '/user';
    public static caption: string = 'User';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        useremail: '',
        useremailmasking: '',
        status: 'active',
        statusCaption: 'Active',
        username: '',
        email: '',
        identifier: '',
        code: '',
        password: '',
        currentPassword: '',
        passwordConfirmation: '',
        confirmation: '',
        owner: [{id: +(OwnerModel.getOwner()||0)}],
        ownerCaption: '',   
        image: null,
        imageId: 0,
        imageUrl: '',
        menugroupLength: 0,
        personalinfo: null,
        personalinfoCaption: '',
  
        }
    }

    public static defaultValueChangePassword = ()=> {
        return {
        password: '',
        currentPassword: '',
        passwordConfirmation: '',
        }
    }

    public static defaultValueForgotPassword = ()=> {
        return {
        useremail: '',
        email: '',
        }
    }

    public static defaultValueResetPassword = ()=> {
        return {
        code: '',
        password: '',
        passwordConfirmation: '',
        }
    }

    public static defaultValueEmailConfirmation = ()=> {
        return {
        confirmation: '',
        }
    }

    public static defaultValueSendEmailConfirmation = ()=> {
        return {
        useremail: '',
        email: '',
        }
    }

    public static defaultValueGetToken = ()=> {
        return {
        useremail: '',
        email: '',
        }
    }

    public static defaultValueCheckToken = ()=> {
        return {
        code: '',
        }
    }

    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'useremail', required: true },
        { name: 'password', required: true },
        { name: 'owner', required: true },
    ]

    public static validationSignin = [
        { name: 'identifier', nameAlias: 'email', required: true },
        { name: 'password', required: true },
    ]

    public static validationChangePassword = [
        { name: 'password', required: true },
        { name: 'currentPassword', nameAlias:'Current Password', required: true },
        { name: 'passwordConfirmation', nameAlias:'Password Confirmation', required: true },
    ]

    public static validationForgotPassword = [
        { name: 'useremail', required: true },
    ]

    public static validationResetPassword = [
        { name: 'code', nameAlias:'Verification Code', required: true },
        { name: 'password', required: true },
        { name: 'passwordConfirmation', nameAlias:'Password Confirmation', required: true },
    ]

    public static validationSendEmailConfirmation = [
        { name: 'useremail', required: true },
    ]

    public static validationEmailConfirmation = [
        { name: 'confirmation', nameAlias:'Confirmation Code', required: true },
    ]

    public static columns = [
        { name: 'name', caption: 'User Name' },
        { name: 'useremailmasking', caption: 'Email' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'User Name' },
        { name: 'useremailmasking', caption: 'Email' },
    ]    
    
    public static columnUpload = [
        { name: 'name', caption: 'User Name' },
        { name: 'useremailmasking', caption: 'Email' },
        { name: 'validate', caption: 'Validate', type: 'validate', default: 'success' },
        { name: 'error', caption: ' ', type: 'error' },
    ]

    public static setUser(id: string, caption: string, token: string) {
        localStorage.setItem('user',id);
        localStorage.setItem('userCaption',caption);
        localStorage.setItem('userAppId',AppService.appId());
        localStorage.setItem('userToken',token);
    }

    public static setUserImage(imageUrl: string) {
        localStorage.setItem('userImageUrl',imageUrl);
    }

    public static removeUser() {
        localStorage.removeItem('user');
        localStorage.removeItem('userCaption');
        localStorage.removeItem('userAppId');
        localStorage.removeItem('userToken');
        localStorage.removeItem('userImageUrl');
    }

    public static getUser() {
        return localStorage.getItem('user') || '';
    }

    public static getUserCaption() {
        return AppService.strCapitalize(localStorage.getItem('userCaption') || '');
    }

    public static getUserAppId() {
        return localStorage.getItem('userAppId') || '';
    }

    public static getUserToken() {
        return localStorage.getItem('userToken') || '';
    }

    public static getUserImageUrl() {
        const userImage = localStorage.getItem('userImageUrl') || '';
        return userImage==='undefined'? '': userImage;
    }

    public static getEmailApp(userEmail: string) {
        return userEmail.replace('@', '.app'+AppService.appId()+'@')
    }

    public static isSignin() {
        return this.getUser()!=='' && this.getUserToken()!=='' && this.getUserAppId()===AppService.appId();
    }
}