import * as React from 'react';
import Model from './user-model';
import BaseUpload from '../base/base-upload.component';

interface IProps {  
  caption?: string;
  dataSet: Array<any>;
  getDataError?: (data: any) => void;
  onSave?: (data: any) => void;
  disableUploadButton?: boolean;
  children?: React.ReactNode;
}

interface IState {
}

export default class UserUpload extends React.Component<IProps, IState> {

  private getDataError = async(data: any) => {
    await this.props.getDataError?.(data);
  };

  private onSave = async(data: any) => {
    await this.props.onSave?.(data);
  };

  public render(): React.ReactNode {
    return (
      <BaseUpload caption={this.props.caption} endPoint={Model.endPointAuth+'/local/register'} model={Model} defaultValue={{'password': 'password123'}} dataSet={this.props.dataSet} getDataError={this.getDataError} onSave={this.onSave} disableUploadButton={this.props.disableUploadButton}>
        {this.props.children}
      </BaseUpload>
    );
  }
}
