import * as React from 'react';
import { History } from 'history';
import Model from './eventquestion-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  eventId: number;
  actions?: any;
  disableBackButton?: boolean;
  disableCard? : boolean;
}

interface IState {
}

export default class EventquestionList extends React.Component<IProps, IState> {
    
  constructor(props: IProps) {
    super(props);
  }

  private getDataSet = (data:any) => {
  }
  
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} parentId={this.props.eventId} caption={this.props.caption} endPointFilter={Model.endPointFilter2+this.props.eventId} url={(this.props.root||'')+Model.url} model={Model} actions={['view']} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard} getDataSet={this.getDataSet}/>
    );
  }
}
