import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputFile, InputMultiline, InputSelect, Picklist } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './transaction-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import TransactiontypePicklist from '../transactiontype/transactiontype-picklist.component';
import PersonalinfoPicklist from '../personalinfo/personalinfo-picklist.component';
import DivisionPicklist from '../division/division-picklist.component';
import SchedulePicklist from '../schedule/schedule-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    root?: string;
    caption?: string;
    transactiontypeType?: string;
    disableFields?: any;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class TransactionCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataRowTransactiontype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'transactiontype': data?.id || null, 'transactiontypeCaption': data?.name || ''  }  });
    };
    
    private getDataRowCustomer = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'customer': data?.id || null, 'customerCaption': data?.name || ''  }  });
    };
    
    private getDataRowEmployee = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'employee': data?.id || null, 'employeeCaption': data?.name || ''  }  });
    };
    
    private getDataRowVendor = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'vendor': data?.id || null, 'vendorCaption': data?.name || ''  }  });
    };
    
    private getDataRowDivision = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'division': data?.id || null, 'divisionCaption': data?.name || ''  }  });
    };
    
    private getDataRowSchedule = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'schedule': data?.id || null, 'scheduleCaption': data?.name || ''  }  });
    };

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} caption={this.props.caption} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} dataRowMedia={this.state.dataRowMedia} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} readOnly />
                {!this.props.disableFields?.includes('owner') && AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                }
                {!this.props.disableFields?.includes('transactionType') &&
                <Picklist name='transactiontype' label='Transaction Type' value={this.state.dataRow.transactiontype} caption={this.state.dataRow.transactiontypeCaption} error={this.state.dataError?.transactiontype} onChange={this.onChange} >
                    <TransactiontypePicklist transactiontypeType={this.props.transactiontypeType} currentId={this.state.dataRow.transactiontype} getDataRow={this.getDataRowTransactiontype}/>
                </Picklist>
                }
                <InputDate name='transactiondate' label='Date' value={this.state.dataRow.transactiondate} error={this.state.dataError?.transactiondate} onChange={this.onChange} />
                <InputMultiline name='note' label='Note' value={this.state.dataRow.note} error={this.state.dataError?.note} onChange={this.onChange} />
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
                {!this.props.disableFields?.includes('customer') &&
                <Picklist name='customer' label='Customer' value={this.state.dataRow.customer} caption={this.state.dataRow.customerCaption} clearValue={this.getDataRowCustomer} error={this.state.dataError?.customer} onChange={this.onChange} >
                    <PersonalinfoPicklist personalinfotypeType='customer' currentId={this.state.dataRow.customer} getDataRow={this.getDataRowCustomer}/>
                </Picklist>
                } 
                {!this.props.disableFields?.includes('vendor') &&
                <Picklist name='vendor' label='Vendor' value={this.state.dataRow.vendor} caption={this.state.dataRow.vendorCaption} clearValue={this.getDataRowVendor} error={this.state.dataError?.vendor} onChange={this.onChange} >
                    <PersonalinfoPicklist personalinfotypeType='vendor' currentId={this.state.dataRow.vendor} getDataRow={this.getDataRowVendor}/>
                </Picklist>
                }  
                {!this.props.disableFields?.includes('employee') &&
                <Picklist name='employee' label='Employee' value={this.state.dataRow.employee} caption={this.state.dataRow.employeeCaption} clearValue={this.getDataRowEmployee} error={this.state.dataError?.employee} onChange={this.onChange} >
                    <PersonalinfoPicklist personalinfotypeType='employee' currentId={this.state.dataRow.employee} getDataRow={this.getDataRowEmployee}/>
                </Picklist>
                } 
                <Picklist name='division' label='Division' value={this.state.dataRow.division} caption={this.state.dataRow.divisionCaption} clearValue={this.getDataRowDivision} error={this.state.dataError?.division} onChange={this.onChange} >
                    <DivisionPicklist currentId={this.state.dataRow.division} getDataRow={this.getDataRowDivision}/>
                </Picklist>
                <Picklist name='schedule' label='Schedule' value={this.state.dataRow.schedule} caption={this.state.dataRow.scheduleCaption} clearValue={this.getDataRowSchedule} error={this.state.dataError?.schedule} onChange={this.onChange} >
                    <SchedulePicklist currentId={this.state.dataRow.schedule} getDataRow={this.getDataRowSchedule}/>
                </Picklist>
            </BaseCopy>
        );
    }
}