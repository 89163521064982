import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import MessageList from './message-list.component';
import MessageCreate from './message-create.component';
import MessageView from './message-view.component';
import MessageEdit from './message-edit.component';
import MessageCopy from './message-copy.component';
import MessageDelete from './message-delete.component';

interface Props {
  root?: string;
}

export const MessageRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/message/create'} component={MessageCreate } />
      <Route path={(props.root||'')+'/message/view'} component={MessageView } />
      <Route path={(props.root||'')+'/message/edit'} component={MessageEdit } />
      <Route path={(props.root||'')+'/message/copy'} component={MessageCopy } />
      <Route path={(props.root||'')+'/message/delete'} component={MessageDelete } />
      <Route path={(props.root||'')+'/message'} render={(params)=> <MessageList {...params} root={props.root} />} /> 
    </Switch>
  );
};
