export default class TransactionamountModel {
    id: number;
    transaction: any;
    transactionCaption: string;
    transactionTotal: number;
    transactionTotaldetail: number;
    transactionTotalpayment: number;
    amounttype: any;
    amounttypeCaption: string;
    percentage: number;
    value: number;
    usepercentage: boolean;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.transaction = dataRow.attributes.transaction?.data?.id || null;
        this.transactionCaption = dataRow.attributes.transaction?.data?.attributes.name || dataRow.attributes.transaction?.data?.id; 
        this.transactionTotal = dataRow.attributes.transaction?.data?.attributes.total || 0; 
        this.transactionTotaldetail = dataRow.attributes.transaction?.data?.attributes.totaldetail || 0; 
        this.transactionTotalpayment = dataRow.attributes.transaction?.data?.attributes.totalpayment || 0; 
        this.amounttype = dataRow.attributes.amounttype?.data?.id || null;
        this.amounttypeCaption = dataRow.attributes.amounttype?.data?.attributes.name || dataRow.attributes.amounttype?.data?.id; 
        this.percentage = dataRow.attributes.percentage;
        this.value = dataRow.attributes.value;
        this.usepercentage = this.percentage !== 0;
        
    } 

    public static endPoint: string = 'transaction-amounts';
    public static endPointFilter: string = '?populate=*&filters[transaction]=';
    public static url: string = '/transactionamount';

    public static caption: string = 'Additional Value';

    public static defaultValue = ()=> {
        return {
        id: 0,
        transaction: null,
        transactionCaption: '',
        transactionTotal: 0,
        transactionTotaldetail: 0,
        transactionTotalpayment: 0,
        amounttype: null,
        amounttypeCaption: '',
        percentage: 0,
        value: 0,
        usepercentage: false,

  
        }
    }
    
    public static columns = [
        { name: 'amounttypeCaption', caption: '' },
        { name: 'percentage', caption: '', type: 'percentage' },
        { name: 'value', caption: '', type: 'money' },
              
    ]

    public static columnPicklist = [
        { name: 'amounttypeCaption', caption: 'Type' },
        { name: 'percentage', caption: 'Percentage', type: 'percentage' },
        { name: 'value', caption: 'Amount', type: 'money' },
    ]
    
}