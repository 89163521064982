import * as React from 'react';
import { Grid } from '@mui/material';
import { Swipe } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  dataLength?: number;
  cardCount?: number;
  cardBorderWidth?: number;
  spacing?: number;
  justifyContent?: 'left' | 'center' | 'right';
  swipe?: string;
  nowrap?: boolean;
  getCardWidth?: (width: number) => void;
  onChange?: (e: React.ChangeEvent<unknown>, value: string) => void;
  children?: React.ReactNode;
}

export const Gallery: React.FunctionComponent<Props> = (props) => {
  const isMobile =AppService.isMobile();
  const isHorizontal = props.swipe?.startsWith('horizontal');
  const isVertical = props.swipe?.startsWith('vertical');
  const isFull = !props.swipe || props.swipe?.endsWith('-full');
  const spacing = (props.cardBorderWidth || 0) + (props.spacing || 0);
  const padding = isMobile? 1 : 0;
  const minMove = padding * 8;
  const galleryRef = React.useRef<any>(null);
  const [cardWidth, setCardWidth] = React.useState(-1);
  const [cardHeight, setCardHeight] = React.useState(-1);
  const [scrollMove, setScrollMove] = React.useState(0);
  const [scrollRow, setScrollRow] = React.useState(0);
  const [maxMove, setMaxMove] = React.useState(0);
  const [maxRow, setMaxRow] = React.useState(0);
  const [isWheel, setIsWheel] = React.useState(false);
  var cardCount = props.cardCount || 1;
  cardCount = isMobile? (!props.nowrap && cardCount>2? ((props.dataLength||0)===1? 1 : 2) :  cardCount) : ( props.cardCount || 3);

  const moved = (isLeftUp?: boolean) => {
    var move = scrollMove + (isVertical? cardHeight : cardWidth) * (isLeftUp? 1 : -1) * (isHorizontal? cardCount : 1) + spacing;
    var row = scrollRow + (isLeftUp? 1 : -1)
    
    if (isFull && isMobile) move = minMove; 
    else if (move > maxMove) move = maxMove; 
    else if (move < minMove) move = minMove; 
    galleryRef.current?.scrollTo({left: isVertical? 0 : move, top: isVertical? move : 0, behavior: 'smooth'});
    setScrollMove(move);

    if (row > maxRow) row = maxRow; else if (row < 0) row = 0;
    setScrollRow(row);  
  }

  const moving = async(e: React.ChangeEvent<unknown>, isLeftUp: boolean) => {
    await moved(isLeftUp);
    
    var firstLast = '';
    if (isVertical)
      firstLast = !isLeftUp && galleryRef.current?.scrollTop===0? 'first' :
        isLeftUp && Math.ceil(galleryRef.current?.scrollHeight-galleryRef.current?.scrollTop)===galleryRef.current?.clientHeight? 'last' :'';
    else if (isHorizontal)
      firstLast = !isLeftUp && galleryRef.current?.scrollLeft===0? 'first' :
        isLeftUp && Math.ceil(galleryRef.current?.scrollWidth-galleryRef.current?.scrollLeft)===galleryRef.current?.clientWidth? 'last' :'';
    await props.onChange?.(e, firstLast)
  }

  const getHeight = () => {
    const start = isHorizontal? 0 : cardCount * scrollRow;
    const end = isHorizontal? (props.dataLength||0) : start + cardCount;

    return [...galleryRef.current?.children].reduce((height: number, data: any, i: number) => {
      return i>=start && i<end? (data.clientHeight>height? data.clientHeight: height) : height;
    }, 0) + spacing;
  }

  const getMaxHeight = () => {
    return [...galleryRef.current?.children].reduce((maxHeight: number, data: any, i: number) => {
      return i<((props.dataLength||0)-cardCount) && i % cardCount === 0? (data.clientHeight + spacing + maxHeight) : maxHeight;
    }, 0);
  }

  const onChange = (e: React.ChangeEvent<unknown>, value: string) => {
    if (value.startsWith('end')) moving(e, value.endsWith('left') || value.endsWith('up'));
    props.onChange?.(e, value);
  }

  const onWheel = async(e: React.WheelEvent<HTMLElement>) => {
    if (props.swipe && !isMobile && !isWheel) {
      await setIsWheel(true);
      setTimeout(() => {
        moving(e, e.deltaY>0);
        setIsWheel(false);
      }, 100); ;
    }
  }

  const onMouseEnter = () => {
    if (!props.swipe || isMobile) return;
    document.body.style.overflow = 'hidden';
    if (document.body.clientHeight> window.innerHeight)
      document.body.style.paddingRight = '10px';
  }

  const onMouseLeave = () => {
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
  }

  React.useEffect(() => {         
    const width = galleryRef.current?.clientWidth/cardCount;
    
    props.getCardWidth?.(width);
    setCardWidth(width);

    if (props.swipe && props.dataLength && props.dataLength===galleryRef.current?.children.length) {
      const height = getHeight();
      const row = (Math.ceil(props.dataLength/(isVertical || isFull? cardCount : 1))-1);
      setCardHeight(height);
      setMaxMove((isVertical? getMaxHeight() : (row-1) * width) + minMove);
      setMaxRow(row);
      if (scrollMove===0) moved();
    }


  }, [props.dataLength, galleryRef.current?.children.length, galleryRef.current?.children?.[0]?.clientHeight, cardHeight, cardWidth, cardCount, scrollRow])

  React.useEffect(() => {return () => onMouseLeave()}, [])

  return (
    <Swipe onChange={onChange} vertical={isVertical}>
      <Grid ref={galleryRef} container direction={isHorizontal && props.dataLength && props.dataLength>=cardCount? 'column' : 'row'} paddingTop={isVertical? padding : 0} paddingBottom={spacing + (isVertical? padding : 0)} paddingLeft={isHorizontal? padding : 0} paddingRight={isHorizontal && !isMobile? props.cardBorderWidth : (isHorizontal? padding : 0)} justifyContent={props.justifyContent} spacing={props.spacing} borderRadius={cardCount===1? 5 : -1} maxHeight={isFull? (isMobile? 'calc(65vh)' : 'calc(80vh)') : cardHeight} sx={{overflowX: isHorizontal? 'auto' : 'hidden', overflowY: isHorizontal? 'hidden' : 'auto', '::-webkit-scrollbar': {display: 'none'}}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onWheel={onWheel}>
        {props.children}
      </Grid>
    </Swipe>
  );  
};
