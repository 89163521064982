import React from 'react';
import { History} from 'history';
import { AddressRoute } from '../address/address-route';
import { AmounttypeRoute } from '../amounttype/amounttype-route';
import { AppRoute } from '../app/app-route';
import { CivilnumberRoute } from '../civilnumber/civilnumber-route';
import { CoabalanceRoute } from '../coabalance/coabalance-route';
import { CoaRoute } from '../coa/coa-route';
import { DivisionRoute } from '../division/division-route';
import { EventRoute } from '../event/event-route';
import { EventRouteEventtype } from '../event/event-route-eventtype';
import { EventRouteTemplate } from '../event/event-route-template';
import { EventquestionRoute } from '../eventquestion/eventquestion-route';
import { EventresultRoute } from '../eventresult/eventresult-route';
import { EventsectionRoute } from '../eventsection/eventsection-route';
import { EventtypeRoute } from '../eventtype/eventtype-route';
import { JournalgroupdetailRoute } from '../journalgroupdetail/journalgroupdetail-route';
import { JournalgroupRoute } from '../journalgroup/journalgroup-route';
import { JournalRoute } from '../journal/journal-route';
import { MenugroupRoute } from '../menugroup/menugroup-route';
import { MenuRoute } from '../menu/menu-route';
import { MessageRoute } from '../message/message-route';
import { MessageRouteTemplate } from '../message/message-route-template';
import { OwnerRoute } from '../owner/owner-route';
import { PaymenttypeRoute } from '../paymenttype/paymenttype-route';
import { PersonalinfoRoute } from '../personalinfo/personalinfo-route';
import { PersonalinfoRouteCustomer } from '../personalinfo/personalinfo-route-customer';
import { PersonalinfoRouteEmployee } from '../personalinfo/personalinfo-route-employee';
import { PersonalinfoRouteVendor } from '../personalinfo/personalinfo-route-vendor';
import { PersonalinfotypeRoute } from '../personalinfotype/personalinfotype-route';
import { PersonalinfotypeRouteCustomer } from '../personalinfotype/personalinfotype-route-customer';
import { PersonalinfotypeRouteEmployee } from '../personalinfotype/personalinfotype-route-employee';
import { PersonalinfotypeRouteVendor } from '../personalinfotype/personalinfotype-route-vendor';
import { ProductRoute } from '../product/product-route';
import { ProducttypeRoute } from '../producttype/producttype-route';
import { QuestionoptionRoute } from '../questionoption/questionoption-route';
import { QuestionRoute } from '../question/question-route';
import { RegionRoute } from '../region/region-route';
import { ReportRoute } from '../report/report-route';
import { ReportgroupRoute } from '../reportgroup/reportgroup-route';
import { ScheduleRoute } from '../schedule/schedule-route';
import { TransactionamountRoute } from '../transactionamount/transactionamount-route';
import { TransactiondetailRoute } from '../transactiondetail/transactiondetail-route';
import { TransactionpaymentRoute } from '../transactionpayment/transactionpayment-route';
import { TransactionRoute } from '../transaction/transaction-route';
import { TransactionRouteGeneral } from '../transaction/transaction-route-general';
import { TransactionRouteJournal } from '../transaction/transaction-route-journal';
import { TransactionRoutePurchase } from '../transaction/transaction-route-purchase';
import { TransactionRouteSales } from '../transaction/transaction-route-sales';
import { TransactiontypeRoute } from '../transactiontype/transactiontype-route';
import { UserRoute } from '../user/user-route';
import UserModel from '../user/user-model';
import MenugroupModel from '../menugroup/menugroup-model';
import BaseView from '../base/base-view.component';

interface IProps {  
    history: History;
    root?: string;
    getDataRow?: (data: any) => void;
 }

interface IState { 
    dataRowMenugroup: MenugroupModel;
 }

export default class ApplayoutRoutes extends React.Component<IProps, IState> { 

    constructor(props: IProps) { 
        super(props);
        this.state = { 
            dataRowMenugroup: MenugroupModel.defaultValue(),
        }
    }

    private getDataRowMenugroup = (data:any) => { 
        this.setState({dataRowMenugroup:  data});
    }

    private isAllow(){
        var path =  '/'+(this.props.history.location.pathname.replace(this.props.root || '', '').split('/')?.[1] || '');
        return UserModel.isSignin() && this.state.dataRowMenugroup?.menu.length>0 && this.state.dataRowMenugroup?.menu.findIndex((d: any) =>  (d.attributes.path.split(';').includes(path)))>=0;
    }

    render() { 
        return (
            //editable..
            <BaseView history={this.props.history} id={0} caption=' ' 
                endPointFilter={MenugroupModel.endPointFilter2()} model={MenugroupModel} getDataRow={this.getDataRowMenugroup} disableBackButton disableCard>              
                {this.isAllow() &&
                <>
                    <AddressRoute root={ this.props.root }/>
                    <AmounttypeRoute root={ this.props.root }/> 
                    <AppRoute root={ this.props.root }/>
                    <CivilnumberRoute root={ this.props.root }/>
                    <CoabalanceRoute root={ this.props.root }/>
                    <CoaRoute root={ this.props.root }/>
                    <DivisionRoute root={ this.props.root }/> 
                    <EventRoute root={ this.props.root }/> 
                    <EventRouteEventtype root={ this.props.root }/> 
                    <EventRouteTemplate root={ this.props.root }/> 
                    <EventquestionRoute root={ this.props.root }/> 
                    <EventresultRoute root={ this.props.root }/> 
                    <EventsectionRoute root={ this.props.root }/>
                    <EventtypeRoute root={ this.props.root }/> 
                    <JournalgroupdetailRoute root={ this.props.root }/>
                    <JournalgroupRoute root={ this.props.root }/>
                    <JournalRoute root={ this.props.root }/>
                    <MenugroupRoute root={ this.props.root }/>
                    <MenuRoute root={ this.props.root }/>
                    <MessageRoute root={ this.props.root }/> 
                    <MessageRouteTemplate root={ this.props.root }/> 
                    <OwnerRoute root={ this.props.root }/>  
                    <PaymenttypeRoute root={ this.props.root }/>
                    <PersonalinfoRoute root={ this.props.root }/> 
                    <PersonalinfoRouteCustomer root={ this.props.root }/> 
                    <PersonalinfoRouteEmployee root={ this.props.root }/> 
                    <PersonalinfoRouteVendor root={ this.props.root }/> 
                    <PersonalinfotypeRoute root={ this.props.root }/> 
                    <PersonalinfotypeRouteCustomer root={ this.props.root }/> 
                    <PersonalinfotypeRouteEmployee root={ this.props.root }/> 
                    <PersonalinfotypeRouteVendor root={ this.props.root }/> 
                    <ProductRoute root={ this.props.root }/> 
                    <ProducttypeRoute root={ this.props.root }/> 
                    <QuestionoptionRoute root={ this.props.root }/> 
                    <QuestionRoute root={ this.props.root }/> 
                    <RegionRoute root={ this.props.root }/>
                    <ReportRoute root={ this.props.root }/>
                    <ReportgroupRoute root={ this.props.root }/>
                    <ScheduleRoute root={ this.props.root }/>
                    <TransactionamountRoute root={ this.props.root }/> 
                    <TransactiondetailRoute root={ this.props.root }/> 
                    <TransactionpaymentRoute root={ this.props.root }/> 
                    <TransactionRoute root={ this.props.root }/> 
                    <TransactionRouteGeneral root={ this.props.root }/> 
                    <TransactionRouteJournal root={ this.props.root }/> 
                    <TransactionRoutePurchase root={ this.props.root }/> 
                    <TransactionRouteSales root={ this.props.root }/> 
                    <TransactiontypeRoute root={ this.props.root }/> 
                    <UserRoute root={ this.props.root }/> 
                </>}
            </BaseView>
        );
     }
 }