import * as React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { Icon } from './components';
import { CssBaseline, ThemeProvider, Typography } from "@mui/material";

import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'toastr/build/toastr.min.css'; 
import './App.css'

import Model from './pages/app/app-model'; 
import OwnerModel from './pages/owner/owner-model';  
import UserModel from './pages/user/user-model';
import BaseView from './pages/base/base-view.component'; 
import Applayout from './pages/applayout/applayout.component';
import ApplayoutThemes from './pages/applayout/applayout-themes.component';
import AppService from './services/app.service';
import ApiService from './services/api.service';

interface IProps { 
}

interface IState {
  dataRow: Model;
  dataError: any;
  themes: any;
}


export default class App extends React.Component<IProps, IState> {

  constructor(props: IProps) {
      super(props);
      this.state = {
          dataRow: Model.defaultValue(),
          dataError: null,
          themes: ApplayoutThemes('blank'),
      }
  }
  
  private getDataRow = (data:any) => {
      this.setState({dataRow:  data});
      OwnerModel.setOwner(data?.owner, data?.ownerCaption);
      document.title = data?.name;  
      const favicon = document.querySelector("link[rel~='icon']") as HTMLAnchorElement; 
      if (favicon) favicon.href = data?.imageUrl;
      if (!UserModel.isSignin()) UserModel.removeUser();
  }

  private getDataError = (data: any) => {
    this.setState({dataError: data});
    if (data && !data?.[0]?.includes('Server')) UserModel.removeUser();
  }

  private getThemes = (data:any) => {
    this.setState({themes: data})
  }
  
  render() {
    return (
      <>
        <BaseView id={+AppService.appId()} caption=' '
          model={Model} getDataRow={this.getDataRow} getDataError={this.getDataError}/>

        {this.state.dataError?.[0] && 
          <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='red' p={5}>
            <Icon name='gpp_bad'/> {this.state.dataError[0]}. Please reload in a moment.
          </Typography>
        }

        {(this.state.dataRow?.['id'] || !ApiService.apiUrl) &&
          <ThemeProvider theme={this.state.themes}> 
            <CssBaseline />            
            <BrowserRouter>
              <Switch>
              <Applayout getThemes={this.getThemes}/>
              </Switch>
            </BrowserRouter>
          </ThemeProvider>
        }
      </> 
    );
  }
}
