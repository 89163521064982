export default class TransactiondetailModel {
    id: number;
    transaction: any;
    transactionCaption: string;
    transactiontype: any;
    transactiontypePricetype: string;
    product: any;
    productCaption: string;
    productQty: number;
    productLength: number;
    price: number;
    qty: number;
    value: number;
    note: string;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.transaction = dataRow.attributes.transaction?.data?.id || null;
        this.transactionCaption = dataRow.attributes.transaction?.data?.attributes.name || dataRow.attributes.transaction?.data?.id; 
        this.transactiontype = dataRow.attributes.transaction?.data?.attributes.transactiontype?.data?.id || null;
        this.transactiontypePricetype = dataRow.attributes.transaction?.data?.attributes.transactiontype?.data?.attributes.pricetype || 'pricebase';
        this.product = dataRow.attributes.product?.data?.id || null;
        this.productCaption = dataRow.attributes.product?.data?.attributes.name || dataRow.attributes.product?.data?.id; 
        this.productQty =  dataRow.attributes.product?.data?.attributes.qty;
        this.productLength =  dataRow.attributes.product?.data?.attributes.product?.data?.length;
        this.price = dataRow.attributes.price;
        this.qty = dataRow.attributes.qty;
        this.value = dataRow.attributes.value; 
        this.note = dataRow.attributes.note; 
    } 

    public static endPoint: string = 'transaction-details';    
    public static endPointFilter: string = '?populate=*&filters[transaction]=';
    public static endPointFilter2: string = '?populate[product][sort]=id'+
                                            '&populate[product][populate]=product'+
                                            '&populate[transaction][sort]=id'+
                                            '&populate[transaction][populate]=transactiontype'+
                                            '&filters[id]='
    public static url: string = '/transactiondetail';

    public static caption: string = 'Order Detail';

    public static defaultValue = ()=> {
        return {
        id: 0,
        transaction: null,
        transactionCaption: '',
        transactiontype: null,
        transactiontypePricetype: 'pricebase',
        product: null,
        productCaption: '',
        productQty: 0,
        productLength: 0,
        price: 0,
        qty: 0,
        value: 0,
        note: '',
  
        }
    }
    
    public static validations = [
        { name: 'qty', minValue: 1 },
    ]

    public static columns = [
        { name: 'productCaption', caption: 'Product/Service' },
        { name: 'note', caption: 'Note' },
        { name: 'price', caption: 'Price', type: 'money' },
        { name: 'qty', caption: 'Qty', type: 'number' },  
        { name: 'value', caption: 'Total', type: 'money' },
              
    ]

    public static columnPicklist = [
        { name: 'productCaption', caption: 'Product/Service' },
        { name: 'price', caption: 'Price', type: 'money' },
        { name: 'qty', caption: 'Qty', type: 'number' },  
        { name: 'value', caption: 'Total', type: 'money' },
    ]
    
}