import * as React from 'react';
import { Card, Picklist } from '../../components';
import Model from './personalinfo-model';
import PersonalinfotypeModel from '../personalinfotype/personalinfotype-model';
import BaseCreate from '../base/base-create.component';
import UserUpload from '../user/user-upload.component';
import PersonalinfotypePicklist from '../personalinfotype/personalinfotype-picklist.component';

interface IProps {  
  caption?: string;
  personalinfotypeType?: string;
  dataSet: Array<any>;
  getDataError?: (data: any) => void;
  onSave?: (data: any) => void;
}

interface IState {
  dataRow: Model;
  dataRowPersonalinfotype: PersonalinfotypeModel;
  dataError: any;
  save: boolean;
}

export default class PersonalinfoUpload extends React.Component<IProps, IState> {

  constructor(props: IProps) {
    super(props);
    this.state = {
        dataRow: Model.defaultValue(),
        dataError: null,
        dataRowPersonalinfotype: PersonalinfotypeModel.defaultValue(),
        save: false,
    }
  }

  private getDataErrorUser = async(data: any) => {
    //
  };

  private getDataError = async(data: any) => {
    if (data) await this.props.getDataError?.(data)
  };

  private getDataRowPersonalinfotype = (data: any) => {
    this.setState({ dataRowPersonalinfotype: data});
  };

  private onChange(fieldName: string, value: string) { 
    const nextState = {
        ...this.state, dataRow: {
            ...this.state.dataRow, [fieldName]: value,
        }
    };
    this.setState(nextState);
  }

  private onSaveUser = async(data: any) => {
    data.personalinfotype = this.state.dataRowPersonalinfotype.id;
    await this.setState({dataRow: {...Model.defaultValue(), ...data}, save: true});
    setTimeout(() => {/**waiting for save personal info data**/}, 500);
  };

  private onSave = async(data: any) => {
    await this.props.onSave?.(data);
    await this.setState({save: false});
  };


  public render(): React.ReactNode {
    return (
      <>
        <UserUpload caption={this.props.caption} dataSet={this.props.dataSet} getDataError={this.getDataErrorUser} onSave={this.onSaveUser} disableUploadButton={!this.state.dataRowPersonalinfotype.id}>
          <Card color='secondary.dark' margin={1}>
            <Picklist name='personalinfotype' label='Customer Type'  value={this.state.dataRow.personalinfotype} caption={this.state.dataRowPersonalinfotype.name} error={this.state.dataError?.personalinfotype} onChange={this.onChange} >
                <PersonalinfotypePicklist currentId={this.state.dataRowPersonalinfotype.id} type={this.props.personalinfotypeType || 'customer'} getDataRow={this.getDataRowPersonalinfotype}/>
            </Picklist>
          </Card> 
        </UserUpload>
        <BaseCreate caption=' ' buttonCaption=' '
            model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} onSave={this.onSave} save={this.state.save} disableAutoBack disableBackButton/>
      </>
    );
  }
}
