import * as React from 'react';
import { History } from 'history';
import Model from './personalinfo-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  personalinfotypeType?: string;
  actions?: any;
  disableBackButton?: boolean;
  disableCard?: boolean;
}

interface IState {
}

export default class PersonalinfoList extends React.Component<IProps, IState> {
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} caption={this.props.caption} endPointFilter={Model.endPointFilter()+(this.props.personalinfotypeType? '&filters[personalinfotype][type]='+this.props.personalinfotypeType : '')} url={(this.props.root||'')+'/'+(this.props.personalinfotypeType || '')} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}/>
    );
  }
}
