import * as React from 'react';
import { History } from 'history';
import Model from './message-model';
import BaseList from '../base/base-list.component';

interface IProps {  
  history: History;
  root?: string;
  caption?: string;
  url?: string;
  astemplate?: string;
}

interface IState {
}

export default class MessageList extends React.Component<IProps, IState> {
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history} endPointFilter={Model.endPointFilter(this.props.astemplate)} caption={this.props.caption} url={(this.props.root||'')+(this.props.url || Model.url)} model={Model} header={this.props.astemplate==='yes' && Model.columnTemplate} searchField={this.props.astemplate==='yes'? '': 'to'}/>
    );
  }
}
