import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EventsectionList from './eventsection-list.component';
import EventsectionCreate from './eventsection-create.component';
import EventsectionView from './eventsection-view.component';
import EventsectionEdit from './eventsection-edit.component';
import EventsectionCopy from './eventsection-copy.component';
import EventsectionDelete from './eventsection-delete.component';

interface Props {
  root?: string;
}

export const EventsectionRoute: React.FunctionComponent<Props> = (props) => {
  return (
    <Switch>
      <Route path={(props.root||'')+'/eventsection/create'} component={EventsectionCreate} />
      <Route path={`${props.root||''}/eventsection/view`} render={(params)=> <EventsectionView {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/eventsection/edit`} render={(params)=> <EventsectionEdit {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/eventsection/copy`} component={EventsectionCopy} />
      <Route path={`${props.root||''}/eventsection/delete`} render={(params)=> <EventsectionDelete {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/eventsection'} render={(params)=> <EventsectionList {...params} root={props.root} />} /> 
    </Switch>
  );
};
