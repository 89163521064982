import React from 'react';
import { History } from 'history';
import { Button, Card, FormHeader } from '../../components';
import Model from './user-model';  //editable..
import { Typography } from '@mui/material';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserAuthSignout extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private onClick = () => {
        Model.removeUser();
        this.props.history.replace('/');
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..  
            <>
            <FormHeader history={this.props.history} label='Logout' color='primary.light'/>  
            <Card>
                <Typography sx={{typography: {xs: 'h6', md: 'h5'}}} textAlign='center' pt={5}>
                    Are you sure to logout?
                    <br/>
                    <Button variant='outlined' sx={{margin: 3}} label='Logout' onClick={this.onClick} />  
                </Typography>
            </Card>
            </>     
        );
    }     
     
}