import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, InputFile, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './questionoption-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import QuestionModel from '../question/question-model';
import EventresultChecklist from '../eventresult/eventresult-checklist.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class QuestionoptionCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;
    
    private getDataRowQuestion = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'question': data?.id || null, 'questionCaption': data?.name || ''  }  });
    };  

    private getDataRowEventresult = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'eventresult': data || [], 'eventresultCaption': data?.length>0? (data[0].name || data[0]?.attributes.name) + (data.length>1? ', ...': '') : ''}  });
    };  

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{files: data, ref: Model.endPointMedia, field:'image'}}});
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(): void {    
        this.getDataRowQuestion({'id': this.historyState?.id});
    }

    render() {
        return (
            //editable.. 
            <>
            {this.historyState?.id>0 &&
            <BaseView history={this.props.history} caption=''
                model={QuestionModel} getDataRow={this.getDataRowQuestion}/>
            }    
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataError={this.getDataError}>              
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onChange} />
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onChange} />
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
                <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onChange} />
                <Input name='answer' label='Answer' value={this.state.dataRow.answer} error={this.state.dataError?.answer} onChange={this.onChange} />
                <InputNumber name='value' label='Value' value={this.state.dataRow.value} error={this.state.dataError?.value} onChange={this.onChange} />
                <Picklist name='eventresult' label='Event Result List' value={this.state.dataRow.eventresult} caption={this.state.dataRow.eventresultCaption} disableAutoClose clearValue={this.getDataRowEventresult} error={this.state.dataError?.eventresult} onChange={this.onChange} >
                    <EventresultChecklist history={this.props.history} currentId={this.state.dataRow.eventresult} getDataRow={this.getDataRowEventresult} disableBackButton/>
                </Picklist> 
            </BaseCreate>
            </>
        );
    }     
     
}