import * as React from 'react';
import { History } from 'history';
import Model from './menugroup-model';
import BasePicklistCard from '../base/base-picklistcard.component';
import AppService from '../../services/app.service';

interface IProps {
  history: History;
  root?: string;
  caption?: string;
  currentId?: number;
  cardCount?: number;
  cardIconOnly?: boolean;
  autoLoad?: boolean;
  getDataRow?: (data: any) => void;
}

interface IState {
  dataRow: Model;
  currentId: number;
  historyLengthRoot: number;
  menugroupPage: number;
}

export default class MenugroupPicklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { 
      dataRow: Model.defaultValue(),
      currentId : this.props.currentId || 0,
      historyLengthRoot: props.history.length,
      menugroupPage:  1,
    }
    
    this.onChangePage = this.onChangePage.bind(this);
  }

  private isMobile: boolean = AppService.isMobile();
  
  private getDataRow = (data: any) => {
    var historyState: any = this.props.history.location.state;
    if (data?.id>0) historyState = {...historyState, menugroupId: data?.id};
    this.props.history.replace(this.props.history.location.pathname, historyState);
    this.props.history.push(this.props.history.location.pathname);
    
    this.setState({dataRow: data, currentId: data.id})
    this.props.getDataRow?.(data);
  };

  private onChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    var historyState: any = this.props.history.location.state;
    this.props.history.replace(this.props.history.location.pathname, {...historyState, menugroupPage: value});
    this.setState({menugroupPage: value})
  }

  public componentDidMount() {
      //Back to Previous Page First, When User Hit New Url
      var historyState: any = this.props.history.location.state;
      if (this.props.history.location.pathname!==this.props.root && !historyState) this.props.history.goBack();

      //Update currentId, menugroupPage & historyLength Base on Current History States
      this.setState({currentId: 0})
      if (historyState?.menugroupPage) this.setState({menugroupPage: historyState?.menugroupPage})
      if (historyState?.historyLengthRoot) this.setState({historyLengthRoot: historyState?.historyLengthRoot})
  }

  public componentDidUpdate() {
  
    //Tagging currentId for Active Menu Id & historyLength for Back to Root Url in New Forwards Url
    var historyState: any = this.props.history.location.state;
    if (!historyState?.menugroupPage && this.state.menugroupPage>0) this.props.history.replace(this.props.history.location.pathname, {...historyState, menugroupPage: this.state.menugroupPage});
    if (!historyState?.historyLengthRoot && this.state.historyLengthRoot) this.props.history.replace(this.props.history.location.pathname, {...historyState, historyLengthRoot: this.state.historyLengthRoot});
    if (!historyState?.historyLength && this.props.history.length) this.props.history.replace(this.props.history.location.pathname, {...historyState, historyLength: this.props.history.length});
  
  }
  
  public render(): React.ReactNode {
    return (
      <BasePicklistCard history={this.props.history} caption={this.props.caption} endPointFilter={Model.endPointFilter2()} model={Model} currentId={this.state.currentId} getDataRow={this.getDataRow} page={this.state.menugroupPage} pageSize={this.isMobile? 6: 10} onChangePage={this.onChangePage} cardCount={this.props.cardCount || 2} cardBoxShadow={5} swipe={this.isMobile? 'horizontal-full' : ''} cardIconOnly={this.props.cardIconOnly}  autoLoad={this.isMobile || this.props.autoLoad} disableBackButton/>
    );
  }
}
