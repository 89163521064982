import * as React from 'react';
import { Grid, Switch as SwitchMui, Typography } from '@mui/material';
import { Chip } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  name: string;
  label?: string;
  value: string;
  defaultTrue: string;
  defaultFalse: string;
  readOnly?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
}

export const Switch: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const [value, setValue] = React.useState('');
  const onChangeSwitch = (e: React.ChangeEvent<HTMLInputElement>) => { 
    setValue(e.target.checked?  props.defaultTrue : props.defaultFalse);
    props.onChange?.(props.name, e.target.checked?  props.defaultTrue : props.defaultFalse);
  };

  React.useEffect(() => {
    setValue(props.value);

  },[props.value])

  return (
    <Grid id={props.id || props.name} mt={3} mb={3}>
        <Typography variant='caption' color='text.secondary' display='block' >
          {props.label}
        </Typography>
        {props.readOnly?
          <Chip label={AppService.strCapitalize(value)} size={isMobile? 'small' : 'medium'} color='info' />
        : <>
          <SwitchMui
            checked={value === props.defaultTrue}
            disabled={props.readOnly}
            onChange={onChangeSwitch}
          /> 
          {AppService.strCapitalize(value)}
          </>
        }
        <Typography variant='caption' color='error' display='block' >
          {props.error}
        </Typography>
    </Grid>
  )
};