import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, InputSelect, Switch, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './eventsection-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class EventsectionCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataRowParent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'parent': data?.id || null, 'parentCaption': data?.name || ''  }  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..      
            <>     
            {this.historyState?.id>0 &&
            <BaseView history={this.props.history} caption=''
                model={Model} getDataRow={this.getDataRowParent}/>
            }        
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} disableAutoBack>              
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                {this.historyState?.id>0 &&
                <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/>
                }
                <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onChange} />
                <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onChange} />
                <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} error={this.state.dataError?.seq} onChange={this.onChange} />
                <InputSelect name='viewstyle' label='View Style' value={this.state.dataRow.viewstyle} options={Model.optionViewstyle} error={this.state.dataError?.viewstyle} onChange={this.onChange} />
                <InputNumber name='timelimit' label='Time Limit (Seconds)' value={this.state.dataRow.timelimit} error={this.state.dataError?.timelimit} onChange={this.onChange} />
                <InputNumber name='questiontolerance' label='Question Tolerances' value={this.state.dataRow.questiontolerance} error={this.state.dataError?.questiontolerance} onChange={this.onChange} />
                <Input name='tag' label='Tag' value={this.state.dataRow.tag} error={this.state.dataError?.tag} onChange={this.onChange} />
                
            </BaseCreate>
            </>
        );
    }     
     
}