import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import ReportgroupList from './reportgroup-list.component';
import ReportgroupCreate from './reportgroup-create.component';
import ReportgroupView from './reportgroup-view.component';
import ReportgroupEdit from './reportgroup-edit.component';
import ReportgroupCopy from './reportgroup-copy.component';
import ReportgroupDelete from './reportgroup-delete.component';

interface Props {
  root?: string;
}

export const ReportgroupRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/reportgroup/create'} component={ReportgroupCreate} />
      <Route path={(props.root||'')+'/reportgroup/view'} component={ReportgroupView} />
      <Route path={(props.root||'')+'/reportgroup/edit'} component={ReportgroupEdit} />
      <Route path={(props.root||'')+'/reportgroup/copy'} component={ReportgroupCopy} />
      <Route path={(props.root||'')+'/reportgroup/delete'} component={ReportgroupDelete} />
      <Route path={(props.root||'')+'/reportgroup'} render={(params)=> <ReportgroupList {...params} root={props.root} />} /> 
    </Switch>
  );
};
