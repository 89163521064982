import React from 'react';
import { History } from 'history';
import { CardMedia, Typography } from '@mui/material';
import { Card, Input, InputMoney, InputMultiline, InputNumber, TextToHtml } from '../../components';
import BaseView from '../base/base-view.component'; 
import BaseEdit from '../base/base-edit.component';
import BaseCreate from '../base/base-create.component';
import Model from './eventquestion-model';  //editable..
import EventModel from '../event/event-model';
import QuestionoptionPicklistCard from '../questionoption/questionoption-picklistcard.component';
import QuestionoptionChecklistCard from '../questionoption/questionoption-checklistcard.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    eventId: number;
    dataRowQuestion: any;
    readOnly?: boolean;
    onSave?: (data: any)=>void;
}

interface IState {
    dataRow: Model;
    dataError: any;
    prevAnswer: string;
    questionoptionLength: number;
    save: boolean;
}

export default class EventquestionViewQuestionoption extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            prevAnswer: '',
            questionoptionLength: 0,
            save: false,
        }
        this.onChange = this.onChange.bind(this);
        this.onEndTyping = this.onEndTyping.bind(this);
    }

    private isMobile: boolean = AppService.isMobile();

    private getDataRow = (data:any) => {
        if (data) this.setState({dataRow: data, prevAnswer: data.answer, questionoptionLength: data.questionoptionLength})
    }

    private getDataRowEvent = async(data:any) => {
        await this.setState({ dataRow: { ...this.state.dataRow, 
            'name': data?.name  +'/'+this.props.dataRowQuestion.name,
            'description': data?.name  +'/'+this.props.dataRowQuestion.name,
            'event': data?.id, 
            'eventsection': data?.eventsection?.['0']?.id, 
            'question': this.props.dataRowQuestion.id,
            'questionType': this.props.dataRowQuestion.type,
            }
        });
    }

    private getDataError = (data:any) => {
    }

    private getDataRowQuestionoption = async(data: any) => {
        data = ['yesno','optional'].includes(this.state.dataRow.questionType)? [data] : data; 
        this.setState({ dataRow: { ...this.state.dataRow, 'questionoption': data, 'questionoptionLength': data?.length || 0}, save: true});
    };

    private onSave = async (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, id: data.id}, save: false});
        data.create = true;
        data.disablescroll = ['multiple','multiplematch','freetext'].includes(this.state.dataRow.questionType);
        await this.props.onSave?.(data);
    }    
    
    private onSaveEdit = async(data: any) => {
        if (['multiple','multiplematch','freetext'].includes(this.state.dataRow.questionType)) {
            if (this.props.dataRowQuestion.type.includes('multiple')) {
                data.create = this.state.questionoptionLength === 0 && this.state.dataRow.questionoptionLength === 1;
                data.delete = this.state.questionoptionLength === 1 && this.state.dataRow.questionoptionLength === 0;
            }
            data.disablescroll = true;
        }
        this.setState({questionoptionLength: this.state.dataRow.questionoptionLength, save: false});
        await this.props.onSave?.(data);
    }

    private onEndTyping() { 
        if (this.state.prevAnswer===this.state.dataRow.answer) return;
        
        var data = null;
        if (this.props.dataRowQuestion.questionoptionLength>0)
            data = this.state.dataRow.answer===this.props.dataRowQuestion.questionoption?.[0]?.attributes.answer? this.props.dataRowQuestion.questionoption : null;
        
        this.getDataRowQuestionoption(data)
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value
            }
        };
        this.setState(nextState);
    }

    render() {
        const isSample = this.props.dataRowQuestion.seq<=0;
        const currentId = isSample && this.props.dataRowQuestion?.questionoption?.filter((d: any) => (d?.attributes.value>0));
        
        return (
            //editable..
            <BaseView id={this.props.eventId} caption=' '
                endPointFilter={Model.endPointFilter3+this.props.dataRowQuestion.id+'&filters[event]='} model={Model} getDataRow={this.getDataRow} disableBackButton disableCard>
                
                {this.state.dataRow.id === 0 &&
                <BaseView id={this.props.eventId} caption=' ' 
                        model={EventModel} getDataRow={this.getDataRowEvent}/>
                }
                {this.state.dataRow.id>0?
                <BaseEdit id={this.state.dataRow.id} caption=' ' buttonCaption=' ' successCaption=' '
                    model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} onSave={this.onSaveEdit} save={this.state.save} disableAutoBack disableBackButton disableCard/>              
                :
                <BaseCreate caption=' ' buttonCaption=' ' successCaption=' '
                    model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} onSave={this.onSave} save={this.state.dataRow.name && this.state.dataRow.event && this.state.save} disableAutoBack disableBackButton disableCard/>              
                }
                
                {this.props.dataRowQuestion.title?
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} textAlign='center' mb={3}>
                    {this.props.dataRowQuestion.title}
                </Typography>
                :''}
                {this.props.dataRowQuestion.imageUrl?
                    <Card media={<CardMedia sx={{ height: 200, width: 'auto'}} image={this.props.dataRowQuestion.imageUrl} component='img'/> } borderWidth={0}/>
                :''}
                
                {this.props.dataRowQuestion.questionoptionLength>0 &&
                <>
                {this.props.dataRowQuestion.type==='yesno' &&
                <QuestionoptionPicklistCard caption=' ' questionId={this.props.dataRowQuestion.id} currentId={(currentId || this.state.dataRow.questionoption)[0]?.id} getDataRow={this.getDataRowQuestionoption} cardCount={6} cardBorderRadius={20} cardAlign='center' readOnly={this.props.readOnly} />
                }
                {this.props.dataRowQuestion.type==='optional' &&
                <QuestionoptionPicklistCard caption=' ' questionId={this.props.dataRowQuestion.id} currentId={(currentId || this.state.dataRow.questionoption)[0]?.id} getDataRow={this.getDataRowQuestionoption} cardCount={6} cardBorderRadius={5} cardAlign='center' readOnly={this.props.readOnly} />
                }
                {this.props.dataRowQuestion.type==='optionalthin' &&
                <QuestionoptionPicklistCard caption=' ' questionId={this.props.dataRowQuestion.id} currentId={(currentId || this.state.dataRow.questionoption)[0]?.id} getDataRow={this.getDataRowQuestionoption} cardCount={this.isMobile? this.props.dataRowQuestion.questionoptionLength : 16} cardBorderRadius={20} cardAlign='center' cardNowrap readOnly={this.props.readOnly}/>
                }
                {this.props.dataRowQuestion.type.includes('multiple') &&
                <QuestionoptionChecklistCard caption=' ' questionId={this.props.dataRowQuestion.id} currentId={currentId || this.state.dataRow.questionoption} getDataRow={this.getDataRowQuestionoption} cardCount={6} cardAlign='center' readOnly={this.props.readOnly} />
                }
                </>
                }
                {!isSample &&
                <>
                {this.props.dataRowQuestion.type==='freetext' &&
                <InputMultiline name='answer' value={this.state.dataRow.answer} size='medium' box color={this.state.dataRow.answer && 'secondary.dark'} error={this.state.dataError?.answer} onChange={this.onChange} onEndTyping={this.onEndTyping} readOnly={this.props.readOnly} />
                } 
                {this.props.dataRowQuestion.type==='letter' &&
                <Input name='answer' value={this.state.dataRow.answer.replace(/[^a-z\s]/gi,'')} size='medium' box color={this.state.dataRow.answer && 'secondary.dark'} error={this.state.dataError?.answer} onChange={this.onChange} onEndTyping={this.onEndTyping} readOnly={this.props.readOnly} />
                }   
                {this.props.dataRowQuestion.type==='letternumber' &&
                <Input name='answer' value={this.state.dataRow.answer.replace(/[^a-z0-9\s]/gi,'')} size='medium' box color={this.state.dataRow.answer && 'secondary.dark'} error={this.state.dataError?.answer} onChange={this.onChange} onEndTyping={this.onEndTyping} readOnly={this.props.readOnly} />
                }  
                {this.props.dataRowQuestion.type==='number' &&
                <InputNumber name='answer' value={+this.state.dataRow.answer} size='medium' autoSelect box color={this.state.dataRow.answer && 'secondary.dark'} error={this.state.dataError?.answer} onChange={this.onChange} onEndTyping={this.onEndTyping} readOnly={this.props.readOnly} />
                }
                {this.props.dataRowQuestion.type==='decimal' &&
                <InputNumber name='answer' value={+this.state.dataRow.answer} size='medium' autoSelect box color={this.state.dataRow.answer && 'secondary.dark'} error={this.state.dataError?.answer} onChange={this.onChange} onEndTyping={this.onEndTyping} readOnly={this.props.readOnly} />
                }  
                {this.props.dataRowQuestion.type==='money' &&
                <InputMoney name='answer' value={+this.state.dataRow.answer} size='medium' autoSelect box color={this.state.dataRow.answer && 'secondary.dark'} error={this.state.dataError?.answer} onChange={this.onChange} onEndTyping={this.onEndTyping} readOnly={this.props.readOnly} />
                }
                </>
                }
                {this.props.dataRowQuestion.content?
                <Typography component={'div'} sx={{typography: {xs: 'subtitle1', md: 'h6'}}} color='primary.light' textAlign='center' mt={3} mb={3}>
                    <TextToHtml content={this.props.dataRowQuestion.content}/>
                </Typography>
                :''}
            </BaseView> 
        );
    }
}