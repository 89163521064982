import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputEmail, InputFile, InputMultiline, InputSelect, Layout, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './personalinfo-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import RegionPicklist from '../region/region-picklist.component';
import CivilnumberList from '../civilnumber/civilnumber-list.component';
import AddressList from '../address/address-list.component';

interface IProps { 
    history: History;
    root?: string;
    id?: number;
    caption?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class PersonalinfoEditByuser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowBirthplace = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'birthplace': data?.id || null, 'birthplaceCaption': data?.description || ''  }  });
    };

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} id={this.props.id} caption={this.props.caption} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError} disableBackButton disableAutoBack disableCard>              
                
                <Layout history={this.props.history} direction='column' content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                        <Input name='phone' label='Phone' value={this.state.dataRow.phone} error={this.state.dataError?.phone} onChange={this.onChange} />
                        <InputEmail name='email' label='Email' value={this.state.dataRow.email} error={this.state.dataError?.email} onChange={this.onChange} />
                        <Picklist name='birthplace' label='Place of Birth' value={this.state.dataRow.birthplace} caption={this.state.dataRow.birthplaceCaption} error={this.state.dataError?.birthplace} onChange={this.onChange} >
                            <RegionPicklist caption='Select Place of Birth' currentId={this.state.dataRow.birthplace} regionType='city' getDataRow={this.getDataRowBirthplace}/>
                        </Picklist>  
                        <InputDate name='birthdate' label='Date of Birth' value={this.state.dataRow.birthdate} error={this.state.dataError?.birthdate} onChange={this.onChange} disableTime/>
                        <InputSelect name='gender' label='Gender' value={this.state.dataRow.gender} options={Model.optionGender} error={this.state.dataError?.gender} onChange={this.onChange} />
                        <InputFile name='imageUrl' label='Photo' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
                    </>
                    },

                    {caption: 'Addresses', child: 
                    this.state.dataRow.id>0 &&
                        <AddressList history={this.props.history} root={this.props.root} personalinfoId={this.state.dataRow.id} disableBackButton disableCard/>
                    },

                    {caption: 'Civil Numbers', child: 
                    this.state.dataRow.id>0 &&
                        <CivilnumberList history={this.props.history} root={this.props.root} personalinfoId={this.state.dataRow.id} disableBackButton disableCard/>
                    },

                    ]}
                />  

            </BaseEdit>
        );
    }
}