import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import AmounttypeList from './amounttype-list.component';
import AmounttypeCreate from './amounttype-create.component';
import AmounttypeView from './amounttype-view.component';
import AmounttypeEdit from './amounttype-edit.component';
import AmounttypeCopy from './amounttype-copy.component';
import AmounttypeDelete from './amounttype-delete.component';

interface Props {
  root?: string;
}

export const AmounttypeRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/amounttype/create'} component={AmounttypeCreate} />
      <Route path={(props.root||'')+'/amounttype/view'} component={AmounttypeView} />
      <Route path={(props.root||'')+'/amounttype/edit'} component={AmounttypeEdit} />
      <Route path={(props.root||'')+'/amounttype/copy'} component={AmounttypeCopy} />
      <Route path={(props.root||'')+'/amounttype/delete'} component={AmounttypeDelete} />
      <Route path={(props.root||'')+'/amounttype'} render={(params)=> <AmounttypeList {...params} root={props.root} />} /> 
    </Switch>
  );
};
