import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputNumber, InputMoney, Switch, Picklist, InputFile } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './product-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import AmounttypeModel from '../amounttype/amounttype-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import ProducttypePicklist from '../producttype/producttype-picklist.component';
import ProductChecklist from './product-checklist.component';
import AmounttypeChecklist from '../amounttype/amounttype-checklist.component';
import JournalgroupPicklist from '../journalgroup/journalgroup-picklist.component';
import EventPicklist from '../event/event-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class ProductCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataRowProducttype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'producttype': data?.id || null, 'producttypeCaption': data?.name || '', 'producttypeType': data?.type  }  });
    };

    private getDataRowProduct = (data: any) => {
        var pricebase = 0;
        var pricesale = 0;
        data?.forEach((dataRow: any, i: any) => {
            pricebase = pricebase + dataRow.pricebase;
            pricesale = pricesale + dataRow.pricesale;
        });
        this.setState({ dataRow: { ...this.state.dataRow, 'pricebase': pricebase, 'pricesale': pricesale, 'product': data || [], 'productCaption': data?.length>0? (data[0].name || data[0]?.attributes.name) + (data.length>1? ', ...': '') : ''}  });
    }; 

    private getDataRowAmounttype = (data: any) => {
        var totalamount = 0;
        data?.forEach((dataRow: any, i: any) => {
            totalamount = totalamount + AmounttypeModel.getCalcValue(dataRow.percentage, dataRow.value, this.state.dataRow.pricebase)
        });
        var pricesale = Number(this.state.dataRow.pricebase) + Number(this.state.dataRow.margin) + totalamount;
        this.setState({ dataRow: { ...this.state.dataRow, 'totalamount': totalamount, 'pricesale': pricesale, 'amounttype': data || [], 'amounttypeCaption': data?.length>0? (data[0].name || data[0]?.attributes.name) + (data.length>1? ', ...': '') : ''} });
    };  

    private getDataRowJournalgroup = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'journalgroup': data?.id || null, 'journalgroupCaption': data?.name || ''  }  });
    };

    private getDataRowEvent = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'event': data?.id || null, 'eventCaption': data?.name || ''  }  });
    };

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    };
    
    private onChange(fieldName: string, value: string) { 
        var  pricesale = this.state.dataRow.pricesale;
        if (fieldName === 'pricebase') pricesale = Number(value) + Number(this.state.dataRow.margin) + Number(this.state.dataRow.totalamount)
        else if (fieldName === 'margin') pricesale = Number(this.state.dataRow.pricebase) + Number(value) + Number(this.state.dataRow.totalamount);
        
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, pricesale: pricesale
            }
        };
        this.setState(nextState);
    }
    
    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                <Picklist name='producttype' label='Product Type' value={this.state.dataRow.producttype} caption={this.state.dataRow.producttypeCaption} error={this.state.dataError?.producttype} onChange={this.onChange} >
                    <ProducttypePicklist currentId={this.state.dataRow.producttype} getDataRow={this.getDataRowProducttype}/>
                </Picklist> 
                <Input name='unit' label='Unit' value={this.state.dataRow.unit} error={this.state.dataError?.unit} onChange={this.onChange} />
                <InputNumber name='qty' label='Qty' value={this.state.dataRow.qty} error={this.state.dataError?.qty} onChange={this.onChange} />
                <InputNumber name='qtymin' label='Qty Min' value={this.state.dataRow.qtymin} error={this.state.dataError?.qtymin} onChange={this.onChange} />
                <InputNumber name='qtymax' label='Qty Max' value={this.state.dataRow.qtymax} error={this.state.dataError?.qtymax} onChange={this.onChange} />
                <InputMoney name='pricebase' label='Price Base' value={this.state.dataRow.pricebase} error={this.state.dataError?.pricebase} onChange={this.onChange} />
                <InputMoney name='margin' label='Margin' value={this.state.dataRow.margin} error={this.state.dataError?.margin} onChange={this.onChange} />
                <Picklist name='amounttype' label='Addional Value List' value={this.state.dataRow.amounttype} caption={this.state.dataRow.amounttypeCaption} disableAutoClose clearValue={this.getDataRowAmounttype} error={this.state.dataError?.amounttype} onChange={this.onChange} >
                    <AmounttypeChecklist history={this.props.history} currentId={this.state.dataRow.amounttype} getDataRow={this.getDataRowAmounttype} disableBackButton/>
                </Picklist> 
                <InputMoney name='totalamount' label='Total Addional Values' value={this.state.dataRow.totalamount} readOnly/>
                <InputMoney name='pricesale' label='Price Sale' value={this.state.dataRow.pricesale} error={this.state.dataError?.pricesale} onChange={this.onChange} />
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
                <Picklist name='product' label='Sub Product List' value={this.state.dataRow.product} caption={this.state.dataRow.productCaption} disableAutoClose clearValue={this.getDataRowProduct} error={this.state.dataError?.product} onChange={this.onChange} >
                    <ProductChecklist history={this.props.history} currentId={this.state.dataRow.product} getDataRow={this.getDataRowProduct} disableBackButton/>
                </Picklist>  
                <Picklist name='journalgroup' label='Journal Group' value={this.state.dataRow.journalgroup} caption={this.state.dataRow.journalgroupCaption} error={this.state.dataError?.journalgroup} clearValue={this.getDataRowJournalgroup} onChange={this.onChange} >
                    <JournalgroupPicklist currentId={this.state.dataRow.journalgroup} getDataRow={this.getDataRowJournalgroup}/>
                </Picklist> 
                {this.state.dataRow.producttypeType==='event' &&
                <Picklist name='event' label='Event' value={this.state.dataRow.event} caption={this.state.dataRow.eventCaption} error={this.state.dataError?.event} clearValue={this.getDataRowEvent} onChange={this.onChange} >
                    <EventPicklist currentId={this.state.dataRow.event} astemplate='yes' getDataRow={this.getDataRowEvent}/>
                </Picklist> 
                }

            </BaseCopy>
        );
    }
}