import Response from './api-response-model.service';
import axios from 'axios';
import UserModel from '../pages/user/user-model';

export default class ApiService {
    //note: for test in mobile, api server must be using ipaddress 
    public static svrUrl: string = `${process.env.REACT_APP_SVR_URL||''}`;
    public static apiUrl: string = this.svrUrl? this.svrUrl + `${process.env.REACT_APP_API_URL}` : '';
    
    private static header() {
        var token = UserModel.getUserToken();
        return token? {headers: {Authorization: 'Bearer '+token}} : {};
    }

    private static hasResponse<T>(response: any, isArray?: boolean): Response{
        const result = response.data ; 
        if(result){
            if (!result.data) {result.data = result; } //special case for user endpoint
            return new Response(true, !isArray? result.data  as Array<T> : result.data, !isArray?  null: result.meta, 'Success', '');
        }
        else{
            const msg = (result.messageList && result.messageList.length > 0) ? result.messageList[0].text: 'Error';
            return new Response(false, null, null, 'Data Error', msg);
        }  
    }

    private static hasError(error: any): Response{
        /*
        error level:
        1. error.message -> when network error
        2. error.response.statusText -> when internal server error like database problem, permission issue, etc.
        3. dataError -> when field get error validations
        */
       
        const errorDetails = error.response?.data.error?.details?.errors;
        const errorMessage = error.response?.data.error?.message || (error.toString().includes('Network Error') && 'Failed to connect to the server');
        var dataError: any;
        var message = error.response?.statusText || error.message;
        if (message.length>50) message = '';

        errorDetails?.map((errorDetail: any) => {
            if (!dataError && errorDetail.path[0]) dataError = {};
            if (errorDetail.path[0] && !dataError?.[errorDetail.path[0]]) dataError[errorDetail.path[0]] = errorDetail.message;
        });
        if (!dataError) dataError = {'0': errorMessage};
        
        return new Response(false, null, null, message, dataError);
    }

    public static async getAll<T>(endpoint: string): Promise<Response> {
        if (!this.apiUrl) return new Response(false, null, null, '', null);
        let res = await axios.get<Array<T>>(this.apiUrl + endpoint, this.header())
            .then((response: any) => {return this.hasResponse<T>(response, true);})
            .catch((error) => {return this.hasError(error)});
        return res;
    }

    public static async get<T>(endpoint: string, param: any): Promise<Response> {
        if (!this.apiUrl) return new Response(false, null, null, '', null);
        let res = axios.get<T>(this.apiUrl + endpoint + param, this.header())
            .then((response: any) => { return this.hasResponse<T>(response);})
            .catch((error: any) => { return this.hasError(error)});
        return res;
    }

    public static create<T>(endpoint: string, obj: T): Promise<Response> {
        let res = axios.post(this.apiUrl + endpoint , obj, this.header())
            .then((response: any) => { return new Response(true, response.data , null, 'Success', '');;})
            .catch((error: any) => { return this.hasError(error);});
        return res;
    }

    public static update<T>(endpoint: string, param: any, obj: T): Promise<Response> {
        let res = axios.put(this.apiUrl + endpoint + param, obj, this.header())
            .then((response: any) => { return this.hasResponse<T>(response);})
            .catch((error: any) => { return this.hasError(error);});
        return res;
    }

    public static delete(endpoint: string, param: any): Promise<Response> {
        let res = axios.delete(this.apiUrl + endpoint + param, this.header() )
            .then((response: any) => { return this.hasResponse(response);})
            .catch((error: any) => { return this.hasError(error);});
        return res;
    }
}