import * as React from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { Dialog , DialogContent, Grid, Typography} from '@mui/material';
import { Button, ProgressBar } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  caption?: string;
  buttonCaption?: string;
  htmlId: string;
  printOptions: any;
  save?: boolean;
  onProgress?: (data: any) => void;
  getFiles?: (data: any) => void;
}

export const PrintPDF: React.FunctionComponent<Props> = (props) => {
  const [open, setOpen] = React.useState(false);
  const [save, setSave] = React.useState(false);
  const [progress, setProgress] = React.useState(0);

  const imageContent = (ctx: CanvasRenderingContext2D, sx: number, sy: number, sw: number, sh: number) => {
    const imgData = new Uint32Array( ctx.getImageData(sx, sy, sw, sh).data.buffer);
    return imgData.some(color => ![0, 4294967295].includes(color));
  }

  const printDocument =  async() => {
    props.onProgress?.(true);
    setTimeout(() => {}, 1000);
    setProgress(0);
    setOpen(true);

    const filename = (props.printOptions.fileName||'result')+'.pdf';

    var pdf = new jsPDF('p', 'mm', props.printOptions.paper || 'a4', true);
    pdf.setProperties(props.printOptions.fileProperties);
    var pdfWidth = pdf.internal.pageSize.getWidth();
    var pdfHeight = pdf.internal.pageSize.getHeight();
    var pdfMargin = props.printOptions.margin || 20;
    var pdfMargin2 = pdfMargin*2;
    var pageNumber = 0;
    var sections = document.querySelectorAll('#'+props.htmlId);

    //create page per section
    for (let i = 0; i < sections.length; i++) {
      
        var section = sections[i] as HTMLElement;
        if (section) {
            await html2canvas(section, {scale: 1.5, useCORS: true}).then((canvas) => { 
              var canvasPage = document.createElement('canvas');
              var pageCtx = canvasPage.getContext('2d', { willReadFrequently: true });
              canvasPage.width = canvas.width;
              var canvasPageHeight = Math.floor(canvas.width * (pdfHeight / pdfWidth));
              var canvasHeightLeft = canvas.height;   
              
              const drawImageAdj = (h: number, w: number, adj: number) => {
                if (pageCtx && imageContent(pageCtx, pdfMargin+5, h-1, 200, 1)) {
                  h =  h + adj;
                  canvasPage.height = h;
                  pageCtx.clearRect(0, 0, w, h);
                  pageCtx.drawImage(canvas, 0, canvas.height - canvasHeightLeft, w, h, 0, 0, w, h);
                  return true;
                }
                return false;
              }

              while (canvasHeightLeft>0) {
                  pageNumber++;

                  var printOptions = props.printOptions;
                  var optionText = JSON.stringify(printOptions);
                  var optionValue = {pagenumber: pageNumber}
                  printOptions = JSON.parse(AppService.replaceValue(optionText, optionValue))
            
                  //create image per page
                  var canvasPageHeightAdj = printOptions.marginAdj?.filter((d: any) => d.pageNumber===pageNumber)?.[0]?.value || 0;
                  canvasPage.height = canvasPageHeight + canvasPageHeightAdj;
                  if (pageCtx) {
                      var w = canvasPage.width;
                      var h = canvasPage.height;
                      pageCtx.fillStyle = 'white';
                      pageCtx.fillRect(0, 0, w, h);
                      pageCtx.drawImage(canvas, 0, canvas.height - canvasHeightLeft, w, h, 0, 0, w, h);
                      if (drawImageAdj(canvasPage.height, w, 16)) drawImageAdj(canvasPage.height, w, 16);
                  }
                  var imgData = canvasPage.toDataURL('image/png', 1);
                  pdf.addImage(imgData, 'PNG', pdfMargin, pdfMargin, pdfWidth-pdfMargin2, 0);
                  
                  //header footer page
                  var headerTop = pdfMargin/2;
                  var footerTop = pdfHeight-headerTop;
                  pdf.setFontSize(9);
                  if (printOptions.headerText && pageNumber>=printOptions.headerText.start) {
                    pdf.text(printOptions.headerText.left, pdfMargin, headerTop, {align: 'left'});
                    pdf.text(printOptions.headerText.center, pdfWidth/2, headerTop, {align: 'center'});
                    pdf.text(printOptions.headerText.right, pdfWidth-pdfMargin, headerTop, {align: 'right'});
                  }
                  if (printOptions.footerText && pageNumber>=printOptions.footerText.start) {
                    pdf.text(printOptions.footerText.left, pdfMargin, footerTop, {align: 'left'});
                    pdf.text(printOptions.footerText.center, pdfWidth/2, footerTop, {align: 'center'});
                    pdf.text(printOptions.footerText.right, pdfWidth-pdfMargin, footerTop, {align: 'right'});
                  }

                  //calculate canvas left
                  canvasHeightLeft -= canvasPage.height;
                  if (canvasHeightLeft<67) canvasHeightLeft = 0; //skip minimum canvas size
                  if (canvasHeightLeft>0) pdf.addPage(); 
              }                     

              canvasPage.remove(); 
              setProgress(Math.floor((i+1)/sections.length*100));
            })
            if (i < sections.length-1) pdf.addPage();
        }
    }
    if (props.getFiles) props.getFiles?.({path: pdf.output('datauristring'), filename: filename});
    else pdf.save(filename);
  }


  React.useEffect(() => {
    if (props.save && !save) {
      setTimeout(() => printDocument(), 1000);
      setSave(true);
    }  

    if (progress===100) {
      setOpen(false);
      props.onProgress?.(false);
      setTimeout(() => setProgress(0), 100);
    }

  }, [props.save, save, printDocument, progress]);

  return (
    <>
      {props.buttonCaption?.trim()!=='' &&
        <Button variant='contained' label={props.buttonCaption} onClick={printDocument}/>
      }
      
      <Dialog open={open}>  
        <DialogContent>
          <Typography sx={{typography: {xs: 'caption', md: 'h6'}, marginBottom: 2}} >
            {props.caption || 'Please wait while document is generated.'}
          </Typography>
          <Grid container justifyContent='center'>
            <ProgressBar value={progress} totalValue={100}/>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  )
  
}