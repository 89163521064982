import React from 'react';
import { History } from 'history';
import { InputMoney, InputPercentage, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './transactionamount-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactionamountDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Picklist name='transaction' label='Transaction' value={this.state.dataRow.transaction} caption={this.state.dataRow.transactionCaption} readOnly/>
                <Picklist name='amounttype' label='Type' value={this.state.dataRow.amounttype} caption={this.state.dataRow.amounttypeCaption} readOnly/>
                {this.state.dataRow.usepercentage &&  
                <InputPercentage name='percentage' label='Percentage' value={this.state.dataRow.percentage} readOnly/>
                }
                <InputMoney name='value' label='Amount' value={this.state.dataRow.value} readOnly/>
                
              </BaseDelete>
        );
    }
}