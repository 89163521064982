import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import DivisionList from './division-list.component';
import DivisionCreate from './division-create.component';
import DivisionView from './division-view.component';
import DivisionEdit from './division-edit.component';
import DivisionCopy from './division-copy.component';
import DivisionDelete from './division-delete.component';

interface Props {
  root?: string;
}

export const DivisionRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/division/create'} component={DivisionCreate} />
      <Route path={(props.root||'')+'/division/view'} component={DivisionView} />
      <Route path={(props.root||'')+'/division/edit'} component={DivisionEdit} />
      <Route path={(props.root||'')+'/division/copy'} component={DivisionCopy} />
      <Route path={(props.root||'')+'/division/delete'} component={DivisionDelete} />
      <Route path={(props.root||'')+'/division'} render={(params)=> <DivisionList {...params} root={props.root} />} /> 
    </Switch>
  );
};
