import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PersonalinfotypeList from './personalinfotype-list.component';
import PersonalinfotypeCreate from './personalinfotype-create.component';
import PersonalinfotypeView from './personalinfotype-view.component';
import PersonalinfotypeEdit from './personalinfotype-edit.component';
import PersonalinfotypeCopy from './personalinfotype-copy.component';
import PersonalinfotypeDelete from './personalinfotype-delete.component';

interface Props {
  root?: string;
}

export const PersonalinfotypeRouteVendor: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>     
      <Route path={`${props.root||''}/vendortype/create`} render={(params)=> <PersonalinfotypeCreate {...params} caption='Create Vendor Type' personalinfotypeType='vendor' />} />    
      <Route path={`${props.root||''}/vendortype/view`} render={(params)=> <PersonalinfotypeView {...params} caption='View Vendor Type' personalinfotypeType='vendor' />} />        
      <Route path={`${props.root||''}/vendortype/edit`} render={(params)=> <PersonalinfotypeEdit {...params} caption='Edit Vendor Type' personalinfotypeType='vendor' />} />        
      <Route path={`${props.root||''}/vendortype/copy`} render={(params)=> <PersonalinfotypeCopy {...params} caption='Duplicate Vendor Type' personalinfotypeType='vendor' />} />     
      <Route path={`${props.root||''}/vendortype/delete`} render={(params)=> <PersonalinfotypeDelete {...params} caption='Delete Vendor Type' personalinfotypeType='vendor' />} />        
      <Route path={(props.root||'')+'/vendortype'} render={(params)=> <PersonalinfotypeList {...params} root={props.root} caption='Vendor Type' personalinfotypeType='vendor' />} /> 
    </Switch>
  );
};
