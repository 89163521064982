import * as React from 'react';
import { Link } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { Button } from './button';
import { Card } from './card';
import ZoomMtgEmbedded from "@zoom/meetingsdk/embedded";


interface Props {
  id?: string;
  label?: string;
  url?: string;
  logoUrl?: string;
  titleColor?: any;
}

export const ZoomMeet : React.FunctionComponent<Props> = (props) => {
  //https://marketplace.zoom.us/ -> log to developer account
  //https://github.com/zoom/meetingsdk-react-sample
  //https://developers.zoom.us/docs/meeting-sdk
  
        
  const client = ZoomMtgEmbedded.createClient();

  const signatureEndpoint = "https://zoomsdk-sign-generator-express.onrender.com"; // 
  //const signatureEndpoint = "https://zoomsdk-sign-generator-express.onrender.com/?code=B3VcZdiWlHLy5VrZSoGQ0iMM_HCczowLg"
  //https://zoom.us/oauth/authorize?client_id=wwinvLFvQqmxd9KAqT2edw&response_type=code&redirect_uri=https%3A%2F%2Fzoomsdk-sign-generator-express.onrender.com
  const sdkKey = 'u30Ik_g6TYSyyCp5WBmMAQ';//process.env.REACT_APP_ZOOM_MEETING_SDK_KEY;
  //client secret: ichDz28UN5092xAzIjXE3Hj8NrJMnlUK
  const meetingNumber = "73224079115";
  const passWord = "HvVp6X";
  const role = 1;
  const userName = "Nono Rukmana";
  const userEmail = "lengkah@yahoo.com";
  const registrantToken = "";
  const zakToken = "jHt6a7ApeNilznI8rMzR0WxhkTGiCoovw";
  //const zakToken = "fCHkVbIgS2GUvId5IW2mZw";
  //const zakToken = "859323";

  
  const getAuth = async() => {
    try {
      const req = await fetch("https://api.zoom.us/v2/users/lengkah@yahoo.com/token?type=zak", {
        method: "GET",
        headers: { "Content-Type": "application/json", "Authorization" : "Bearer 1EHejcTZ22I6qwmunwyR-2CHIqpl1m35Q" },
      });
      //const res = await req.json()
      console.log(req)
    } catch (e) {
      console.log('getAuth', e);
    }
  };

  const createMeeting = async() => {
    try {
      const req = await fetch(`https://api.zoom.us/v2/users/${userEmail}/meetings`, {
        method: "POST",
        headers: { "Content-Type": "application/json", "Authorization": `Bearer ${zakToken}` },
        body: JSON.stringify({})
      })
    
    
    
      //const res = await req.json()
      console.log(req)
    } catch (e) {
      console.log('createMeeting', e);
    }
  };

  const getSignature = async() => {
    getAuth();
    try {
      const req = await fetch(signatureEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          meetingNumber: meetingNumber,
          role: role,
        }),
      }); 
      const res = await req.json()
      const signature = res.signature as string;
      createMeeting();
      startMeeting(signature);
    } catch (e) {
      console.log('getSignature', e);
    }
  };

  const startMeeting = async(signature: string) => {
    const meetingSDKElement = document.getElementById("meetingSDKElement")!;
    try {
      await client.init({
        zoomAppRoot: meetingSDKElement,
        language: "en-US",
        patchJsMedia: true,
        leaveOnPageUnload: true,
      });
      await client.join({
        signature: signature,
        sdkKey: sdkKey,
        meetingNumber: meetingNumber,
        password: passWord,
        userName: userName,
        userEmail: userEmail,
        tk: registrantToken,
        zak: zakToken,
        
      });
      console.log("joined successfully");
    } catch (error) {
      console.log('startMeeting', error);
    }
  }

  //fCHkVbIgS2GUvId5IW2mZw -> secret key
  
  return (
    <Card>
      <h1>Join Zoom Meeting</h1>
      <div id="meetingSDKElement" />
      <Button variant='contained' color='secondary'  label='Join Meeting' onClick={getSignature} />
      <a href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_ZOOM_MEETING_SDK_KEY}&redirect_uri=${signatureEndpoint}`}>
        Connect Zoom
      </a>
    </Card>
  );
}