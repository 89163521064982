import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PaymenttypeList from './paymenttype-list.component';
import PaymenttypeCreate from './paymenttype-create.component';
import PaymenttypeView from './paymenttype-view.component';
import PaymenttypeEdit from './paymenttype-edit.component';
import PaymenttypeCopy from './paymenttype-copy.component';
import PaymenttypeDelete from './paymenttype-delete.component';

interface Props {
  root?: string;
}

export const PaymenttypeRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/paymenttype/create'} component={PaymenttypeCreate} />
      <Route path={(props.root||'')+'/paymenttype/view'} component={PaymenttypeView} />
      <Route path={(props.root||'')+'/paymenttype/edit'} component={PaymenttypeEdit} />
      <Route path={(props.root||'')+'/paymenttype/copy'} component={PaymenttypeCopy} />
      <Route path={(props.root||'')+'/paymenttype/delete'} component={PaymenttypeDelete} />
      <Route path={(props.root||'')+'/paymenttype'} render={(params)=> <PaymenttypeList {...params} root={props.root} />} /> 
    </Switch>
  );
};
