import * as React from 'react';
import Model from './transaction-model';
import BasePicklistCard from '../base/base-picklistcard.component';

interface IProps { 
  caption?: string;
  currentId?: number;
  transactiontypeType?: string;
  personalinfotypeType?: string,
  personalinfoId?: number;  
  getDataRow: (data: any) => void;
  getDataSet?: (data: any, page?: any) => void;
}

interface IState {
}

export default class TransactionPicklistCard extends React.Component<IProps, IState> {

  private getDataRow = (data: any) => {
    this.props.getDataRow?.(data)
  };
  
  public render(): React.ReactNode {
    return (
      <BasePicklistCard caption={this.props.caption} captionColor='primary.light' endPointFilter={Model.endPointFilter(this.props.transactiontypeType, this.props.personalinfotypeType, this.props.personalinfoId)} model={Model} currentId={this.props.currentId} getDataRow={this.getDataRow} getDataSet={this.props.getDataSet} header={Model.columnPicklistCard} cardCount={4} swipe='horizontal' disableBackButton/>
    );
  }
}
