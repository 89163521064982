import * as React from 'react';
import { Typography } from '@mui/material';

interface Props {
  id?: string;
  content: string;
  sx?: any;
}

export const TextToHtml: React.FunctionComponent<Props> = (props) => {
  return (
    <Typography id={props.id} component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}, lineHeight: {xs: 1.2, md: 2}, whiteSpace: 'pre-line', overflow: 'auto', ...props.sx}} dangerouslySetInnerHTML={{ __html: props.content}}/>                   
  )
  
}