import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionView from './transaction-view.component';
import TransactionList from './transaction-list.component';

interface Props {
  root?: string;
}

export const TransactionRouteJournal: React.FunctionComponent<Props> = (props) => {
  const disableFields = ['owner','transactionType','employee','customer','vendor','transactiondetail','transactionamount','transactionpayment','invoice'];
    
  return (
    <Switch>
        <Route path={`${props.root||''}/transactionjournal/view`} render={(params)=> <TransactionView {...params} key={params.history.location.key} root={props.root} caption={'View Transaction Journal'} disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactionjournal'} render={(params)=> <TransactionList {...params} key={params.history.location.key} root={props.root} caption='Transaction Journal' status='approved' url='/transactionjournal' actions={['view']} />} /> 
    </Switch>
  );
};
