import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputSelect, InputNumber, Switch } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import BaseView from '../base/base-view.component';
import Model from './region-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class RegionCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private historyState: any = this.props.history.location.state;
    
    private getDataRowParent = (data: any) => {
        const typeIdx = Model.optionType.findIndex((d: any) => d.key === data.type);
        const type = Model.optionType?.[typeIdx+1]?.key || '';
        this.setState({ dataRow: { ...this.state.dataRow, 'type': type, 'parent': data?.id || null, 'parentCaption': data?.name || ''  } });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        const typeCaption = Model.optionType.filter((d: any) => d.key === this.state.dataRow.type)?.[0]?.value;

        return (
            //editable..      
            <>     
            {this.historyState?.id>0 &&
            <BaseView history={this.props.history} caption=''
                model={Model} getDataRow={this.getDataRowParent}/>
            }              
            <BaseCreate history={this.props.history} caption={'Create '+typeCaption}
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                {this.state.dataRow.type === 'village' &&
                <>
                <InputNumber name='zipcode' label='Zip Code' value={this.state.dataRow.zipcode} error={this.state.dataError?.zipcode} onChange={this.onChange} />
                <Input name='code' label='Region Code' value={this.state.dataRow.code} error={this.state.dataError?.code} onChange={this.onChange} />
                <InputNumber name='latitude' label='Latitude' value={this.state.dataRow.latitude} error={this.state.dataError?.latitude} onChange={this.onChange} />
                <InputNumber name='longitude' label='Longitude' value={this.state.dataRow.longitude} error={this.state.dataError?.longitude} onChange={this.onChange} />
                <InputNumber name='elevation' label='Elevation' value={this.state.dataRow.elevation} error={this.state.dataError?.elevation} onChange={this.onChange} />
                </>
                }
                {this.historyState?.id>0 &&
                <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/> 
                }
            </BaseCreate>
            </>
        );
    }     
     
}