import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EventresultList from './eventresult-list.component';
import EventresultCreate from './eventresult-create.component';
import EventresultView from './eventresult-view.component';
import EventresultEdit from './eventresult-edit.component';
import EventresultCopy from './eventresult-copy.component';
import EventresultDelete from './eventresult-delete.component';

interface Props {
  root?: string;
}

export const EventresultRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>           
      <Route path={(props.root||'')+'/eventresult/create'} component={EventresultCreate} />
      <Route path={`${props.root||''}/eventresult/view`} render={(params)=> <EventresultView {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/eventresult/edit`} render={(params)=> <EventresultEdit {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/eventresult/copy`} component={EventresultCopy} />
      <Route path={`${props.root||''}/eventresult/delete`} render={(params)=> <EventresultDelete {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/eventresult'} render={(params)=> <EventresultList {...params} root={props.root} />} /> 
    </Switch>
  );
};
