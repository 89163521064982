import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import QuestionList from './question-list.component';
import QuestionCreate from './question-create.component';
import QuestionView from './question-view.component';
import QuestionEdit from './question-edit.component';
import QuestionCopy from './question-copy.component';
import QuestionDelete from './question-delete.component';

interface Props {
  root?: string;
}

export const QuestionRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/question/create'} component={QuestionCreate} />
      <Route path={`${props.root||''}/question/view`} render={(params)=> <QuestionView {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/question/edit`} render={(params)=> <QuestionEdit {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/question/copy'} component={QuestionCopy} />
      <Route path={`${props.root||''}/question/delete`} render={(params)=> <QuestionDelete {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/question'} component={QuestionList} />
    </Switch>
  );
};
