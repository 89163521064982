import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputDate, InputFile, InputMultiline, InputSelect, Picklist, Switch } from '../../components';
import BaseCopy from '../base/base-copy.component'; 
import Model from './personalinfo-model';  //editable..
import MedialibraryModel from '../medialibrary/medialibrary-model';
import OwnerPicklist from '../owner/owner-picklist.component';
import RegionPicklist from '../region/region-picklist.component';
import PersonalinfotypePicklist from '../personalinfotype/personalinfotype-picklist.component';
import DivisionPicklist from '../division/division-picklist.component';
import UserPicklistPersonalinfo from '../user/user-picklist-personalinfo.component';
import ScheduleChecklist from '../schedule/schedule-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    root?: string;
    caption?: string;
    personalinfotypeType?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowMedia: any;
}

export default class PersonalinfoCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowMedia: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataRowPersonalinfotype = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'personalinfotype': data?.id || null, 'personalinfotypeCaption': data?.name || '', 'personalinfotypeType': data?.type || 'customer'  }  });
    };
    
    private getDataRowBirthplace = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'birthplace': data?.id || null, 'birthplaceCaption': data?.description || ''  }  });
    };
    
    private getDataRowDivision = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'division': data?.id || null, 'divisionCaption': data?.name || ''  }  });
    };
    
    private getDataRowSchedule = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'schedule': data || [], 'scheduleCaption': data?.length>0? (data[0].name || data[0]?.attributes.name) + (data.length>1? ', ...': '') : ''}  });
    }; 

    private getDataRowUser = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'user': data?.id || null, 'userCaption': data?.name || ''  }  });
    };

    private getFiles = (data:any) => {
        this.setState({dataRowMedia: {...this.state.dataRowMedia, endPoint: MedialibraryModel.endPoint, endPointDelete: MedialibraryModel.endPoint2, dataRow:{id:this.state.dataRow.imageId, files: data, ref: Model.endPointMedia, field:'image'}}});
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseCopy history={this.props.history} caption={this.props.caption} 
                model={Model} dataRow={this.state.dataRow} dataRowMedia={this.state.dataRowMedia} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist> 
                }            
                <Picklist name='personalinfotype' label='Type' value={this.state.dataRow.personalinfotype} caption={this.state.dataRow.personalinfotypeCaption} error={this.state.dataError?.personalinfotype} onChange={this.onChange} >
                    <PersonalinfotypePicklist currentId={this.state.dataRow.personalinfotype} type={this.props.personalinfotypeType} getDataRow={this.getDataRowPersonalinfotype}/>
                </Picklist>           
                <Input name='phone' label='Phone' value={this.state.dataRow.phone} error={this.state.dataError?.phone} onChange={this.onChange} />
                <InputEmail name='email' label='Email' value={this.state.dataRow.email} error={this.state.dataError?.email} onChange={this.onChange} />
                <Picklist name='birthplace' label='Place of Birth' value={this.state.dataRow.birthplace} caption={this.state.dataRow.birthplaceCaption} error={this.state.dataError?.birthplace} onChange={this.onChange} >
                    <RegionPicklist caption='Select Place of Birth' currentId={this.state.dataRow.birthplace} regionType='city' getDataRow={this.getDataRowBirthplace}/>
                </Picklist>  
                <InputDate name='birthdate' label='Date of Birth' value={this.state.dataRow.birthdate} error={this.state.dataError?.birthdate} onChange={this.onChange} disableTime/>
                <InputSelect name='gender' label='Gender' value={this.state.dataRow.gender} options={Model.optionGender} error={this.state.dataError?.gender} onChange={this.onChange} />
                <InputFile name='imageUrl' label='Photo' value={this.state.dataRow.imageUrl} type='image' getFiles={this.getFiles} error={this.state.dataError?.image} onChange={this.onChange} />
                {this.state.dataRow.personalinfotypeType === 'employee' &&
                <>
                <Picklist name='division' label='Division' value={this.state.dataRow.division} caption={this.state.dataRow.divisionCaption} error={this.state.dataError?.division} clearValue={this.getDataRowDivision} onChange={this.onChange} >
                    <DivisionPicklist currentId={this.state.dataRow.division} getDataRow={this.getDataRowDivision}/>
                </Picklist>
                <Picklist name='schedule' label='Schedule List' value={this.state.dataRow.schedule} caption={this.state.dataRow.scheduleCaption} disableAutoClose clearValue={this.getDataRowSchedule} error={this.state.dataError?.schedule} onChange={this.onChange} >
                    <ScheduleChecklist history={this.props.history} currentId={this.state.dataRow.schedule} getDataRow={this.getDataRowSchedule} disableBackButton/>
                </Picklist>
                </>
                }
                <Picklist name='user' label='User' value={this.state.dataRow.user} caption={this.state.dataRow.userCaption} error={this.state.dataError?.user} clearValue={this.getDataRowUser} onChange={this.onChange} >
                    <UserPicklistPersonalinfo currentId={this.state.dataRow.user} getDataRow={this.getDataRowUser}/>
                </Picklist>  
            </BaseCopy>
        );
    }
}