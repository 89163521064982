import React from 'react';
import { History } from 'history';
import { Input, InputNumber, InputMultiline, Switch, Picklist, Layout } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './eventresult-model';  //editable..
import EventresultList from './eventresult-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class EventresultView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Layout history={this.props.history} content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                        <InputNumber name='seq' label='Seq' value={this.state.dataRow.seq} readOnly/>
                        <InputNumber name='valuemin' label='Value Min.' value={this.state.dataRow.valuemin} readOnly/>
                        <InputNumber name='valuemax' label='Value Max.' value={this.state.dataRow.valuemax} readOnly/>
                        <InputNumber name='scale' label='Scale' value={this.state.dataRow.scale} readOnly/>
                        <InputNumber name='factor' label='Factor' value={this.state.dataRow.factor} readOnly/>
                        <Input name='tag' label='Tag' value={this.state.dataRow.tag} readOnly/>
                    </>
                    },

                    {caption: 'Sections', child: 
                    this.state.dataRow.id>0 &&
                        <EventresultList history={this.props.history} root={this.props.root} caption='Event Result Section' parentId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                    },

                    ]}
                    
                />                  
            </BaseView>
        );
    }
}