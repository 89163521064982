import * as React from 'react';
import { Grid, Rating as RatingMui, Typography} from '@mui/material';
import { Icon } from '.';

interface Props {
  id?: string;
  name: string;
  label?: string;
  value: number;
  max: number;
  size?: 'small'|'medium'|'large'|'extralarge';
  sx?: any;
  fullWidth?: boolean;
  selectedOnly?: boolean;
  readOnly?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
                    
}

export const Rating: React.FunctionComponent<Props> = (props) => {
  const sxFullWidth = props.fullWidth? {width: '100%', justifyContent: 'space-between'} : {};
  const onChange = (e: React.SyntheticEvent<Element, Event>, newValue: number|null) => { 
    props.onChange?.(props.name, newValue?.toString()||'0');
  };

  return (
    <Grid id={props.id || props.name}>
      <Typography variant='caption' color='text.secondary' display='block'>
        {props.label} 
      </Typography>
      <RatingMui
        value={props.value}
        max={props.max}
        size={props.size!=='extralarge'? props.size : 'medium'}
        sx={{...props.sx, ...sxFullWidth}}
        icon={props.size==='extralarge'? <Icon name='star_icon' size='large'/> : undefined} 
        emptyIcon={props.size==='extralarge'? <Icon name='star_icon' color='secondary.dark' size='large'/> : undefined} 
        highlightSelectedOnly={props.selectedOnly}
        readOnly = {props.readOnly}
        onChange={onChange}
      /> 
      <Typography variant='caption' color='error' display='block' >
        {props.error}
      </Typography>
    </Grid>
  )
};


