import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, Switch, Picklist } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './menugroup-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import MenuChecklist from '../menu/menu-checklist.component';
import OwnerChecklist from '../owner/owner-checklist.component';
import UserChecklist from '../user/user-checklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class MenugroupCreate extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
        
    private getDataRowMenu = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'menu': data || [], 'menuCaption': data?.length>0? (data[0].name || data[0]?.attributes.name) + (data.length>1? ', ...': '') : ''}  });
    };  

    private getDataRowAccessOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'accessowner': data || [], 'accessownerCaption': data?.length>0? (data[0].name || data[0]?.attributes.name) + (data.length>1? ', ...': '') : ''}  });
    };  
   
    private getDataRowUser = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'user': data || [], 'userCaption': data?.length>0? data[0].name + (data.length>1? ', ...': '') : ''}  });
    };
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError}>               
          
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                } 
                <Picklist name='menu' label='Menu Access List' value={this.state.dataRow.menu} caption={this.state.dataRow.menuCaption} disableAutoClose clearValue={this.getDataRowMenu} error={this.state.dataError?.menu} onChange={this.onChange} >
                    <MenuChecklist history={this.props.history} currentId={this.state.dataRow.menu} getDataRow={this.getDataRowMenu} disableBackButton/>
                </Picklist> 
                {AppService.isAppFullVersion() &&
                <Picklist name='accessowner' label='Owner Access List' value={this.state.dataRow.accessowner} caption={this.state.dataRow.accessownerCaption} disableAutoClose clearValue={this.getDataRowAccessOwner} error={this.state.dataError?.accessowner} onChange={this.onChange} >
                    <OwnerChecklist history={this.props.history} currentId={this.state.dataRow.accessowner} getDataRow={this.getDataRowAccessOwner} disableBackButton/>
                </Picklist> 
                }
                <Picklist name='user' label='User Access List' value={this.state.dataRow.user} caption={this.state.dataRow.userCaption} disableAutoClose clearValue={this.getDataRowUser} error={this.state.dataError?.user} onChange={this.onChange} >
                    <UserChecklist history={this.props.history} currentId={this.state.dataRow.user} getDataRow={this.getDataRowUser} disableBackButton/>
                </Picklist>
                <Input name='icon' label='Icon' value={this.state.dataRow.icon} error={this.state.dataError?.icon} onChange={this.onChange} />
                 
            </BaseCreate>
        );
    }     
     
}