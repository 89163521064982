import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EventList from './event-list.component';
import EventCreate from './event-create.component';
import EventView from './event-view.component';
import EventEdit from './event-edit.component';
import EventCopy from './event-copy.component';
import EventDelete from './event-delete.component';

interface Props {
  root?: string;
}

export const EventRouteTemplate: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
      <Route path={`${props.root||''}/eventtemplate/create`} render={(params)=> <EventCreate {...params} key={params.history.location.key} astemplate='yes' />} />        
      <Route path={`${props.root||''}/eventtemplate/view`} render={(params)=> <EventView {...params} key={params.history.location.key} root={props.root} astemplate='yes' />} />        
      <Route path={`${props.root||''}/eventtemplate/edit`} render={(params)=> <EventEdit {...params} key={params.history.location.key} root={props.root} astemplate='yes' />} />        
      <Route path={`${props.root||''}/eventtemplate/copy`} render={(params)=> <EventCopy {...params} key={params.history.location.key}  astemplate='yes' />} />        
      <Route path={`${props.root||''}/eventtemplate/delete`} render={(params)=> <EventDelete {...params} key={params.history.location.key} root={props.root} astemplate='yes' />} />        
      <Route path={(props.root||'')+'/eventtemplate'} render={(params)=> <EventList {...params} root={props.root} astemplate='yes' url='/eventtemplate'/>} /> 
    </Switch>
  );
};
