import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputNumber, InputSelect, InputFile, Switch } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './civilnumber-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class CivilnumberView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>   
                
                <Input name='name' label='Number' value={this.state.dataRow.name} readOnly/>
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' readOnly/>
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} readOnly/>
                <InputNumber name='seq' label='Sequence' value={this.state.dataRow.seq} readOnly/>
                <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' readOnly/>
                    
            </BaseView>
        );
    }
}