import * as React from 'react';
import { Typography } from '@mui/material';
import { CircleNumber, TextToHtml } from '.';

interface Props {
  id?: string;
  data: any;
  start?: number;
  size?: number;
  spacing?: number;
  textAlign?: 'left'|'center'|'right'|'justify'
  disableNumber?: boolean;
}

export const Numbering: React.FunctionComponent<Props> = (props) => {
  
  return (
    <>
      {props.data?.map((dataRow: string, i: number) => {
        var label = !props.disableNumber ? undefined : ((props.start||1)+i)
        return (
            <Typography key={i} component='div' sx={{typography: {xs: 'subtitle1', md: 'h6'}}} textAlign={props.textAlign} display='flex' mb={props.spacing || 2}>
                <CircleNumber color='primary.light' size={props.size} label={label}/>
                <TextToHtml content={dataRow}/>
            </Typography>
        )
      })}
    </>
  );
  
};
