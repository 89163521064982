import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionList from './transaction-list.component';
import TransactionCreate from './transaction-create.component';
import TransactionView from './transaction-view.component';
import TransactionEdit from './transaction-edit.component';
import TransactionCopy from './transaction-copy.component';
import TransactionDelete from './transaction-delete.component';
import TransactionEditStatus from './transaction-edit-status.component';

interface Props {
  root?: string;
}

export const TransactionRouteGeneral: React.FunctionComponent<Props> = (props) => {
  const disableFields = ['status','owner','transactionType','employee','customer','vendor','transactiondetail','transactionamount','transactionpayment','invoice'];
  
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactiongeneral/create'} render={(params)=> <TransactionCreate {...params} key={params.history.location.key} root={props.root} caption={'Create General Transaction'} transactiontypeType='general' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/view`} render={(params)=> <TransactionView {...params} key={params.history.location.key} root={props.root} caption={'View General Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/edit`} render={(params)=> <TransactionEdit {...params} key={params.history.location.key} root={props.root} caption={'Edit General Transaction'} transactiontypeType='general' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/copy`} render={(params)=> <TransactionCopy {...params} key={params.history.location.key} root={props.root} caption={'Duplicate General Transaction'} transactiontypeType='general' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/delete`} render={(params)=> <TransactionDelete {...params} key={params.history.location.key} root={props.root} caption={'Delete General Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactiongeneral/status`} render={(params)=> <TransactionEditStatus {...params} key={params.history.location.key} root={props.root} caption={'Change Status General Transaction'} transactiontypeType='general' disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactiongeneral'} render={(params)=> <TransactionList {...params} key={params.history.location.key} root={props.root}  caption='General Transaction' transactiontypeType='general' actions={['...','status']} url='/transactiongeneral'/>} /> 
    </Switch>
  );
};
