import ApiService from '../../services/api.service';
import AppService from '../../services/app.service';
import OwnerModel from '../owner/owner-model';
import UserModel from '../user/user-model';

export default class TransactionModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;    
    transactiontype: any;
    transactiontypeCaption: string;
    transactiontypePricetype: string;
    transactiondate: any;
    transactiondateFmt: string;
    note: string;
    image: any;
    imageId: number;
    imageUrl: string;    
    employee: any;
    employeeCaption: string;
    customer: any;
    customerCaption: string;
    vendor: any;
    vendorCaption: string;  
    division: any;
    divisionCaption: string;  
    schedule: any;
    scheduleCaption: string;  
    totaldetail: number;
    totalamount: number;
    total: number;
    totalpayment: number;
    remainingpayment: number;
    statuspayment: string;
    statuspaymentCaption: string;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'draft';
        this.statusCaption = TransactionModel.optionStatus.filter((d: any) => d.key === this.status)?.[0]?.value || 'Draft';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.transactiontype = dataRow.attributes.transactiontype?.data?.id || null;
        this.transactiontypeCaption = dataRow.attributes.transactiontype?.data?.attributes.name || dataRow.attributes.transactiontype?.data?.id;
        this.transactiontypePricetype = dataRow.attributes.transactiontype?.data?.attributes.pricetype || 'pricebase';
        this.transactiondate = dataRow.attributes.transactiondate;
        this.transactiondateFmt = AppService.dateFormat(dataRow.attributes.transactiondate, 'y-m-d h:n');
        this.note = dataRow.attributes.note; 
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ApiService.svrUrl+this.image.attributes.url : '';
        this.employee = dataRow.attributes.employee?.data?.id || null;
        this.employeeCaption = dataRow.attributes.employee?.data?.attributes.name || dataRow.attributes.employee?.data?.id;
        this.customer = dataRow.attributes.customer?.data?.id || null;
        this.customerCaption = dataRow.attributes.customer?.data?.attributes.name || dataRow.attributes.customer?.data?.id;
        this.vendor = dataRow.attributes.vendor?.data?.id || null;
        this.vendorCaption = dataRow.attributes.vendor?.data?.attributes.name || dataRow.attributes.vendor?.data?.id;
        this.division = dataRow.attributes.division?.data?.id || null;
        this.divisionCaption = dataRow.attributes.division?.data?.attributes.name || dataRow.attributes.division?.data?.id;
        this.schedule = dataRow.attributes.schedule?.data?.id || null;
        this.scheduleCaption = dataRow.attributes.schedule?.data?.attributes.name || dataRow.attributes.schedule?.data?.id;
        this.totaldetail = dataRow.attributes.totaldetail;        
        this.totalamount = dataRow.attributes.totalamount;        
        this.total = dataRow.attributes.total;        
        this.totalpayment = dataRow.attributes.totalpayment; 
        this.remainingpayment = this.totalpayment - this.total;     
        this.statuspayment =  TransactionModel.getStatuspayment(this.totalpayment, this.total);
        this.statuspaymentCaption = TransactionModel.optionStatuspayment.filter((d: any) => d.key === this.statuspayment)?.[0]?.value || 'Unpaid';
        
        
    } 

    public static endPoint: string = 'transactions';
    public static endPointMedia: string = 'api::transaction.transaction';
    public static endPointFilter = (transactiontype?: string, personalinfotypeType?: string, personalinfoId?: number)=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[owner]='+owner+(transactiontype? '&filters[transactiontype][type]='+transactiontype : '')+(personalinfotypeType && personalinfoId? '&filters['+personalinfotypeType+']='+personalinfoId : ''));
    } 
    public static endPointFilter2 = ()=>{
        const user = UserModel.getUser();
        return ( '?populate=*&filters[customer][user]='+user+'&filters[status]=draft');
    } 
    public static url: string = '/transaction';
    public static caption: string = 'Transaction';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'draft',
        statusCaption: 'Draft',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),    
        transactiontype: null,
        transactiontypeCaption: '',
        transactiontypePricetype: 'pricebase',
        transactiondate: AppService.dateCurrent(),
        transactiondateFmt: '',
        note: '',
        image: null,
        imageId: 0,
        imageUrl: '',
        employee: null,
        employeeCaption: '',
        customer: null,
        customerCaption: '',
        vendor: null,
        vendorCaption: '', 
        division: null,
        divisionCaption: '',
        schedule: null,
        scheduleCaption: '', 
        totaldetail: 0,
        totalamount: 0,
        total: 0,
        totalpayment: 0,
        remainingpayment: 0,
        statuspayment: 'unpaid',
        statuspaymentCaption: 'Unpaid',
 
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'customerCaption', caption: 'Customer' },
        { name: 'transactiondateFmt', caption: 'Date' },
        { name: 'statuspaymentCaption', caption: 'Payment', type: 'state', default: 'Paid' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Approved' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'transactiondate', caption: 'Date', type: 'datetime' },
    ]

    public static columnPicklistCard = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'transactiondate', caption: 'Date', type: 'datetime' },
        { name: 'statusCaption', caption: 'State', type: 'state', default: 'Active' },
    ]

    public static optionStatus = [
        { key: 'draft', value: 'Draft' },
        { key: 'approved', value: 'Approved' },
        { key: 'rejected', value: 'Rejected' },
    ]
    
    public static optionStatuspayment = [
        { key: 'unpaid', value: 'Unpaid' },
        { key: 'paid', value: 'Paid' },
        { key: 'overpayment', value: 'Over Payment' },
    ]

    public static getStatuspayment (totalpayment: number, total: number) {
        return total === 0 || totalpayment < total? 'unpaid' : totalpayment === total? 'paid' : 'overpayment';
    }

}