import * as React from 'react';
import { Dialog as DialogMui, DialogContent, IconButton } from '@mui/material';
import { Icon, SwipeDrawer } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  open: boolean;
  maxWidth?: 'xs'|'md'|'lg';
  onClose?: () => void;
  children?: React.ReactNode;
}

export const Dialog: React.FunctionComponent<Props> = (props) => {
  
  const isMobile = AppService.isMobile();

  return (
    
    <>
    {isMobile?
      <SwipeDrawer variant='bottom' open={props.open || false} onClose={props.onClose}>
        {props.children}
      </SwipeDrawer>
    :
      <DialogMui open={props.open || false} fullWidth={true} maxWidth={props.maxWidth || 'md'} > 
        <DialogContent sx={{overflow: 'hidden'}}>
          <IconButton sx={{ position: 'absolute', right: '2%', top: 30}} onClick={props.onClose}> 
            <Icon name='close' color='primary.main'/>
          </IconButton>
          {props.children}
        </DialogContent>
      </DialogMui>
    }
    </>
  );
};

export default Dialog;
