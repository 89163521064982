import * as React from 'react';
import { History } from 'history';
import { Grid, Typography } from '@mui/material';
import { Card, Pagination, Spinner } from '../../components';
import Model from './question-model'; //editable..
import BaseList from '../base/base-list.component';
import EventquestionViewQuestionoption from '../eventquestion/eventquestion-view-questionoption.component';

interface IProps {  
  history: History;
  root?: string;
  eventsectionId: number;
  answered?: number;
  viewStyle: string;
  getDataRow?: (data: any) => void;
  wrap?: boolean;
  readOnly?: boolean;
  onSave?: (data: any)=>void;
  
}

interface IState {
  dataSet: Array<Model>;
  pageInfo: any;
  pageSize: number;
  page: number;
  isReady: boolean;
}

export default class QuestionListByuser extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    
    this.state = {
      dataSet: [],
      pageInfo: null,
      pageSize: this.props.viewStyle.includes('onebyone')? 1:20,
      page: 1,
      isReady: false,
    }
  }

  private historyState: any = this.props.history.location.state;

  private getDataSet = (data:any, pageInfo: any) => {
    this.setState({dataSet: data, pageInfo: pageInfo, isReady: true});
    this.props.getDataRow?.({'total':pageInfo.total});
    document.getElementById('Top')?.scrollIntoView(false);
  }
  
  private onChangePage = async(e: React.ChangeEvent<unknown>, value: number) => {
    await this.props.history.replace(this.props.history.location.pathname, {...this.historyState, page: value})
    this.setState({page: value}); 
  };

  public componentDidMount() {
    if (this.historyState?.page) this.setState({page: this.historyState?.page})
  }

  public render(): React.ReactNode {
    const unAnswered = this.props.answered? this.state.pageInfo?.total-this.props.answered : 0;

    return (
      <Grid container id='Top' justifyContent='center' spacing={2} m={0} mt={1}>
        <BaseList history={this.props.history} parentId={this.props.eventsectionId} caption=' ' endPointFilter={(this.props.readOnly? Model.endPointFilter2 : Model.endPointFilter1)+this.props.eventsectionId} model={Model} page={this.state.page} pageSize={this.state.pageSize} getDataSet={this.getDataSet} disableView/>
        {!this.state.isReady && <Spinner/>}
        {this.state.dataSet.map((dataRow: any) => { 
          if (this.historyState?.id>0 && dataRow.id>0) return (
            <Card key={dataRow.id} color='white' caption={dataRow.name} captionAlign='center' wrap={this.props.wrap}>
                <EventquestionViewQuestionoption history={this.props.history} eventId={this.historyState?.id} dataRowQuestion={dataRow} onSave={this.props.onSave} readOnly={this.props.readOnly || dataRow.seq<=0}/>
            </Card>
          )
        })}

        {unAnswered>0 &&
        <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}} bgcolor='primary.dark' textAlign='center' width='100%' borderRadius={20} p={2} pl={3} pr={3} mt={3}>
          Masih tersisa {unAnswered} soal yang belum terjawab, <br/>silahkan selesaikan sebelum lanjut ke tahap berikutnya.
        </Typography>
        }
        <Pagination pageCount={this.state.pageInfo?.pageCount} page={this.state.page} pageInfoCaption='Hal.' prevCaption='Sebelumnya' nextCaption='Berikutnya' disablePageButtons onChange={this.onChangePage}/>
      </Grid>
      );
  }
}
