import React from 'react';
import { History } from 'history';
import { InputFile, InputSelect } from '../../components';
import BaseShare from '../base/base-share.component';
import BaseView from '../base/base-view.component';
import Model from './event-model';  //editable..
import EventtypeModel from '../eventtype/eventtype-model';
import MessageModel from '../message/message-model';
import UserAuthGetToken from '../user/user-auth-gettoken.component';
import PersonalinfoChecklist from '../personalinfo/personalinfo-checklist.component';
import MessageCopy from '../message/message-copy.component';
import ReportPreview from '../report/report-preview.component';
import { Chip, Grid } from '@mui/material';

interface IProps { 
    history: History;
    root?: string;
    id?: number;
    caption?: string;
    buttonCaption?: string;
    reportgenerateCaption?: string;
    participantId?: number;
    reportId?: number;
    dataSource?: any;
    to?: string;
    astemplate?: string;
    save?: boolean;
    onSave?: (data: any) => void;
    disableView?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowEventtype: EventtypeModel;
    dataRowMessage: MessageModel;
    messageType: string;
    attachments: any;
    attachmentUrl: string;
    needToken: boolean;
    saveOrder: number;
    job: string;
}

export default class EventShare extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowEventtype: EventtypeModel.defaultValue(),
            dataRowMessage: MessageModel.defaultValue(),
            messageType: 'email',
            attachments: null,
            attachmentUrl: '',
            needToken: false,
            saveOrder: -1,
            job: '',
        }
        
        this.onChange = this.onChange.bind(this);
    }

    private dataRowParticipant: any = null;
    private defaultValue: any = null;
    private dataSource: any = null;
    private userEmail = '';
    
    private getDataRow = (data:any) => {
        this.setState({dataRow: data});
    }

    private getDataRowEventtype = (data:any) => {
        this.setState({dataRowEventtype: data});
    }

    private getDataRowMessage = (data:any) => {
        this.setState({dataRowMessage: data, messageType: data.type, needToken: data.text.includes('[link]')});
    }

    private getDataRowParticipant = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'participant': data || []} });
    };

    private getFiles = async(data:any) => {
        this.setState({ attachments : data});
        if (this.props.reportId) await this.setState({ job : this.state.needToken? 'token' : 'message'});
    }

    private getDataRowToken = async(data: any) => { 
        if (this.state.dataRow.id>0 && this.state.dataRow.eventtypeType) this.dataSource = {link: window.location.origin+'/user/link/'+ data.code +'?'+this.state.dataRow.eventtypeType+'='+this.state.dataRow.id}
        await this.setState({job : 'message'});
    }
    
    private getDataErrorToken = (data: any) => {  
        if (data) this.onSaveMessage(data);
    }

    private getDataErrorMessage = (data: any) => { 
        if (data) this.onSaveMessage(data);
    }

    private onSaveMessage = async(data: any) => { 
        var saveOrder = this.state.saveOrder+1;
        if (saveOrder >= this.state.dataRow.participant.length) {
            saveOrder = -1;
            this.props.onSave?.(null);
        }
        await this.setState({ saveOrder: saveOrder, job: this.props.reportId? 'report' : this.state.needToken? 'token' : 'message'});
    }

    private onSave = (data: any) => {
        this.setState({saveOrder: 0, job: this.props.reportId? 'report' : this.state.needToken? 'token' : 'message'});
    }
    
    private onChange(fieldName: string, value: string) {
        const nextState = {
            ...this.state, [fieldName]: value
        };
        this.setState(nextState);
    }

    render() {
        this.dataRowParticipant = this.state.dataRow?.participant?.[this.state.saveOrder];
        if (this.dataRowParticipant?.attributes) this.dataRowParticipant = this.dataRowParticipant.attributes;
        if (this.dataRowParticipant){
            this.userEmail = this.dataRowParticipant?.userEmail || this.dataRowParticipant?.user?.data?.attributes.useremail;
            this.defaultValue = {type: this.state.messageType, astemplate: 'no', to: this.state.messageType==='email'? (this.props.to || this.dataRowParticipant?.email) : (this.props.to || this.dataRowParticipant?.phone), 'attachments': this.state.attachments};
            this.dataSource = {...this.dataSource, ...this.props.dataSource, name: this.dataRowParticipant?.name, event: this.state.dataRow.name, customer: this.state.dataRow.customerCaption};
        }
        var messageId = this.props.reportId? this.state.dataRowEventtype.messageresult :this.state.dataRowEventtype.messageinvitation;
        
        return(
            //editable..
            <>
            <BaseShare history={this.props.history} id={this.props.id} caption={this.props.disableView? ' ' : ''} buttonCaption={this.state.saveOrder>=0? 'Proccessing ...' : this.props.buttonCaption || (this.props.disableView || this.state.dataRow.participant?.length===0? ' ' : '')}
                endPointFilter={Model.endPointFilter8(this.props.participantId)} model={Model} getDataRow={this.getDataRow} save={this.props.save && this.state.saveOrder<0} onSave={this.onSave} disableAutoBack disableBackButton={this.props.disableView} disableCard={this.props.disableView}>  
                
                {this.state.dataRow.eventtype &&
                <BaseView id={this.state.dataRow.eventtype} caption='' 
                    model={EventtypeModel} getDataRow={this.getDataRowEventtype}/>
                }

                {messageId?
                <>
                    <BaseView id={messageId} caption='' 
                        model={MessageModel} getDataRow={this.getDataRowMessage}/>
                        
                    {!this.props.disableView &&
                    <>
                    <InputSelect name='messageType' label='Send By' value={this.state.messageType} options={MessageModel.optionType} error={this.state.dataError?.type} onChange={this.onChange} />
                    <InputFile name='attachmentUrl' label='Attachment' value={this.state.attachmentUrl} getFiles={this.getFiles} error={this.state.dataError?.attachments} onChange={this.onChange} />
                    <PersonalinfoChecklist caption=' ' currentId={this.state.dataRow.participant} getDataRow={this.getDataRowParticipant} selectedOnly disableBackButton/>
                    </>
                    }
                </>
                :
                !this.props.disableView && <Grid container justifyContent='center'><Chip label='Invalid message template, please check on event type setting.' size='small' color='default'/></Grid>
                }
            </BaseShare>

            {this.state.dataRow.participant.length>0 && messageId && this.dataRowParticipant && 
            <>
            {this.state.job==='report' && <ReportPreview history={this.props.history} id={this.props.reportId} proccessCaption={this.props.reportgenerateCaption} getFiles={this.getFiles} save disableView/>}
            {this.state.job==='token' && <UserAuthGetToken useremail={this.userEmail} successCaption='Link Created' getDataRow={this.getDataRowToken} getDataError={this.getDataErrorToken} save/>}
            {this.state.job==='message' && <MessageCopy id={messageId} successCaption='Message Sent' defaultValue={this.defaultValue} dataSource={this.dataSource} getDataError={this.getDataErrorMessage} save onSave={this.onSaveMessage} disableView/>}
            </>
            }
        </>
        )
    }
}