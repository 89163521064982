import ApiService from "../../services/api.service";

export default class QuestionModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    eventsection: any;
    eventsectionCaption: string;
    type: string;
    typeCaption: string;
    title: string;
    content: string;
    image: any;
    imageId: number;
    imageUrl: string;    
    seq: number;
    questionoption: any;
    questionoptionLength: number;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.eventsection = dataRow.attributes.eventsection?.data?.id || null;
        this.eventsectionCaption = dataRow.attributes.eventsection?.data?.attributes.name || dataRow.attributes.eventsection?.data?.id;
        this.type = dataRow.attributes.type || 'yesno';
        this.typeCaption = QuestionModel.optionType.filter((d: any) => d.key === this.type)?.[0]?.value || 'Yes or No';
        this.title = dataRow.attributes.title;
        this.content = dataRow.attributes.content;
        this.image = dataRow.attributes.image?.data?.[0] || null;
        this.imageId = this.image?.id || null;
        this.imageUrl = this.image? ApiService.svrUrl+this.image.attributes.url : '';
        this.seq = dataRow.attributes.seq;
        this.questionoption = dataRow.attributes.questionoption?.data || [];
        this.questionoptionLength = this.questionoption?.length || 0;
        
    } 

    public static endPoint: string = 'questions' 
    public static endPointMedia: string = 'api::question.question';
    public static endPointFilter: string = '?populate=*&filters[eventsection]=';
    public static endPointFilter1: string = '?populate=*&filters[seq][$gte]=0&filters[eventsection]=';
    public static endPointFilter2: string = '?populate=*&filters[seq][$lt]=0&filters[eventsection]=';
    public static url: string = '/question';
    public static caption: string = 'Question';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        eventsection: null,
        eventsectionCaption: '',
        status: 'active',
        statusCaption: 'Active',
        type: 'yesno',  
        typeCaption: 'Yes or No',
        title: '',
        content: '',
        image: null,
        imageId: 0,
        imageUrl: '',
        seq: 0,
        questionoption: null,
        questionoptionLength: 0,
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'title', caption: 'Title' },
    ]
    
    public static optionType = [
        { key: 'yesno', value: 'Yes or No' },
        { key: 'optional', value: 'Optional' },
        { key: 'optionalthin', value: 'Optional Thin' },
        { key: 'multiple', value: 'Multiple' },
        { key: 'multiplematch', value: 'Multiple Match' },
        { key: 'freetext', value: 'Free Text' },
        { key: 'letter', value: 'Letter Only' },
        { key: 'letternumber', value: 'Letter & Number' },
        { key: 'number', value: 'Number' },
        { key: 'decimal', value: 'Decimal' },
        { key: 'money', value: 'Money' },
        { key: 'rating', value: 'Rating' },
    ]

}