import React from 'react';
import { History } from 'history';
import { Grid, Typography } from '@mui/material';
import { Button, Rating } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './product-model';  //editable..
import ProductPicklistCard from './product-picklistcard.component';
import AppService from '../../services/app.service';
import TransactiondetailCreateByUser from '../transactiondetail/transactiondetail-create-byuser.component';

interface IProps { 
    history: History;
    root?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class ProductViewByuser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
        document.getElementById('top')?.scrollIntoView(false)
    }

    
    private soldWords = () => {
        switch (this.state.dataRow.producttypeType){
        case 'goods': return 'terjual';
        case 'services': return 'digunakan';
        case 'event': return 'diikuti';
        default: return 'terjual';
        }
    }

    render() {
        return (
            //editable..
            <>
            <Grid id='top'/>
            <BaseView history={this.props.history} caption='Rincian Produk' 
                model={Model} getDataRow={this.getDataRow}> 
                <Grid container alignContent='center' p={2}>
                    <Grid item xs={12} md={5}>
                        <img src={this.state.dataRow.imageUrl} width={350} height='auto' alt='img'/>
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <Typography sx={{typography: {xs: 'h6', md: 'h5'} }} mt={3}><b>{this.state.dataRow.name}</b></Typography> 
                        <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'} }}>Sudah {this.soldWords()} 250+</Typography>                        
                        <Rating name='rating' value={4} max={5}/>    
                        <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'} }} mt={3} mb={3}>{this.state.dataRow.description}</Typography>                        
                        {this.state.dataRow.pricesale!==0 &&
                        <Typography sx={{typography: {xs: 'h5', md: 'h4'} }}><b>{AppService.currencySymbol + ' ' + AppService.strToMoney(this.state.dataRow.pricesale.toString())}</b> {this.state.dataRow.unit? '/'+this.state.dataRow.unit : ''}</Typography>                        
                        }
                        {this.state.dataRow.qty!==0 &&
                        <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'} }} mb={5}>Stok: {this.state.dataRow.qty}</Typography>             
                        }
                        {this.state.dataRow.pricesale!==0 && this.state.dataRow.qty!==0 &&
                        <TransactiondetailCreateByUser history={this.props.history} productId={this.state.dataRow.id}/>
                        }
                    </Grid>
                </Grid>    
                   
                <Grid item mt={5} pt={3} borderTop={1} borderColor='silver'>
                    <Typography sx={{typography: {xs: 'h6', md: 'h5'} }} color='primary.main'>Ulasan</Typography>           
                    <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'} }} mb={8}>Belum ada ulasan.</Typography>
                </Grid>
                <Grid item mt={5} borderTop={1} borderColor='silver'>
                    <ProductPicklistCard caption='Produk Lainnya' pricetype='pricesale' cardCount={6} swipe='horizontal' getDataRow={this.getDataRow}/>  
                </Grid>
            </BaseView>
            </>
        );
    }
}