import * as React from 'react';
import { Button as ButtonMui, Typography } from '@mui/material';

interface Props {
  id?: string;
  label?: string;
  variant?: any;
  color?: any;
  size?: 'small'|'medium'|'large';
  sx?: any;
  startIcon?: any;
  endIcon?: any;
  textCapitalize?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Button: React.FunctionComponent<Props> = (props) => {

  return (
    <ButtonMui 
      id={props.id}
      variant={props.variant}
      color={props.color || 'primary'}
      size={props.size}
      sx={{borderRadius: 30, padding: 1.5, paddingLeft: 3, paddingRight: 3, ...props.sx}}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disabled={props.disabled}
      onClick={props.onClick}
    >
      <Typography sx={{typography: {xs: 'caption', md: 'body2'}, textTransform: props.textCapitalize? 'capitalize' : ''}}>{props.label}</Typography>
    </ButtonMui>
  );
};
