import * as React from 'react';
import { History } from 'history';
import Model from './coabalance-model';
import BaseList from '../base/base-list.component';

interface IProps { 
  history: History;
  root?: string; 
  coaId: number;
  actions?: any;
  disableBackButton?: boolean;
  disableCard?: boolean;
}

interface IState {
}

export default class CoabalanceList extends React.Component<IProps, IState> {
  public render(): React.ReactNode {
    return (
      <BaseList history={this.props.history}  parentId={this.props.coaId} endPointFilter={Model.endPointFilter+(this.props.coaId)} url={(this.props.root||'')+Model.url} model={Model} actions={this.props.actions} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}/>
    );
  }
}
