import * as React from 'react';
import { History } from 'history';
import { Grid, Typography } from '@mui/material';
import { Icon } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  history?: History;
  label?: string;
  color?: string;
  thin?: boolean;
  disableBackButton?: boolean;
  children?: React.ReactNode;
}

export const FormHeader: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const margin = props.label?.trim()==='' || isMobile? 1 : props.thin? 0 : 3;

  const onClick = () => {
    props.history?.goBack();
  }

  return (
    <Grid id={props.id} container mt={margin} mb={margin} >
      <Grid item sx={{cursor: 'pointer', pointerEvents: (props.disableBackButton? 'none' : 'auto')}} onClick={onClick}>
         <Typography component='div' sx={{typography: {xs: 'h6', md: props.thin? 'h6' : 'h5'}, lineHeight: 1.2, color: props.color || 'primary.main'}}>
            <Grid container alignItems='center'>
              {!props.disableBackButton && <Icon name='west' marginRight/>}
              <Grid item xs md ml={props.disableBackButton? 1 : 2}>{props.label}</Grid>
            </Grid>
          </Typography>
      </Grid>
      <Grid item xs md textAlign='right'>
        {props.children}
      </Grid>
    </Grid>
  );
};
