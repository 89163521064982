import React from 'react';
import { History } from 'history';
import BaseEdit from '../base/base-edit.component'; 
import Model from './eventquestion-model';  //editable..
import QuestionModel from '../question/question-model';

interface IProps { 
    history: History;
    caption?: string;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowQuestion: QuestionModel;
}

export default class EventquestionEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowQuestion: QuestionModel.defaultValue()
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} caption={this.props.caption}
                endPointFilter={Model.endPointFilter} model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} >              
            </BaseEdit>
        );
    }
}