import React from 'react';
import { History } from 'history';
import { Input, InputEmail, InputPassword } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserAuthSignup extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }
    
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        var username = this.state.dataRow.username;
        var email = this.state.dataRow.email;
        if (fieldName === 'useremail') {
            email = Model.getEmailApp(value);
            username = email;
        }

        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value, email: email, username: username,
            }
        };
        this.setState(nextState);
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={'Register ' +Model.caption} buttonCaption='Create Account' successCaption='Registered'
                endPoint={Model.endPointAuth+'/local/register'} model={Model} dataRow={this.state.dataRow} getDataError={this.getDataError} buttonOnBottom>              
                <Input name='name' label='User Name' value={this.state.dataRow.name} error={this.state.dataError?.name || this.state.dataError?.[0]} onChange={this.onChange} />
                <InputEmail name='useremail' label='Email' value={this.state.dataRow.useremail} error={this.state.dataError?.useremail} onChange={this.onChange} />
                <InputPassword name='password' label='Password' value={this.state.dataRow.password} error={this.state.dataError?.password} onChange={this.onChange} />
                    
            </BaseCreate>
        );
    }     
     
}