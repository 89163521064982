import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import MenugroupList from './menugroup-list.component';
import MenugroupCreate from './menugroup-create.component';
import MenugroupView from './menugroup-view.component';
import MenugroupEdit from './menugroup-edit.component';
import MenugroupCopy from './menugroup-copy.component';
import MenugroupDelete from './menugroup-delete.component';

interface Props {
  root?: string;
}

export const MenugroupRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/menugroup/create'} component={MenugroupCreate} />
      <Route path={(props.root||'')+'/menugroup/view'} component={MenugroupView} />
      <Route path={(props.root||'')+'/menugroup/edit'} component={MenugroupEdit} />
      <Route path={(props.root||'')+'/menugroup/copy'} component={MenugroupCopy} />
      <Route path={(props.root||'')+'/menugroup/delete'} component={MenugroupDelete} />
      <Route path={(props.root||'')+'/menugroup'} render={(params)=> <MenugroupList {...params} root={props.root} />} /> 
    </Switch>
  );
};
