import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputMoney, InputMultiline, InputSelect, Layout, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './transaction-model';  //editable..
import TransactiontypeModel from '../transactiontype/transactiontype-model';
import TransactiondetailList from '../transactiondetail/transactiondetail-list.component';
import TransactionamountList from '../transactionamount/transactionamount-list.component';
import TransactionpaymentList from '../transactionpayment/transactionpayment-list.component';
import JournalList from '../journal/journal-list.component';
import ReportList from '../report/report-list.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    root?: string;
    caption?: string;
    disableFields?: any;
}

interface IState {
    dataRow: Model;
    dataRowTransactiontype: TransactiontypeModel;
    dataError: any;
}

export default class TransactionView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataRowTransactiontype: TransactiontypeModel.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowTransactiontype = (data:any) => {
        this.setState({dataRowTransactiontype:  data});
    }
    
    render() {
        return (
            //editable..
            <BaseView history={this.props.history} caption={this.props.caption} 
                model={Model} getDataRow={this.getDataRow} disableCard>              

                {this.state.dataRow.transactiontype>0 &&
                <BaseView id={this.state.dataRow.transactiontype} caption=''
                    model={TransactiontypeModel} getDataRow={this.getDataRowTransactiontype}/>
                }

                <Layout history={this.props.history} content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} readOnly/>
                        {!this.props.disableFields?.includes('owner') && AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        {!this.props.disableFields?.includes('transactionType') &&
                        <Picklist name='transactiontype' label='Transaction Type' value={this.state.dataRow.transactiontype} caption={this.state.dataRow.transactiontypeCaption} readOnly/>
                        }
                        <InputDate name='transactiondate' label='Date' value={this.state.dataRow.transactiondate} readOnly/>
                        {!this.props.disableFields?.includes('customer') &&
                        <Picklist name='customer' label='Customer' value={this.state.dataRow.customer} caption={this.state.dataRow.customerCaption} readOnly/>
                        }  
                        {!this.props.disableFields?.includes('vendor') &&
                        <Picklist name='vendor' label='Vendor' value={this.state.dataRow.vendor} caption={this.state.dataRow.vendorCaption} readOnly/>
                        }
                        {!this.props.disableFields?.includes('employee') &&
                        <Picklist name='employee' label='Employee' value={this.state.dataRow.employee} caption={this.state.dataRow.employeeCaption} readOnly/>
                        }
                        <Picklist name='division' label='Division' value={this.state.dataRow.division} caption={this.state.dataRow.divisionCaption} readOnly/>
                        <Picklist name='schedule' label='Schedule' value={this.state.dataRow.schedule} caption={this.state.dataRow.scheduleCaption} readOnly/>
                    </>
                    },

                    {caption: 'Orders', hide: this.props.disableFields?.includes('transactiondetail','transactionamount'), child: 
                    this.state.dataRow.id>0 &&
                    <>
                        {!this.props.disableFields?.includes('transactiondetail') &&
                            <>
                            <TransactiondetailList history={this.props.history} root={this.props.root} transactionId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                            <InputMoney name='totaldetail' label='Sub Total' value={this.state.dataRow.totaldetail} readOnly/>
                            </>
                        }
                        {!this.props.disableFields?.includes('transactionamount') &&
                            <TransactionamountList history={this.props.history} root={this.props.root} transactionId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/> 
                        }
                        {!this.props.disableFields?.includes('transactiondetail','transactionamount') &&
                            <InputMoney name='total' label='Total' value={this.state.dataRow.total} size='medium' readOnly/>
                        }
                    </>
                    },

                    {caption:'Payments', hide: this.props.disableFields?.includes('transactionpayment'), child:
                    this.state.dataRow.id>0 &&
                        <>
                        <InputSelect name='statuspayment' label='' value={this.state.dataRow.statuspayment} options={Model.optionStatuspayment} readOnly />
                        <TransactionpaymentList history={this.props.history} root={this.props.root} transactionId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/> 
                        <InputMoney name='total' label='Total Payment' value={this.state.dataRow.totalpayment} readOnly/>
                        {this.state.dataRow.statuspayment !== 'paid' &&
                            <InputMoney name='remainingpayment' label={'Remaining '+this.state.dataRow.statuspaymentCaption} value={this.state.dataRow.remainingpayment} readOnly/>
                        }
                        </>
                    },
 
                    {caption:'Journals', hide: this.props.disableFields?.includes('journal'), child:
                    this.state.dataRow.id>0 &&
                        <JournalList history={this.props.history} root={this.props.root} transactionId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/> 
                    },
  
                    {caption: 'Invoices', hide: this.props.disableFields?.includes('invoice'), child:
                    this.state.dataRow.id>0 && this.state.dataRowTransactiontype?.reportgroup>0 && 
                        <ReportList history={this.props.history} root={this.props.root} caption='Invoice' previewId={this.state.dataRow.id} reportgroupId={this.state.dataRowTransactiontype.reportgroup} actions={['preview']} disableBackButton disableCard/>
                    },

                   ]}
                    
                />

                </BaseView>
            );
            
    }
}