import * as React from 'react';
import { History } from 'history';
import { Menu, List, ListItem, ListItemButton, ListItemIcon, Grid } from '@mui/material';
import { Icon, SwipeDrawer } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  history?: History;
  anchorEl: HTMLElement | null,  
  action?: {
    caption: string, 
    icon?: string,
    color?: string,
    url?: string,
    state?: any,
    readOnly?: boolean,
  }[];
  onClose: () => void;
}

export const ActionButton: React.FunctionComponent<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(props.anchorEl);
  
  const onClose = () => {    
    setAnchorEl(null);
    props.onClose();
  };

  const onClick = (action: any) => {
    props.history?.push(action.url, action.state);
  }

  const actions = () => {
    return (
      <List>
      {props.action?.map(action => {
        if (action.url)
        return(
          <Grid key={action.caption} item sx={{cursor: 'pointer', pointerEvents: (action.readOnly? 'none' : 'auto')}} onClick={()=>onClick(action)}>
            <ListItem disablePadding> <ListItemButton><ListItemIcon>{action.icon && <Icon name={action.icon} size='small' color={action.color}/>}</ListItemIcon> {action.caption} </ListItemButton></ListItem>
          </Grid>
        );
      })}
      </List>
    );
  }

  React.useEffect(()=>{
    setAnchorEl(props.anchorEl)
  }, [props.anchorEl])

  return (
    <>
    {AppService.isMobile()?
      <SwipeDrawer variant='bottom' open={anchorEl? true : false}>
        {actions()}   
      </SwipeDrawer>
      
    : //Web view
      <Menu anchorEl={anchorEl} open={anchorEl? true : false} onClose={onClose}>
        {actions()}
      </Menu>
    }  
    </>
  )
};



