import React from 'react';
import { History } from 'history';
import { InputDate, InputMoney, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './coabalance-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class CoabalanceDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <InputDate name='balancedate' label='Date' value={this.state.dataRow.balancedate} readOnly/>
                <InputMoney name='value' label='Value' value={this.state.dataRow.value} readOnly/>
                <Picklist name='coa' label='COA' value={this.state.dataRow.coa} readOnly caption={this.state.dataRow.coaCaption} /> 
            </BaseDelete>
        );
    }
}