import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import JournalgroupdetailList from './journalgroupdetail-list.component';
import JournalgroupdetailCreate from './journalgroupdetail-create.component';
import JournalgroupdetailView from './journalgroupdetail-view.component';
import JournalgroupdetailEdit from './journalgroupdetail-edit.component';
import JournalgroupdetailCopy from './journalgroupdetail-copy.component';
import JournalgroupdetailDelete from './journalgroupdetail-delete.component';

interface Props {
  root?: string;
}

export const JournalgroupdetailRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/journalgroupdetail/create'} component={JournalgroupdetailCreate} />
      <Route path={(props.root||'')+'/journalgroupdetail/view'} component={JournalgroupdetailView} />
      <Route path={(props.root||'')+'/journalgroupdetail/edit'} component={JournalgroupdetailEdit} />
      <Route path={(props.root||'')+'/journalgroupdetail/copy'} component={JournalgroupdetailCopy} />
      <Route path={(props.root||'')+'/journalgroupdetail/delete'} component={JournalgroupdetailDelete} />
      <Route path={(props.root||'')+'/journalgroupdetail'} component={JournalgroupdetailList} />
    </Switch>
  );
};
