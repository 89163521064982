import React from 'react';
import { History } from 'history';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    match: any;
}

interface IState {
    dataRow: Model;
    dataError: any;
    save: boolean;
    link: string;
    id: number;
}

export default class UserAuthCheckToken extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            save: false,
            link: '',
            id: 0,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataError = (data: any) => {
        if (data) {
            Model.removeUser();
            this.setState({dataError: data, save: false});
        }
    }
    
    private onSave = (data: any) => {
        this.setState({save: false});
        
        if (data.user) {
            Model.setUser(data.user?.id, data.user?.name, this.state.dataRow.code);
            if (this.state.link && this.state.id) setTimeout(() => this.props.history.replace('/'+this.state.link, {id: this.state.id}), 1);
        }
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount() {
        const code = this.props.match.params.code;
        const historySearch= this.props.history.location.search.split('=');
        const dataRowTemp: any = Model.defaultValueCheckToken;

        Model.setUser('','', code);
        this.setState({ dataRow: { ...dataRowTemp, 'code': code}, link: historySearch[0].substring(1), id: +historySearch[1], save: true});
    }
    
    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption='Invalid Link!' buttonCaption=' ' successCaption='Verifying..' captionColor='primary.light'
                endPoint={Model.endPointAuth+'/check-token'} model={Model} dataRow={this.state.dataRow} validations={Model.validationResetPassword} getDataError={this.getDataError} onSave={this.onSave} save={this.state.save} buttonOnBottom disableAutoBack/>
        );
    }     
     
}