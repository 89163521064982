import React from 'react';
import * as toastr from 'toastr';
import {Form } from '../../components';
import BaseUpload from './base-upload.component';
import ApiService from '../../services/api.service';
import BaseValidate from './base-validate.component';

interface IProps { 
    history?: any;
    id?: number;
    caption?: string;
    captionColor?: string;
    buttonCaption?: string;
    successCaption?: string;
    endPoint?: string;
    endPointFilter?: string;
    url?: string;
    model: any;
    dataRow: any;
    dataRowMedia?: any;
    validations?: any;
    getDataRow?: (data: any) => void;
    getDataError?: (data: any) => void;
    onSaveBefore?: () => void;
    onSave?: (data: any) => void;
    save?: boolean;
    buttonOnBottom?: boolean;
    disableAutoBack?: boolean;
    disableBackButton?: boolean;
    disableCard? : boolean;
    children?: React.ReactNode;
}

interface IState {
    dataRow: Array<any>;  
}

export default class BaseCopy extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
    }

    private historyState: any = this.props.history?.location.state;
    private id = this.props.id?.toString() || this.historyState?.id || '';
    private isLoading: boolean = false;
    private isSaving: boolean = false;

    private onSave = async() => {
        this.props.dataRow.id = 0;
        await this.props.onSaveBefore?.();
        var dataError = BaseValidate.validate(this.props.dataRow, this.props.validations || this.props.model.validations);
        this.props.getDataError?.(dataError);
        
        if (!dataError) 
        ApiService.create<typeof this.props.model>(this.props.endPoint || this.props.model.endPoint, this.props.model.endPointAuth? this.props.dataRow : {data:this.props.dataRow}).then(
            (rp) =>  {
                if (rp.Status) {
                    const rpData = this.props.model.endPointAuth? rp.Data : rp.Data.data;
                    if (this.props.dataRowMedia && rpData) BaseUpload.uploadImage(rpData.id, this.props.dataRowMedia);
                    this.props.onSave?.(rpData);
                    (!this.props.successCaption || this.props.successCaption!==' ') && toastr.success(this.props.successCaption || 'Data duplicated.');

                    if (this.props.disableAutoBack) {
                        if (rpData) 
                            this.props.history?.replace(
                                this.props.url || this.props.history?.location.pathname.replace('create','edit'),
                                {...this.historyState, id: rpData.id}
                            )
                    }
                    else {this.props.history?.goBack();}
                }
                else { this.props.getDataError && rp.Errors? this.props.getDataError(rp.Errors) : toastr.error(rp.Messages); }
                this.isSaving = false;
            }
        );
    }
 
    public componentDidMount() {
        if (this.isLoading) return;
        this.isLoading = true;
        
        ApiService.get<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*&filters[id]='), this.id).then(
            (rp) => {
                if (rp.Status) { if (rp.Data.length>0) var data = new this.props.model(rp.Data[0]); this.setState({dataRow: data}); this.props.getDataRow?.(data);} 
                else { toastr.error(rp.Messages); }
                this.isLoading = false;
            }
        );
    }

    public componentDidUpdate() {
        if (this.props.save) {
            if (this.isSaving) return;
            this.isSaving = true;
            this.onSave();
        }
    }

    render() {
        return(
            <Form history={this.props.history} caption={this.props.caption || 'Duplicate '+ this.props.model.caption} captionColor={this.props.captionColor} buttonCaption={this.props.buttonCaption || 'save'} buttonOnBottom={this.props.buttonOnBottom} onSubmit={this.onSave} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
                {this.props.children}
            </Form>
        );
    }
}