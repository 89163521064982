import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EventquestionList from './eventquestion-list.component';
import EventquestionCreate from './eventquestion-create.component';
import EventquestionView from './eventquestion-view.component';
import EventquestionEdit from './eventquestion-edit.component';
import EventquestionCopy from './eventquestion-copy.component';
import EventquestionDelete from './eventquestion-delete.component';

interface Props {
  root?: string;
}

export const EventquestionRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/eventquestion/create'} component={EventquestionCreate} />
      <Route path={(props.root||'')+'/eventquestion/view'} component={EventquestionView} />
      <Route path={(props.root||'')+'/eventquestion/edit'} component={EventquestionEdit} />
      <Route path={(props.root||'')+'/eventquestion/copy'} component={EventquestionCopy} />
      <Route path={(props.root||'')+'/eventquestion/delete'} component={EventquestionDelete} />
      <Route path={(props.root||'')+'/eventquestion'} component={EventquestionList} />
    </Switch>
  );
};
