import React from 'react';
import { History } from 'history';
import { InputMoney, InputMultiline, InputNumber, Picklist } from '../../components';
import BaseView from '../base/base-view.component'; 
import Model from './transactiondetail-model';  //editable..
import ProductList from '../product/product-list.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactiondetailView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} 
                endPointFilter={Model.endPointFilter2} model={Model} getDataRow={this.getDataRow}>              
                
                <Picklist name='product' label='Product' value={this.state.dataRow.product} caption={this.state.dataRow.productCaption} readOnly/>
                <InputMoney name='price' label='Price' value={this.state.dataRow.price} readOnly/>
                <InputNumber name='number' label='Qty' value={this.state.dataRow.qty} readOnly/>
                <InputMoney name='value' label='Total' value={this.state.dataRow.value} readOnly/>
                <InputMultiline name='note' label='Note' value={this.state.dataRow.note} readOnly/>
                {this.state.dataRow.productLength>0 &&
                    <ProductList history={this.props.history} caption='Sub Product' parentId={this.state.dataRow.product} actions={[]} disableBackButton/>
                }
              </BaseView>
        );
    }
}