import * as React from 'react';
import IconMui from '@mui/material/Icon';

interface Props {
  id?: string;
  name: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  color?: string;
  marginRight?: boolean;
  rotate?: number;
}

export const Icon: React.FunctionComponent<Props> = (props) => {
  
  const fontSize = () =>{
    switch (props.size) {
      case 'extrasmall': return 16;
      case 'small': return 24;
      case 'medium': return 36;
      case 'large': return 48;
      case 'extralarge': return 56;
      default : return 36;
    }
  }
  
  return (
    <IconMui id={props.id || props.name} sx={{ fontSize: fontSize(), color: props.color, verticalAlign: 'middle', marginRight: props.marginRight? 1 : 0, transform: 'rotate('+props.rotate+'deg)',}}>{props.name.toLowerCase()}</IconMui>
  )
  
}