import React from 'react';
import { History } from 'history';
import BaseView from '../base/base-view.component'; 
import Model from './eventquestion-model';  //editable..
import QuestionModel from '../question/question-model';
import EventquestionViewQuestionoption from './eventquestion-view-questionoption.component';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowQuestion: QuestionModel;
}

export default class EventquestionView extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowQuestion: QuestionModel.defaultValue(),
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowQuestion = (data: any) => {
        this.setState({dataRowQuestion: data });
    }

    render() {
        return (
            //editable..
            <BaseView history={this.props.history} 
                endPointFilter={Model.endPointFilter} model={Model} getDataRow={this.getDataRow}>  

                {this.state.dataRow.question>0 &&
                <BaseView id={this.state.dataRow.question} caption=' ' 
                    model={QuestionModel} getDataRow={this.getDataRowQuestion}/>
                }
                {this.state.dataRowQuestion.id>0 &&
                <EventquestionViewQuestionoption history={this.props.history} eventId={this.state.dataRow.event} dataRowQuestion={this.state.dataRowQuestion} readOnly/>
                }
                
            </BaseView>
        );
    }
}