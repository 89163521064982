import * as React from 'react';
import Model from './product-model';
import BasePicklist from '../base/base-picklist.component';

interface IProps { 
  currentId?: number;  
  pricetype?: string;
  getDataRow: (data: any) => void;
}

interface IState {
}

export default class ProductPicklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private header: any = Model.columnPicklist.filter((d: any) => d.name !== (this.props.pricetype === 'pricebase'? 'pricesale' : 'pricebase'));

  public render(): React.ReactNode {
    return (
      <BasePicklist endPointFilter={Model.endPointFilter()} model={Model} currentId={this.props.currentId} getDataRow={this.props.getDataRow} header={this.header} disableBackButton/>
    );
  }
}
