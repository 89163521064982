import * as React from 'react';
import { FormHeader, Table } from '../../components';
import ApiService from '../../services/api.service';
import AppService from '../../services/app.service';

interface IProps {
  history?: any;
  caption?: string;
  captionColor?: string;
  captionThin?: boolean;
  endPointFilter?: string;
  model: any;
  header?: any;
  currentId?: number;
  getDataRow?: (data: any) => void;
  page?: number;
  pageSize?: number;
  searchField?: string;
  onChangePage?: (e: React.ChangeEvent<unknown>, value: number) => void;
  autoSelect?: boolean;
  readOnly?: boolean;
  disableBackButton?: boolean;
  disableColumnHeader?: boolean;
}

interface IState {
  dataSet: Array<any>;
  header: Array<any>;
  endPointFilter: string;
  page: number;
  pageSize: number;
  pageCount: number;
  searchText: string;
  isSearch: boolean;
  isReady: boolean;
  isError: boolean;
}

export default class BasePicklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      dataSet: this.props.model,
      header: this.props.header || this.props.model.columnPicklist,
      endPointFilter: this.props.endPointFilter || '',
      page: this.props.page || 1,
      pageSize: this.props.pageSize || 10,
      pageCount: 0,
      searchText: '',
      isSearch: this.props.model.columnPicklist?.findIndex((d: { name: string; }) => d.name === (this.props.searchField || 'name'))>=0,
      isReady: false,
      isError: false,
    };
    
    this.onChangePage = this.onChangePage.bind(this);
    this.onChangeSearch = this.onChangeSearch.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
    this.onEndTypingSearch = this.onEndTypingSearch.bind(this);
  }
  
  private historyState: any = this.props.history?.location.state;
  private isLoading: boolean = false;
  
  private loadData (page: number, searchText?: string) {
    if (this.isLoading) return;
    this.isLoading = true;
    this.setState({ isReady: false});

    const paginationPage = '&pagination[page]='+page;
    const paginationPageSize = '&pagination[pageSize]='+this.state.pageSize;
    const search = searchText? '&filters['+(this.props.searchField || 'name')+'][$contains]='+searchText : paginationPage;
    ApiService.getAll<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*')+search+paginationPageSize).then((rp) => {
      if (rp.Status) {
        const pageCount = Math.ceil(rp.Meta?.pagination?.total/this.state.pageSize);
        const data = rp.Data; 
        const dataSet = new Array<typeof this.props.model>();
        (data || []).forEach((dataRow: any) => {dataSet.push(new this.props.model(dataRow));});
        this.setState({ dataSet: dataSet, pageCount:pageCount, isReady: true });
        if ((this.state.pageSize===1) || this.props.autoSelect) this.props.getDataRow?.(dataSet[0])
      } 
      else { this.setState({ isReady: true, isError: true }); }
      this.isLoading = false;
    });
  }

  private onChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    this.props.history?.replace(this.props.history.location.pathname, {...this.props.history.location.state, page : value})
    this.setState({page: value});
    this.loadData(value, this.state.searchText);
    this.props.onChangePage?.(e,value)
  };
  
  private onChangeSearch(fieldName: string, value: string) { 
    this.setState({searchText: value});
  }

  private onClickSearch() { 
    this.loadData(this.state.page, this.state.searchText);
  }
  
  private onEndTypingSearch(fieldName?: string, value?: string) {
      this.setState({searchText: value || ''});
      this.loadData(this.state.page, value);
  }

  private table(){
    return  <Table header={this.state.header} dataSet={this.state.dataSet} currentId={this.props.currentId} getDataRow={this.props.getDataRow} page={this.state.page} pageCount={this.state.pageCount} searchText={this.state.searchText} isSearch={this.state.isSearch} isLoading={this.isLoading} isReady={this.state.isReady} isError={this.state.isError} onChangePage={this.onChangePage} onChangeSearch={this.onChangeSearch} onClickSearch={this.onClickSearch} onEndTypingSearch={this.onEndTypingSearch} readOnly={this.props.readOnly} />
  }

  public componentDidMount() {
    var page = this.historyState?.page || this.state.page;
    this.loadData(page);
    this.setState({page: page})
  }

  public componentDidUpdate() {
    if (!this.isLoading)
      if (this.props.page && (this.props.page !== this.state.page)) {
        this.setState({page: this.props.page})
        this.loadData(this.props.page, this.state.searchText);
      }
      else if (this.props.endPointFilter && (this.props.endPointFilter !== this.state.endPointFilter)) {
        this.setState({endPointFilter: this.props.endPointFilter})
        this.loadData(1);
      }
  }

  public render(): React.ReactNode {      
    var caption = this.props.caption || 'Select'+ (this.props.readOnly? 'ed ': ' ')+  AppService.strPluralize(this.props.model.caption);
   
    return (
      <>
        <FormHeader history={this.props.history} label={caption} color={this.props.captionColor} thin={this.props.captionThin} disableBackButton={this.props.disableBackButton}/> 
        {this.table()}
      </>
    );
  }
}
