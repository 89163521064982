import * as React from 'react';
import * as SerialPortJs from './serialportjs';

interface Props {
  open?: boolean;
  write?: string;
  getData?: (data: any) => void;
  getStatus?: (data: any) => void;
}

export const SerialPort: React.FunctionComponent<Props> = (props) => {

  const getStatus = () =>{
    const status = ['Serial reader is not support', 'No device detected', 'Device disconected', 'Device connected']
    return SerialPortJs.currentPortStatus === 4? SerialPortJs.currentPortDevInfo : status[SerialPortJs.currentPortStatus];
  }

  const portOpen = async() => {
    await SerialPortJs.connect();
    props.getStatus?.(getStatus());
    await SerialPortJs.read((value: string) => {if (value.trim() !=='') props.getData?.(value)});
  }
  
  const portListen = async() => {
    await SerialPortJs.listen(()=> props.getStatus?.(getStatus()) );
  }

  const portWrite = async(data: string) => {
    await SerialPortJs.write(data);
  }

  React.useEffect(() => {

    if ([0,1].includes(SerialPortJs.currentPortStatus)) props.getData?.('');
    if (props.open && [1,2,3].includes(SerialPortJs.currentPortStatus)) portOpen();
    if (props.write && [4].includes(SerialPortJs.currentPortStatus)) portWrite(props.write);
    portListen();
    props.getStatus?.(getStatus());
    
    
    
  }, [props.open, props.write, props.getStatus, SerialPortJs.currentPortStatus])
 
  return (
    <></>
  )
  
}