import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EventtypeList from './eventtype-list.component';
import EventtypeCreate from './eventtype-create.component';
import EventtypeView from './eventtype-view.component';
import EventtypeEdit from './eventtype-edit.component';
import EventtypeCopy from './eventtype-copy.component';
import EventtypeDelete from './eventtype-delete.component';

interface Props {
  root?: string;
}

export const EventtypeRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/eventtype/create'} component={EventtypeCreate} />
      <Route path={(props.root||'')+'/eventtype/view'} component={EventtypeView} />
      <Route path={(props.root||'')+'/eventtype/edit'} component={EventtypeEdit} />
      <Route path={(props.root||'')+'/eventtype/copy'} component={EventtypeCopy} />
      <Route path={(props.root||'')+'/eventtype/delete'} component={EventtypeDelete} />
      <Route path={(props.root||'')+'/eventtype'} render={(params)=> <EventtypeList {...params} root={props.root} />} /> 
    </Switch>
  );
};
