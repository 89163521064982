import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import PersonalinfotypeList from './personalinfotype-list.component';
import PersonalinfotypeCreate from './personalinfotype-create.component';
import PersonalinfotypeView from './personalinfotype-view.component';
import PersonalinfotypeEdit from './personalinfotype-edit.component';
import PersonalinfotypeCopy from './personalinfotype-copy.component';
import PersonalinfotypeDelete from './personalinfotype-delete.component';

interface Props {
  root?: string;
}

export const PersonalinfotypeRouteEmployee: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>     
      <Route path={`${props.root||''}/employeetype/create`} render={(params)=> <PersonalinfotypeCreate {...params} caption='Create Employee Type' personalinfotypeType='employee' />} />    
      <Route path={`${props.root||''}/employeetype/view`} render={(params)=> <PersonalinfotypeView {...params} caption='View Employee Type' personalinfotypeType='employee' />} />        
      <Route path={`${props.root||''}/employeetype/edit`} render={(params)=> <PersonalinfotypeEdit {...params} caption='Edit Employee Type' personalinfotypeType='employee' />} />        
      <Route path={`${props.root||''}/employeetype/copy`} render={(params)=> <PersonalinfotypeCopy {...params} caption='Duplicate Employee Type' personalinfotypeType='employee' />} />     
      <Route path={`${props.root||''}/employeetype/delete`} render={(params)=> <PersonalinfotypeDelete {...params} caption='Delete Employee Type' personalinfotypeType='employee' />} />        
      <Route path={(props.root||'')+'/employeetype'} render={(params)=> <PersonalinfotypeList {...params} root={props.root} caption='Employee Type' personalinfotypeType='employee' />} /> 
    </Switch>
  );
};
