import * as React from 'react';
import { Grid } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  vertical?: boolean;
  onChange?: (e: React.ChangeEvent<unknown>, value: string) => void;
  children?: React.ReactNode;
}

export const Swipe: React.FunctionComponent<Props> = (props) => {
  const isMobile =AppService.isMobile();
  const [touchStartX, setTouchStartX] = React.useState(0);
  const [touchEndX, setTouchEndX] = React.useState(0);
  const [touchStartY, setTouchStartY] = React.useState(0);
  const [touchEndY, setTouchEndY] = React.useState(0);
  const minSwipeDistance = isMobile? 1 : 20;

  const onTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.targetTouches[0].clientX);
    setTouchEndX(0);
    setTouchStartY(e.targetTouches[0].clientY);
    setTouchEndY(0);
    touch(e, 'start');
  }
  
  const onTouchMove = (e: React.TouchEvent) => {
    setTouchEndX(e.targetTouches[0].clientX);
    setTouchEndY(e.targetTouches[0].clientY);
    touch(e, 'move');
  }
  
  const onTouchEnd = (e: React.TouchEvent) => {
    touch(e, 'end');
  }

  const touch = (e: React.TouchEvent, type: string) => {
    const xDistance = touchStartX - touchEndX;
    const yDistance = touchStartY - touchEndY;

    if ((!props.vertical) && Math.abs(yDistance) < Math.abs(xDistance)) {
      if (xDistance > minSwipeDistance) props.onChange?.(e, type+'left');
      else if (xDistance < -minSwipeDistance) props.onChange?.(e, type+'right');
    }
    else if (props.vertical && Math.abs(xDistance) < Math.abs(yDistance)) {
      if (yDistance > minSwipeDistance) props.onChange?.(e, type+'up');
      else if (yDistance < -minSwipeDistance) props.onChange?.(e, type+'down');
    } 
  }

  return (
    <Grid item xs={12} md={12} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} >
      {props.children}
    </Grid>
  );
};
