import * as React from 'react';
import { History } from 'history';
import Model from './menu-model';
import BasePicklistCard from '../base/base-picklistcard.component';
import AppService from '../../services/app.service';

interface IProps {
  history: History;
  root?: string;
  caption?: string;
  captionThin?: boolean;
  currentId?: number;
  menugroupId?: number;
  cardCount?: number;
  cardIconOnly?: boolean;
  autoLoad?: boolean;
  getDataRow?: (data: any) => void;
}

interface IState {
  dataRow: Model;
  currentId: number;
  historyLengthRoot: number;
  menuPage: number;
}

export default class MenuPicklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = { 
      dataRow: Model.defaultValue(),
      currentId : this.props.currentId || 0,
      historyLengthRoot: props.history.length,
      menuPage:  1,
    }
    
    this.onChangePage = this.onChangePage.bind(this);
  }

  private isMobile: boolean = AppService.isMobile();
  
  private getDataRow = (data: any) => {
    if (!this.props.root || this.props.history.location.pathname===this.props.root)
      this.props.history.push((this.props.root||'')+data.path.split(';')[0])
    else {

      //Back to Root Page First & Clear The History, Before Go to New Menu Url
      var historyState: any = this.props.history.location.state;
      var go = historyState.historyLength-this.state.historyLengthRoot-1;
      if (go>0) this.props.history.go(-go);
      setTimeout(() => this.props.history.replace((this.props.root||'')+data.path.split(';')[0], {historyLength: this.state.historyLengthRoot+1}), 1);
    }
    
    this.setState({dataRow: data, currentId: data.id})
    this.props.getDataRow?.(data);
  };

  private onChangePage = (e: React.ChangeEvent<unknown>, value: number) => {
    var historyState: any = this.props.history.location.state;
    this.props.history.replace(this.props.history.location.pathname, {...historyState, menuPage: value});
    this.setState({menuPage: value})
  }

  public componentDidMount() {
    if (this.props.root){

      //Back to Previous Page First, When User Hit New Url
      var historyState: any = this.props.history.location.state;
      if (this.props.history.location.pathname!==this.props.root && !historyState) this.props.history.goBack();

      //Update currentId, menuPage & historyLength Base on Current History States
      if (historyState?.menuId) this.setState({currentId: historyState?.menuId})
      if (historyState?.menuPage) this.setState({menuPage: historyState?.menuPage})
      if (historyState?.historyLengthRoot) this.setState({historyLengthRoot: historyState?.historyLengthRoot})
    }
  }

  public componentDidUpdate() {
    if (this.props.root && this.props.history.location.pathname!==this.props.root) {

      //Tagging currentId for Active menuId & historyLength for Back to Root Url in New Forwards Url
      var historyState: any = this.props.history.location.state;
      if (!historyState?.menuId && this.state.currentId>0) this.props.history.replace(this.props.history.location.pathname, {...historyState, menuId: this.state.currentId});
      if (!historyState?.menuPage && this.state.menuPage>0) this.props.history.replace(this.props.history.location.pathname, {...historyState, menuPage: this.state.menuPage});
      if (!historyState?.historyLengthRoot && this.state.historyLengthRoot) this.props.history.replace(this.props.history.location.pathname, {...historyState, historyLengthRoot: this.state.historyLengthRoot});
      if (!historyState?.historyLength && this.props.history.length) this.props.history.replace(this.props.history.location.pathname, {...historyState, historyLength: this.props.history.length});
    }
  }
  
  public render(): React.ReactNode {
    var currentId = this.props.history.location.pathname===this.props.root? -1 : this.state.currentId;
    
    return (
      <BasePicklistCard history={this.props.history} caption={this.props.caption} captionThin={this.props.captionThin} endPointFilter={Model.endPointFilter2()+(this.props.menugroupId && this.props.menugroupId>1? '&filters[menugroup][id]='+this.props.menugroupId : '')} model={Model} currentId={ currentId} getDataRow={this.getDataRow} page={this.state.menuPage} pageSize={this.isMobile? 6: 10} onChangePage={this.onChangePage} cardCount={this.props.cardCount || 2} cardBoxShadow={5} swipe={this.isMobile? 'horizontal-full' : ''} cardIconOnly={this.props.cardIconOnly}  autoLoad={this.isMobile || this.props.autoLoad} disableBackButton/>
    );
  }
}
