import * as React from 'react';
import { Grid } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  dropper?: boolean;
  children?: React.ReactNode;
}

export const DragDrop: React.FunctionComponent<Props> = (props) => {
  const dragDropRef = React.useRef<any>(null);
  const [isEnter, setIsEnter] = React.useState(false);

  const onDragStart = (e: React.DragEvent) => {
    if (props.dropper) return;
    e.dataTransfer.setData('Text', (e.target as HTMLElement).id);
  }
  
  const onDragLeave = (e: React.DragEvent) => {
    if (props.dropper) setIsEnter(false);
  }

  const onDragOver = (e: React.DragEvent) => {
    if (!props.dropper) return;
    e.preventDefault();
    setIsEnter(dragDropRef.current === (e.target as HTMLElement));
  }

  const onDrop = (e: React.DragEvent) => {
    if (!props.dropper) return;
    e.preventDefault();
    const childId = e.dataTransfer.getData('Text');
    if (dragDropRef.current?.id === (e.target as HTMLElement).id)
      dragDropRef.current?.appendChild(document.getElementById(childId));
    setIsEnter(false);
  }

  return (
    <Grid id={props.id || AppService.randomId()} ref={dragDropRef} container={props.dropper} border={isEnter? 1 : 0} borderColor='silver' minHeight={props.dropper? 50 : 0} draggable={!props.dropper} onDragStart={onDragStart} onDragLeave={onDragLeave} onDragOver={onDragOver} onDrop={onDrop}>
      {props.children}
    </Grid>
  );
};
