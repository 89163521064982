import React from 'react';
import { History } from 'history';
import { Input, InputDate, InputMultiline, InputSelect, Layout, InputFile, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './event-model';  //editable..
import EventsectionChecklist from '../eventsection/eventsection-checklist.component';
import PersonalinfoChecklist from '../personalinfo/personalinfo-checklist.component';
import EventList from './event-list.component';
import EventquestionList from '../eventquestion/eventquestion-list.component';
import EventquestionDelete from '../eventquestion/eventquestion-delete.component';
import AppService from '../../services/app.service';

interface IProps { 
    history?: History;
    root?: string;
    id?: number;
    caption?: string;
    astemplate?: string;
    delete?: boolean;
    onDelete?: (data: any) => void;
    getDataSet?: (data: any) => void;
    getDataError?: (data: any) => void;
    disableView?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    delete: boolean;
}

export default class EventDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            delete: false,
        }
    }

    private deleteCount: number = 0;
    private eventCount: number = 0;

    private getDataRow = (data:any) => {
        this.setState({dataRow: data});
        this.eventCount += 1;
        data.eventCount = 1;
        this.props.getDataSet?.(data);     
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
        this.props.getDataError?.(data)
    }

    private getDataRowEvent = (data:any) => {
        this.eventCount += data?.eventCount;
        data.eventCount = 1;
        this.props.getDataSet?.(data);
    }

    private onDeleteEvent = (data: any) => {
        this.deleteCount += data.deleteCount;
        data.deleteCount = 1;
        this.props.onDelete?.(data);
        if (!this.props.disableView && this.deleteCount>=this.eventCount) 
            setTimeout(() =>this.props.history?.goBack(), 1000);        
    }

    private onDelete = (data: any) =>{
        if (!this.props.disableView) this.setState({delete: true});
        this.deleteCount += 1;
        data.deleteCount = 1;
        this.props.onDelete?.(data);     
    }

    render() {
        var isDelete = this.props.disableView? this.props.delete : this.state.delete;

        return (
            //editable..
            <>
            <BaseDelete history={this.props.history} id={this.props.id} caption={this.props.disableView? ' ' : this.state.dataRow?.parent && 'Delete Event Section'} buttonCaption={this.props.disableView? ' ' : ''}
                model={Model} getDataRow={this.getDataRow} getDataError={this.getDataError} delete={this.props.delete} onDelete={this.onDelete} disableAutoBack disableBackButton={this.props.disableView} disableCard>                  
                
                {!this.props.disableView && 
                <Layout history={this.props.history} content={[
                    {caption: 'Details', child: 
                    <>
                        <Input name='name' label='Name' value={this.state.dataRow.name} readOnly/>
                        <InputMultiline name='description' label='Description' value={this.state.dataRow.description} readOnly/>
                        <InputSelect name='status' label='Status' value={this.state.dataRow.status} options={Model.optionStatus} readOnly />
                        {AppService.isAppFullVersion() &&
                        <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} readOnly/>
                        }
                        {this.state.dataRow.parent &&
                        <Input name='parentCaption' label='Parent' value={this.state.dataRow.parentCaption} readOnly/>
                        }
                        {!this.state.dataRow.parent &&
                        <Picklist name='eventtype' label='Type' value={this.state.dataRow.eventtype} caption={this.state.dataRow.eventtypeCaption} readOnly/>
                        }
                        <Picklist name='eventsection' label='Section' value='' caption={this.state.dataRow.eventsectionCaption} readOnly={this.state.dataRow.parent} disableAutoClose>
                            <EventsectionChecklist history={this.props.history} currentId={this.state.dataRow.eventsection} readOnly disableBackButton/>
                        </Picklist> 
                        {!this.state.dataRow.parent &&
                        <Picklist name='customer' label='Customer' value={this.state.dataRow.customer} caption={this.state.dataRow.customerCaption} readOnly/>
                        }
                        <Picklist name='participant' label={'Participant' + (this.state.dataRow.participantLength===1? ' Name' : 's')} value='' caption={this.state.dataRow.participantCaption} readOnly={this.state.dataRow.parent} disableAutoClose>
                            <PersonalinfoChecklist caption='Selected Participant' currentId={this.state.dataRow.participant} readOnly disableBackButton/>
                        </Picklist> 
                        <Input name='title' label='Title' value={this.state.dataRow.title} readOnly/>
                        <InputMultiline name='content' label='Content' value={this.state.dataRow.content} readOnly/>
                        <InputDate name='startdate' label='Start Date' value={this.state.dataRow.startdate} readOnly/>
                        <InputDate name='enddate' label='End Date' value={this.state.dataRow.enddate} readOnly/>
                        <InputFile name='imageUrl' label='Image' value={this.state.dataRow.imageUrl} type='image' readOnly/>                  
                    </>
                    },

                    {caption: 'Sections', child: 
                    this.state.dataRow.id>0 && this.props.history &&
                        <EventList history={this.props.history} root={this.props.root} caption='Event Section' parentId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                    },
                    
                    {caption: 'Questions', child: 
                    this.state.dataRow.id>0 && this.props.history &&
                        <EventquestionList history={this.props.history} root={this.props.root} caption='Question' eventId={this.state.dataRow.id} actions={['view']} disableBackButton disableCard/>
                    },
                ]}
                />
                }
            </BaseDelete>
            
            {this.state.dataRow.eventquestion?.map((eventquestion: any) => {
                return ( <EventquestionDelete key={eventquestion.id} id={eventquestion.id} delete={isDelete} disableView/> )
            })}

            {this.state.dataRow.event?.map((event: any) => {
                return ( <EventDelete key={event.id} id={event.id} getDataSet={this.getDataRowEvent} delete={isDelete} onDelete={this.onDeleteEvent} disableView/> )
            })}       
            
            </>
        )
    }
}