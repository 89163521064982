import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import ReportList from './report-list.component';
import ReportCreate from './report-create.component';
import ReportView from './report-view.component';
import ReportEdit from './report-edit.component';
import ReportCopy from './report-copy.component';
import ReportDelete from './report-delete.component';
import ReportPreview from './report-preview.component';

interface Props {
  root?: string;
}

export const ReportRoute: React.FunctionComponent<Props> = (props) => {
  return (
    <Switch>
      <Route path={(props.root||'')+'/report/create'} component={ReportCreate} />
      <Route path={`${props.root||''}/report/view`} render={(params)=> <ReportView {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/report/edit`} render={(params)=> <ReportEdit {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/report/copy`} component={ReportCopy} />
      <Route path={`${props.root||''}/report/delete`} render={(params)=> <ReportDelete {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/report/preview`} render={(params)=> <ReportPreview {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/report'} render={(params)=> <ReportList {...params} root={props.root} />} /> 
    </Switch>
  );
};
