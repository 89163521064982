import React from 'react';
import { History } from 'history';
import { Grid } from '@mui/material';
import { Button, Input, InputMultiline } from '../../components';
import Model from './event-model';  //editable..
import EventsectionModel from '../eventsection/eventsection-model';
import BaseCreate from '../base/base-create.component';
import BaseView from '../base/base-view.component'; 
import AppService from '../../services/app.service';
import BaseCreateEdit from '../base/base-create-edit.component';

interface IProps { 
    history: History,
    parentId: number;
    participantId?: number;
    participantCaption?: string;
    eventsectionId?: number;
    getDataRow?: (data: any) => void;
    disableView?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowParent: Model;
}

export default class EventCreateByuser extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowParent: Model.defaultValue(),
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.props.getDataRow?.(data);
        this.setState({dataRow: { ...this.state.dataRow, ...data}});
    }
    
    private getDataRowParent = (data:any) => {
        this.setState({dataRowParent: { ...this.state.dataRowParent, ...data}});
    }

    private getDataRowEventsection = (data: any) => {
        const fullname = AppService.strCapitalize(this.props.participantCaption || this.state.dataRowParent?.participantCaption) || '';
        var name1 = this.state.dataRowParent?.name+ (data? '/'+data?.name : '');
        name1 = name1.includes(fullname)? name1 : fullname+'/'+name1;
        
        this.setState({dataRow: { ...this.state.dataRow, 
            name : name1,
            description: name1,
            status: 'active',
            statusCaption: 'Active',  
            owner: this.state.dataRowParent?.owner,
            parent: this.state.dataRowParent.id,
            eventtype: this.state.dataRowParent.eventtype,
            eventtypeType: this.state.dataRowParent.eventtypeType,
            participant: this.props.participantId || this.state.dataRowParent?.participant?.[0]?.id,
            eventsection: data?.id,
            startdate: AppService.dateCurrent(),
            enddate: null,
            title: data?.title,
            content: data?.content,
            astemplate: 'no',
        }});
    };
      
    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private onSave = (data:any) => {
        if (this.props.disableView) this.goNext(data.id);
    }

    private onSaveBefore = async() => {
        await this.setState({dataRow: { ...this.state.dataRow, startdate: AppService.dateCurrent()}});
    }

    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    private goNext(id: number){
        var historyState: any = this.props.history.location.state;
        if (id>0 && this.state.dataRow.eventtypeType) this.props.history.push('/'+this.state.dataRow.eventtypeType, {id: id, eventInfo: historyState?.eventInfo})
    }

    
    render() {
        return (
            //editable..
            <>
            <BaseView id={this.props.eventsectionId || 0} caption=' '
                endPointFilter={this.props.eventsectionId? Model.endPointFilter6(this.props.parentId) : Model.endPointFilter7(this.props.parentId)} model={Model} getDataRow={this.getDataRow} disableBackButton/>
                
            <BaseView id={this.props.parentId} caption=' ' 
                model={Model} getDataRow={this.getDataRowParent}  disableBackButton/>
            
            {(this.state.dataRowParent.name) && !this.state.dataRow.id && 
            <BaseView id={this.props.eventsectionId || 0} caption=' ' 
                model={EventsectionModel} getDataRow={this.getDataRowEventsection}  disableBackButton/>
            }
            
            {this.props.disableView?
                <Grid  container sx={{justifyContent: {xs: 'center', md: 'right'}}}>
                {
                (this.state.dataRowParent.name) && !this.state.dataRow.id? 
                    <BaseCreate caption=' ' buttonCaption='Mulai'
                        model={Model} dataRow={this.state.dataRow} onSaveBefore={this.onSaveBefore} onSave={this.onSave} disableAutoBack disableBackButton disableCard />
                : 
                    <Button variant='contained' sx={{margin: 1, marginRight: 0, float: 'right'}} onClick={()=>this.goNext(this.state.dataRow.id)} label='Lanjut' />
                }
                </Grid>
            :
                <BaseCreateEdit id={this.props.eventsectionId || 0} caption=' '
                    model={Model} endPointFilter={this.props.eventsectionId? Model.endPointFilter6(this.props.parentId) : Model.endPointFilter7(this.props.parentId)} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError} disableBackButton disableCard>  
            
                    <Input name='title' label='Title' value={this.state.dataRow.title} error={this.state.dataError?.title} onChange={this.onChange} />
                    <InputMultiline name='content' label='Content' value={this.state.dataRow.content} error={this.state.dataError?.content} onChange={this.onChange} />
            
                </BaseCreateEdit>
            }
            
            
            
                                        
            </>
        );
    }
}