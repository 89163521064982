import * as React from 'react';
import { Chip as ChipMui } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  label?: string;
  variant?: any;
  size?: 'small'|'medium';
  color?: any;
  sx?: any;
}

export const Chip: React.FunctionComponent<Props> = (props) => {

  const isMobile = AppService.isMobile();

  return (
    <ChipMui 
      id={props.id}
      label={props.label}
      color={props.color || 'primary'}
      size={props.size}
      sx={props.sx}
      style={{fontSize: isMobile? 10 : ''}}
    />
  );
};
