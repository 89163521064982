import React from 'react';
import { History } from 'history';
import { InputSelect, Picklist } from '../../components';
import BaseDelete from '../base/base-delete.component'; 
import Model from './journalgroupdetail-model';  //editable..

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class JournalgroupdetailDelete extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    render() {
        return (
            //editable..
            <BaseDelete history={this.props.history} 
                model={Model} getDataRow={this.getDataRow}>              
                
                <Picklist name='transactiontype' label='Transaction Type' value={this.state.dataRow.transactiontype} caption={this.state.dataRow.transactiontypeCaption} readOnly/>
                <InputSelect name='sign' label='Debet/Credit' value={this.state.dataRow.sign} options={Model.optionSign} readOnly/>
                <Picklist name='coa' label='COA' value={this.state.dataRow.coa} caption={this.state.dataRow.coaCaption} readOnly/>
                
            </BaseDelete>
        );
    }
}