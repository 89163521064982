export default class TransactionpaymentModel {
    id: number;
    transaction: any;
    transactionCaption: string;
    paymenttype: any;
    paymenttypeCaption: string;
    value: number;
    note: string;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.transaction = dataRow.attributes.transaction?.data?.id || null;
        this.transactionCaption = dataRow.attributes.transaction?.data?.attributes.name || dataRow.attributes.transaction?.data?.id; 
        this.paymenttype = dataRow.attributes.paymenttype?.data?.id || null;
        this.paymenttypeCaption = dataRow.attributes.paymenttype?.data?.attributes.name || dataRow.attributes.paymenttype?.data?.id; 
        this.value = dataRow.attributes.value;
        this.note = dataRow.attributes.note;
    } 

    public static endPoint: string = 'transaction-payments';
    public static endPointFilter: string = '?populate=*&filters[transaction]=';
    public static url: string = '/transactionpayment';

    public static caption: string = 'Payment Detail';

    public static defaultValue = ()=> {
        return {
        id: 0,
        transaction: null,
        transactionCaption: '',
        paymenttype: null,
        paymenttypeCaption: '',
        value: 0,
        note: '',

  
        }
    }
    
    public static columns = [
        { name: 'paymenttypeCaption', caption: '' },
        { name: 'note', caption: '' },
        { name: 'value', caption: '', type: 'money' },
              
    ]

    public static columnPicklist = [
        { name: 'paymenttypeCaption', caption: 'Type' },
        { name: 'note', caption: 'Note' },
        { name: 'value', caption: 'Amount', type: 'money' },
    ]
    
}