import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import MenuList from './menu-list.component';
import MenuCreate from './menu-create.component';
import MenuView from './menu-view.component';
import MenuEdit from './menu-edit.component';
import MenuCopy from './menu-copy.component';
import MenuDelete from './menu-delete.component';

interface Props {
  root?: string;
}

export const MenuRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/menu/create'} component={MenuCreate} />
      <Route path={(props.root||'')+'/menu/view'} component={MenuView} />
      <Route path={(props.root||'')+'/menu/edit'} component={MenuEdit} />
      <Route path={(props.root||'')+'/menu/copy'} component={MenuCopy} />
      <Route path={(props.root||'')+'/menu/delete'} component={MenuDelete} />
      <Route path={(props.root||'')+'/menu'} render={(params)=> <MenuList {...params} root={props.root} />} />        
        
    </Switch>
  );
};
