import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProducttypeList from './producttype-list.component';
import ProducttypeCreate from './producttype-create.component';
import ProducttypeView from './producttype-view.component';
import ProducttypeEdit from './producttype-edit.component';
import ProducttypeCopy from './producttype-copy.component';
import ProducttypeDelete from './producttype-delete.component';

interface Props {
  root?: string;
}

export const ProducttypeRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/producttype/create'} component={ProducttypeCreate} />
      <Route path={(props.root||'')+'/producttype/view'} component={ProducttypeView} />
      <Route path={(props.root||'')+'/producttype/edit'} component={ProducttypeEdit} />
      <Route path={(props.root||'')+'/producttype/copy'} component={ProducttypeCopy} />
      <Route path={(props.root||'')+'/producttype/delete'} component={ProducttypeDelete} />
      <Route path={(props.root||'')+'/producttype'} render={(params)=> <ProducttypeList {...params} root={props.root} />} /> 
    </Switch>
  );
};
