import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import TransactionList from './transaction-list.component';
import TransactionCreate from './transaction-create.component';
import TransactionView from './transaction-view.component';
import TransactionEdit from './transaction-edit.component';
import TransactionCopy from './transaction-copy.component';
import TransactionDelete from './transaction-delete.component';
import TransactionEditStatus from './transaction-edit-status.component';

interface Props {
  root?: string;
}

export const TransactionRoutePurchase: React.FunctionComponent<Props> = (props) => {
  const disableFields = ['owner','transactionType','customer','journal'];
  
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactionpurchase/create'} render={(params)=> <TransactionCreate {...params} key={params.history.location.key} root={props.root} caption={'Create Purchasing Transaction'} transactiontypeType='purchase' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionpurchase/view`} render={(params)=> <TransactionView {...params} key={params.history.location.key} root={props.root} caption={'View Purchasing Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionpurchase/edit`} render={(params)=> <TransactionEdit {...params} key={params.history.location.key} root={props.root} caption={'Edit Purchasing Transaction'} transactiontypeType='purchase' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionpurchase/copy`} render={(params)=> <TransactionCopy {...params} key={params.history.location.key} root={props.root} caption={'Duplicate Purchasing Transaction'} transactiontypeType='purchase' disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionpurchase/delete`} render={(params)=> <TransactionDelete {...params} key={params.history.location.key} root={props.root} caption={'Delete Purchasing Transaction'} disableFields={disableFields} />} />        
        <Route path={`${props.root||''}/transactionpurchase/status`} render={(params)=> <TransactionEditStatus {...params} key={params.history.location.key} root={props.root} caption={'Change Status Purchasing Transaction'} transactiontypeType='purchase' disableFields={disableFields} />} />        
        <Route path={(props.root||'')+'/transactionpurchase'} render={(params)=> <TransactionList {...params} key={params.history.location.key} root={props.root} caption='Purchasing Transaction' transactiontypeType='purchase' actions={['...','status']} url='/transactionpurchase'/>} /> 
    </Switch>
  );
};
