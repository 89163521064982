import React from 'react';
import { Logo } from '../../components';

interface IProps { 
  caption?: string;
  titleColor?: any;
}

interface IState {
}

export default class ApplayoutLogo extends React.Component<IProps, IState> {

    constructor(props: IProps) {
      super(props);
      this.state = {
      }
    }

    render() {
      
        return (
          //editable..
          <Logo label={this.props.caption || document.title} url='/' titleColor={this.props.titleColor || 'primary.main'}></Logo>
        );
    }
}