import * as React from 'react';
import { TextField } from '@mui/material';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value: number;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  autoSelect?: boolean;
  box?: boolean;
  color?: string;
  readOnly?: boolean;
  onBlur?: (fieldName?: string, value?: string) => void;
  onChange?: (fieldName: string, value: string) => void;
  onEndTyping?: (fieldName?: string, value?: string) => void;
  error?: string;
}

export const InputNumber: React.FunctionComponent<Props> = (props) => {
  const [endTyping, setEndTyping] = React.useState<any>(null);
  const fontSize = () =>{
    switch (props.size) {
      case 'extrasmall': return 16;
      case 'small': return 18;
      case 'medium': return 24;
      case 'large': return 36;
      case 'extralarge': return 48;
      default : return 18;
    }
  }
  
  const onBlur = (props: Props) => (e: React.FocusEvent<HTMLInputElement>) => { 
    props.onBlur?.(e.target.name, e.target.value);
  };
  
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, AppService.strToNumber(e.target.value));
        
    if (props.onEndTyping) {
      clearTimeout(endTyping);
      setEndTyping(setTimeout(() => props.onEndTyping?.(e.target.name, e.target.value), 500));
    }
  };
  
  return (
    <TextField
      id={props.id || props.name}
      name={props.name}
      label={props.label}
      type='tel'
      value={AppService.strToNumber(props.value?.toString()) }
      placeholder={props.placeHolder}
      inputProps={{spellCheck: false}}
      InputProps={{
        readOnly: props.readOnly || false,
        sx: {fontSize: {xs: fontSize()-2, md: fontSize()}, bgcolor: props.color},
        inputProps: {
          sx: { textAlign: 'right' },
        }
      }}
      InputLabelProps={{
        sx: {fontSize: {xs: fontSize()-2, md: fontSize()}}
      }}
      margin='normal'
      variant={props.box? undefined : 'standard'}
      className='textEnd'
      fullWidth
      helperText={props.error}
      error= {props.error? true: false}
      onBlur={onBlur(props)}
      onChange={onChange(props)}
      onFocus={e => {props.autoSelect && e.target.select();}}
    />
  )
};