import * as React from 'react';
import { Box } from '@mui/material';
import { ParallaxScroll, Trapezoid } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  header?: React.ReactNode;
  children: React.ReactNode;
}


export const LayoutContainer: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();

  return (
    <Box bgcolor='secondary.main' minHeight={window.innerHeight}>
    <Trapezoid position='bottomLeft' color='secondary.light' colorOthers='secondary.main' fullWidth>
      <ParallaxScroll height={isMobile? 10 : 20} 
        backgroundElement={props.header}
      >
        {props.children}
      </ParallaxScroll>
    </Trapezoid>
    </Box>
  );
}