import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EventList from './event-list.component';
import EventCreate from './event-create.component';
import EventView from './event-view.component';
import EventEdit from './event-edit.component';
import EventCopy from './event-copy.component';
import EventDelete from './event-delete.component';
import EventShare from './event-share.component';

interface Props {
  root?: string;
}

export const EventRoute: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
      <Route path={(props.root||'')+'/event/create'} component={EventCreate} />
      <Route path={`${props.root||''}/event/view`} render={(params)=> <EventView {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={`${props.root||''}/event/edit`} render={(params)=> <EventEdit {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/event/copy'} component={EventCopy} />
      <Route path={`${props.root||''}/event/delete`} render={(params)=> <EventDelete {...params} key={params.history.location.key} root={props.root} />} />        
      <Route path={(props.root||'')+'/event/share'} component={EventShare} />
      <Route path={(props.root||'')+'/event'} render={(params)=> <EventList {...params} root={props.root} actions={['...','share']}/>} /> 
    </Switch>
  );
};
