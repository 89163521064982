import OwnerModel from '../owner/owner-model';
import AppService from '../../services/app.service';

export default class ScheduleModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    day: string;
    dayCaption: string;
    dayNo: number;
    time: any;
    timeAll: string;
    division: any;
    divisionCaption: string;

    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.day = dataRow.attributes.day || 'mon';
        this.dayCaption = ScheduleModel.optionDay.filter((d: any) => d.key === this.day)?.[0]?.value || 'Monday';
        this.dayNo = AppService.days('key').indexOf(this.day);
        this.time = dataRow.attributes.time;      
        this.timeAll = this.dayCaption + ', ' + AppService.dateFormat(this.time,'h:n');
        this.division = dataRow.attributes.division?.data?.id || null;
        this.divisionCaption = dataRow.attributes.division?.data?.attributes.name || dataRow.attributes.division?.data?.id; 
    }

    public static endPoint: string = 'schedules' 
    public static endPointFilter = ()=>{
        const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[owner]='+owner);
    } 
    public static url: string = '/schedule';
    public static caption: string = 'Schedule';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: +OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(), 
        day: 'mon',
        dayCaption: 'Monday',
        dayNo: 1,
        time: null,
        timeAll: '',
        division: null,
        divisionCaption: '',
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static columnPicklistCard = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
        { name: 'dayCaption', caption: 'Day', type: 'state', default: 'Monday' },
        { name: 'time', caption: 'Time', type: 'time2' },
    ]

    public static optionDay = AppService.optionDay;
}