import React from 'react';
import * as toastr from 'toastr';
import {Form } from '../../components';
import ApiService from '../../services/api.service';
import BaseUpload from './base-upload.component';
import BaseValidate from './base-validate.component';

interface IProps { 
    history?: any;
    id?: number;
    caption?: string;
    captionColor?: string;
    buttonCaption?: string;
    successCaption?: string;
    endPointFilter?: string;
    model: any;
    dataRow: any;
    dataRowMedia?: any;
    validations?: any;
    getDataRow?: (data: any) => void;
    getDataError?: (data: any) => void;
    onSaveBefore?: () => void;
    onSave?: (data: any) => void;
    save?: boolean;
    buttonOnBottom?: boolean;
    disableAutoBack?: boolean;
    disableBackButton?: boolean;
    disableCard?: boolean;
    children?: React.ReactNode;
}

interface IState {
}

export default class BaseEdit extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
    }

    private id = this.props.id?.toString() || this.props.history?.location.state?.id || '';
    private isLoading: boolean = false;
    private isSaving: boolean = false;

    private onSave = async() => {
        await this.props.onSaveBefore?.();
        var dataError = BaseValidate.validate(this.props.dataRow, this.props.validations || this.props.model.validations);
        this.props.getDataError?.(dataError);

        if (!dataError) 
        ApiService.update<typeof this.props.model>(this.props.model.endPoint+'/', this.id, this.props.model.endPointAuth? this.props.dataRow : {data:this.props.dataRow}).then(
            (rp) =>  {
                if (rp.Status) {
                    if (this.props.dataRowMedia && rp.Data) BaseUpload.uploadImage(rp.Data.id, this.props.dataRowMedia);
                    this.props.onSave?.(rp.Data);
                    (!this.props.successCaption || this.props.successCaption!==' ') && toastr.success(this.props.successCaption || 'Data updated.');
                    if (!this.props.disableAutoBack) {this.props.history?.goBack();}
                }
                else { this.props.getDataError && rp.Errors? this.props.getDataError(rp.Errors) : toastr.error(rp.Messages); }
                this.isSaving = false;
            }
        );
    }
 
    public componentDidMount() {
        if (this.isLoading) return;
        this.isLoading = true;
        
        ApiService.get<typeof this.props.model>(this.props.model.endPoint+(this.props.endPointFilter||'?populate=*&filters[id]='), this.id).then(
            (rp) => {
                if (rp.Status) {if (rp.Data.length>0) var data = new this.props.model(rp.Data[0]); this.props.getDataRow?.(data);} 
                else { toastr.error(rp.Messages); }
                this.isLoading = false;
            }
        );
    }
    
    public componentDidUpdate() {
        if (this.props.save) {
            if (this.isSaving) return;
            this.isSaving = true;
            this.onSave();
        }
    }

    render() {
        return(
            <Form history={this.props.history} caption={this.props.caption || 'Edit '+ this.props.model.caption} captionColor={this.props.captionColor} buttonCaption={this.props.buttonCaption || 'save'} buttonOnBottom={this.props.buttonOnBottom} onSubmit={this.onSave} disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
                {this.props.children}
            </Form>
        );
    }
}