import * as React from 'react';
import { ButtonGroup, MenuItem, TextField, Typography } from '@mui/material';
import { Button, Chip } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  box?: boolean;
  color?: string;
  options: any;
  buttonStyle?: boolean;
  readOnly?: boolean;
  onChange?: (fieldName: string, value: string) => void;
  error?: string;
}

export const InputSelect: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const fontSize = () =>{
    switch (props.size) {
      case 'extrasmall': return 16;
      case 'small': return 18;
      case 'medium': return 24;
      case 'large': return 36;
      case 'extralarge': return 48;
      default : return 18;
    }
  }
  
  const onChange = (props: Props) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    props.onChange?.(e.target.name, e.target.value);
  };
  const onClick = (value: string) => (e: React.MouseEvent<HTMLButtonElement>)=> {
    props.onChange?.(props.name, value);
  }
  const getDesc = () => {
    return props.options.filter((d: any) => d.key === props.value)?.[0]?.value || '';
  }

  return (
    <>
    {props.readOnly?
      <>
      <Typography sx={{typography: {xs: 'caption', md: 'caption'}}} mt={1} mb={1}>{props.label}</Typography>
      <Chip label={getDesc()} size={isMobile? 'small' : 'medium'} color='info' /> 
      </>
    :
    (props.buttonStyle || props.options.length<=3)?
      <>
      <Typography sx={{typography: {xs: 'caption', md: 'caption'}}} mt={1} mb={1}>{props.label}</Typography>
      <ButtonGroup disableElevation sx={{marginBottom: 2}}>
      {props.options.map((option: any) => (
          <Button key={option.key} label={option.value} variant={props.value === option.key? 'contained' : 'outlined'} textCapitalize onClick={onClick(option.key)}/>
      ))}
      </ButtonGroup>
      </>
    :
      <TextField
        id={props.id || props.name}
        name={props.name}
        label={props.label} 
        value={props.readOnly? getDesc() : (props.value || '')}
        placeholder={props.placeHolder}
        inputProps={{spellCheck: false}}
        InputProps={{
          readOnly: props.readOnly || false,
          sx: {fontSize: {xs: fontSize()-2, md: fontSize()}, bgcolor: props.color},
        }}
        InputLabelProps={{
          sx: {fontSize: {xs: fontSize()-2, md: fontSize()}}
        }}
        sx= {{
          '& .MuiSvgIcon-root': {
            color: 'primary.main',
            fontSize: 40,
          }
        }}
        select={!props.readOnly}
        margin='normal'
        variant={props.box? undefined : 'standard'}
        fullWidth
        helperText={props.error}
        error= {props.error? true: false}
        onChange={onChange(props)}
      >
      {props.options.map((option: any) => (
        <MenuItem key={option.key} value={option.key}>
          {option.value}
        </MenuItem>
      ))}
      </TextField>
    }
    </>
  )
};