import * as React from 'react';
import { History } from 'history';
import { Box, Container, Grid, Typography } from '@mui/material';
import { AppBar, Card, Numbering, ParallaxMouseMove, Transition, Trapezoid } from '../../../components';
import ApplayoutLogo from '../applayout-logo.component';
import StudentPicklistCard from './student-picklistcard.component';
import Student from './student.component';
import AppService from '../../../services/app.service';

interface IProps {
  history: History;
  getDataRow: (data: any) => void;
}

interface IState {
  triggerTransition: boolean;
  dataRowStudent: any;
}

export default class Home extends React.Component<IProps, IState> {
  
  constructor(props: IProps) {
    super(props);
    this.state = {
      triggerTransition : true,
      dataRowStudent : null,
    }
    
    document.title = 'Hands on Kespro';
  }

  private isMobile: boolean = AppService.isMobile();

  private getDataRow = (data:any) => {
    this.setState({dataRowStudent:  data});
    
    document.getElementById('header')?.scrollIntoView(false);
  }

  private onClickAppBar = () =>{
    setTimeout(() => this.setState({ triggerTransition: true, dataRowStudent: null }), 100);
  }

  private onScrollEndAppBar = () =>{
    this.setState({ triggerTransition: false })
  }

  private header(){
    return (
      <>
      <Trapezoid id='header' position='bottomLeft' colorOthers='white'>
        <Grid container>
          <Grid item xs={12} md={4}> 
            <Transition parentId='header' addTime={2000}>
              <Typography sx={{typography: {xs: 'h4', md: 'h2'}, textAlign: {xs: 'center', md: 'right'}, lineHeight: {xs: 0.75, md: 0.75}}} color='secondary.dark'>
                <b>HANDS<br/>ON<br/>KESPRO</b> <br/>
              </Typography>  
            </Transition>
            <Transition parentId='header' addTime={3000}>
              <Typography sx={{typography: {xs: 'h6', md: 'h4'}, textAlign: {xs: 'center', md: 'right'}}} color='secondary.main'>
                  kespro untuk semua
              </Typography>
            </Transition>
          </Grid>
          <Grid item xs={12} md={8} textAlign='center'>
          <Transition variant='slide' direction='up' trigger>
              <Box>
              {this.isMobile?
                <>
                <img src={process.env.PUBLIC_URL + '/assets/handsonkespro-header-1.png'} width='auto' height={150} alt='img' />
                <img src={process.env.PUBLIC_URL + '/assets/handsonkespro-header-2.png'} width='auto' height={150} alt='img' />
                <img src={process.env.PUBLIC_URL + '/assets/handsonkespro-header-3.png'} width='auto' height={150} alt='img' />
                </>
              :
                <>
                <ParallaxMouseMove imageUrl={process.env.PUBLIC_URL + '/assets/handsonkespro-header-1.png'} left='50%' width='auto' height={300} alt='Parallax1' />
                <ParallaxMouseMove variant='moveVertReverse' imageUrl={process.env.PUBLIC_URL + '/assets/handsonkespro-header-2.png'} left='60%' top='5%' width='auto' height={400} alt='Parallax2' />
                <ParallaxMouseMove imageUrl={process.env.PUBLIC_URL + '/assets/handsonkespro-header-3.png'} left='75%' width='auto' height={300} alt='Parallax2' />
                </>
              }
              </Box>
            </Transition>
          </Grid>
          <Grid item xs={12} md={12}>
            <Transition parentId='header' addTime={5000}>
              <Typography sx={{typography: {xs: 'h5', md: 'h4'}, textAlign: {xs: 'center', md: 'left'}}} color='white'>
                <b>Kesehatan Reproduksi Inklusi</b> 
              </Typography>
            </Transition>
            </Grid>
        </Grid>
      </Trapezoid>
      </>
    )
  }

  private why(){
    return (
      <Box id='why' p={3} bgcolor='white'>
        <Transition parentId='why' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container direction='row' spacing={3} pt={10} pb={10}>   
              <Grid item xs={12} md={5} pr={3} textAlign='center'>
                <Transition parentId='why' addTime={2000}>
                  <Typography sx={{typography: {xs: 'h5', md: 'h4'}, textAlign: {xs: 'center', md: 'left'}}} color='primary.main'>
                    <b>Kenapa Penting </b>
                  </Typography>
                </Transition>
                <Transition parentId='why' addTime={3000}>
                  <Typography sx={{typography: {xs: 'h5', md: 'h4'}, textAlign: {xs: 'center', md: 'left'}, lineHeight: {xs: 1, md: 1} }}>
                    <b>Pendidikan Kesehatan Reproduksi?</b>
                    <br/><img src={process.env.PUBLIC_URL + '/assets/handsonkespro-why.png'} width='auto' height={this.isMobile? 100:150} alt='img' />
                  </Typography>
                </Transition>
              </Grid>
              <Grid item xs={12} md={7}>
                <Transition parentId='why' addTime={5000}>
                  <Typography sx={{typography: {xs: 'subtitle1', md: 'h5'}, textAlign: 'justify'}} >
                    Dengan Pengetahuan yang baik akan Kesehatan Reproduksi maka kita akan mengenal tubuh kita, kita menjadi tahu perubahan apa yang terjadi pada tubuh kita, sehingga kita dapat menjaga dan melindungi serta menyanyangi tubuh kita dengan baik dan benar. 
                    <br/><br/>Materi yang digunakan Dalam Website ini berasal dari panduan-panduan dari Kementrian Kesehatan RI dan Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi.
                  </Typography>
                </Transition>
              </Grid>
            </Grid>
          </Container>
        </Transition>
      </Box>
    )
  }

  private studentList(caption: string){
    return (
      <Box id='studentList' pt={3} pb={3} bgcolor='secondary.dark'>
        <Transition parentId='studentList' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, color:'primary.light'}} mb={2}>
              <b>{caption}</b>
            </Typography>
            <Card>
              <StudentPicklistCard getDataRow={this.getDataRow}/>
            </Card>
          </Container>
        </Transition>
      </Box>
    )
  }

  private aboutus(){
    return (
      <Box id='aboutus' p={3} bgcolor='secondary.dark'>
        <Transition parentId='aboutus' variant='fade' trigger={this.state.triggerTransition}>
          <Container>
            <Grid container direction='row-reverse' spacing={3} pt={10} pb={10} alignItems='center'>            
              <Grid item xs={12} md={3} textAlign='center'>
                <Transition parentId='aboutus' addTime={4000}>
                  <Typography sx={{typography: {xs: 'h5', md: 'h3'}}} color='primary.main'>
                    <b>Tentang</b>
                  </Typography>
                </Transition>
                <Transition parentId='aboutus' addTime={3000}>
                  <Typography sx={{typography: {xs: 'h5', md: 'h3'}}} color='primary.main'>
                    <b>Kami</b>
                  </Typography>
                </Transition>
              </Grid>
              <Grid item xs={12} md={9}>
                <Transition parentId='aboutus' addTime={2000}>
                  <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}, color:'primary.light'}} >
                    <b>Website ini merupakan hasil penelitian Dosen Kebidanan yang terdiri dari :</b>
                  </Typography>
                </Transition>
                <Transition parentId='aboutus' addTime={3000}>
                  <Grid mt={2}>
                  <Numbering id='a' size={40} spacing={0} data={[
                    'Ajeng Galuh Wuryandari., S.ST., Bdn., MPH (Poltekkes Kemenkes Jambi)',
                    'Ruwayda., S.ST., Bdn., M.Kes (Poltekkes Kemenkes Jambi)',
                    'Nurmisih., S.Pd., M.Kes (Poltekkes Kemenkes Jambi)',
                    'Julaecha., S.ST., Bdn., M.Keb (Stikes Baiturrahim Jambi)'
                  ]}/>
                  </Grid>
                </Transition>
              </Grid>
            </Grid>
          </Container>
        </Transition>
      </Box>
    )
  }
  private footer(){
    
    return (
      <Trapezoid id='footer' position='topLeft' color='secondary.light' colorOthers='secondary.dark'>
        <Transition parentId='footer' trigger={this.state.triggerTransition}>
          <Grid container spacing={5} sx={{ padding: {xs: 5, md:0}, paddingBottom: {xs: 5, md:5}}}>
            <Transition parentId='footer' addTime={1000}>
              <Grid item xs={12} md={4}>
                <ApplayoutLogo/>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                Kespro Untuk Semua
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                
                </Typography>
              </Grid>
            </Transition>
            
            <Transition parentId='footer' addTime={2000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Hubungi Kami</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Call Center <br/>
                  
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Email <br/>
                  
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Instagram
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Facebook
                </Typography>
              </Grid>
            </Transition>

            <Transition parentId='footer' addTime={3000}>
              <Grid item xs={12} md={2}>
                <Typography sx={{typography: {xs: 'subtitle1', md: 'h6'}}}>
                  <b>Kebijakan</b>
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Kebijakan Pribadi
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Syarat & Ketentuan
                </Typography>
                <Typography sx={{typography: {xs: 'caption', md: 'body2'}}} mt={2}>
                  Sering ditanyakan
                </Typography>
              </Grid>
            </Transition>
          </Grid>
        </Transition>
      </Trapezoid>
    )
  }

  private home(){

    return (
      <>
      {this.state.dataRowStudent? 
        <>
        <Student dataRow={this.state.dataRowStudent}/> 
        {this.studentList('Lihat Materi Lainnya')}
        </>
      : <>
        {this.header()}
        {this.why()}
        {this.studentList('Materi Siswa')}
        {this.aboutus()}
        </>
      }
      {this.footer()}
      </>
    )
  }
  
  public render(): React.ReactNode {  
    return (
      <AppBar 
        logo = {<ApplayoutLogo/>}
        menu = {[{caption: 'Beranda', section:'header', url: '/', icon: 'home'}, {caption: 'Tentang Kami',  section:'aboutus', icon: 'contact_support'}, {caption: 'Siswa', section:'studentList', icon:'face'}, {caption:'Orang Tua/Guru', section:'parent', icon: 'face_4'}, {caption:'Kontak', section:'footer', icon:'support_agent'}]}      
        onClick={this.onClickAppBar}
        onScrollEnd={this.onScrollEndAppBar}
        >
        {this.home()}
      </AppBar>
    )
  }
}