import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import EventViewByuser from './event-view-byuser.component';

interface Props {
  root?: string;
}

export const EventRouteEventtype: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
      <Route path={`${props.root||''}/quiz`} render={(params)=> <EventViewByuser {...params} key={params.history.location.key} />} />        
      <Route path={`${props.root||''}/wedding`} render={(params)=> <EventViewByuser {...params} key={params.history.location.key} />} />        
      </Switch>
  );
};
