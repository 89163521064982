import * as React from 'react';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { Icon } from '.';
import AppService from '../services/app.service';

interface Props {
  id?: string;
  name: string;
  label?: string;
  placeHolder?: string;
  value: string;
  size?: 'extrasmall'|'small'|'medium'|'large'|'extralarge';
  box?: boolean;
  color?: string;
  readOnly?: boolean;
  clearValue?: boolean;
  disablePast?: boolean;
  disableTime?: boolean;
  disableDay?: any;
  onChange?: (fieldName: string, value: any) => void;
  error?: string;
}

export const InputDate: React.FunctionComponent<Props> = (props) => {
  const isMobile = AppService.isMobile();
  const [open, setOpen] = React.useState<boolean>();
  const [value, setValue] = React.useState<any>(null);

  const fontSize = () =>{
    switch (props.size) {
      case 'extrasmall': return 16;
      case 'small': return 18;
      case 'medium': return 24;
      case 'large': return 36;
      case 'extralarge': return 48;
      default : return 18;
    }
  }

  const onClickClear = () => {
    if (isMobile) setOpen(false);
    setValue(null);
    props.onChange?.(props.name, null);
  };

  const onClick = () => {setOpen(true);};
  const onClose = () => {setOpen(undefined)};

  React.useEffect(() => {
    setValue(props.value);
    
  },[props.value])

  return (
    <Grid id={props.id || props.name}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        
        <DateTimePicker
          value={value}
          inputFormat={'DD-MMM-YYYY' + (props.disableTime? '' : ' HH:mm')}
          ampm={false}
          closeOnSelect={true}
          disableMaskedInput
          disableOpenPicker = {props.readOnly}
          disablePast = {props.disablePast}
          shouldDisableDate={(date) => {return props.disableDay?.includes(date.$W)}}     
          hideTabs={props.disableTime? true : false}
          open={isMobile? (open? true:false) : undefined}
          readOnly={props.readOnly}
          showToolbar={false}
          views={props.disableTime? ['year', 'month','day'] : ['year', 'month','day','hours','minutes']}
          componentsProps={{
            actionBar: {actions: ['accept','cancel']},
          }}
          onChange={(newValue) => {
            setValue(newValue || null);
            props.onChange?.(props.name, newValue || null);
          }}     
          onClose={onClose}
          InputProps={{
            startAdornment: !props.readOnly &&
              <>
              {props.value &&
                <IconButton onClick={onClickClear} style={{order: 1, padding: 0, marginBottom: -2}}>
                  <Icon name='clear' size='small' color='primary.main'/>
                </IconButton>
              }
              {isMobile &&
                <IconButton onClick={onClick} style={{order: 1, padding: 0, marginBottom: -2}}>
                  <Icon name='event' size='small' color='primary.main'/>
                </IconButton>
              }
              </>
          }}
          InputAdornmentProps={{
            position: 'end',
            sx: {order: 2, marginLeft: 0.2, marginRight: 1.4,
              '.MuiIconButton-root': { padding: 0 },
              '.MuiSvgIcon-root': {margin: 0, color: 'primary.main' },
            }
          }}
          PopperProps={{
            sx: {
              '.MuiPaper-root': { padding: 5 },
              '.MuiPickersDay-dayWithMargin': { }
            },
          }}
          renderInput={(params) => 
            <TextField  {...params}      
              margin='normal'           
              label={props.label}     
              variant={props.box? undefined : 'standard'}
              inputProps={{ ...params.inputProps, readOnly: true }}
              InputProps={{ ...params.InputProps,
                sx: {fontSize: {xs: fontSize()-2, md: fontSize()}, bgcolor: props.color},
              }}
              fullWidth
            /> 
          }
        />
        
      </LocalizationProvider>
      <Typography variant='caption' color='error' display='block' >
        {props.error}
      </Typography>
    </Grid>
  )
};