import OwnerModel from '../owner/owner-model';

export default class PaymenttypeModel {
    id: number;
    name: string;
    description: string;
    status: string;
    statusCaption: string;
    owner: any;
    ownerCaption: string;
    method: string;
    journalgroup: any;
    journalgroupCaption: string;
    
    constructor(dataRow:any) {
        this.id = dataRow.id;
        this.name = dataRow.attributes.name;
        this.description = dataRow.attributes.description;
        this.status = dataRow.attributes.status || 'active';
        this.statusCaption = this.status==='active'? 'Active' : 'Inactive';
        this.owner = dataRow.attributes.owner?.data?.id || null;
        this.ownerCaption = dataRow.attributes.owner?.data?.attributes.name || dataRow.attributes.owner?.data?.id;
        this.method = dataRow.attributes.method || 'cash';
        this.journalgroup = dataRow.attributes.journalgroup?.data?.id || null;
        this.journalgroupCaption = dataRow.attributes.journalgroup?.data?.attributes.name || dataRow.attributes.journalgroup?.data?.id;

    } 

    public static endPoint: string = 'payment-types' 
    public static endPointFilter = ()=>{
       const owner = OwnerModel.getOwner();
        return ( '?populate=*&filters[owner]='+owner);
    } 
    public static url: string = '/paymenttype';
    public static caption: string = 'Payment Type';

    public static defaultValue = ()=> {
        return {
        id: 0,
        name: '',
        description: '',
        status: 'active',
        statusCaption: 'Active',
        owner: OwnerModel.getOwner(),
        ownerCaption: OwnerModel.getOwnerCaption(),         
        method: 'cash',
        journalgroup: null,
        journalgroupCaption: '',
  
        }
    }
    
    public static validations = [
        { name: 'name', required: true, minLength: 3 },
        { name: 'description', minLength: 3 },
    ]

    public static columns = [
        { name: 'name', caption: 'Name' },
        { name: 'description', caption: 'Description' },
        { name: 'statusCaption', caption: ' ', type: 'state', default: 'Active' },
    ]

    public static columnPicklist = [
        { name: 'name', caption: 'Name', type: 'caption' },
        { name: 'description', caption: 'Description' },
    ]

    public static optionType = [
        { key: 'cash', value: 'Cash' },
        { key: 'banktransfer', value: 'Bank Transfer' },
        { key: 'ewallet', value: 'e-Wallet' },
    ]
}