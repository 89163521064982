import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import TransactionamountList from './transactionamount-list.component';
import TransactionamountCreate from './transactionamount-create.component';
import TransactionamountView from './transactionamount-view.component';
import TransactionamountEdit from './transactionamount-edit.component';
import TransactionamountCopy from './transactionamount-copy.component';
import TransactionamountDelete from './transactionamount-delete.component';

interface Props {
  root?: string;
}

export const TransactionamountRoute: React.FunctionComponent<Props> = (props) => {
  
  return (
    <Switch>
        <Route path={(props.root||'')+'/transactionamount/create'} component={TransactionamountCreate} />
        <Route path={(props.root||'')+'/transactionamount/view'} component={TransactionamountView} />
        <Route path={(props.root||'')+'/transactionamount/edit'} component={TransactionamountEdit} />
        <Route path={(props.root||'')+'/transactionamount/copy'} component={TransactionamountCopy} />
        <Route path={(props.root||'')+'/transactionamount/delete'} component={TransactionamountDelete} />
        <Route path={(props.root||'')+'/transactionamount'} component={TransactionamountList}/>
    </Switch>
  );
};
