import * as React from 'react';
import Model from './product-model';
import BasePicklistCard from '../base/base-picklistcard.component';

interface IProps { 
  caption?: string;
  producttypeType?: string;
  currentId?: number;
  pricetype?: string;
  cardCount?: number;
  pageSize?: number;
  swipe?: string;
  cardNowrap?: boolean;
  getDataRow?: (data: any) => void;
}

interface IState {
}

export default class ProductPicklistCard extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }

  private header: any = Model.columnPicklistCard.filter((d: any) => d.name !== (this.props.pricetype === 'pricebase'? 'pricesale' : 'pricebase'));

  private getDataRow = (data: any) => {
    this.props.getDataRow?.(data)
  };

  public render(): React.ReactNode {
    return (
      <BasePicklistCard caption={this.props.caption} endPointFilter={Model.endPointFilter(this.props.producttypeType)} model={Model} currentId={this.props.currentId} getDataRow={this.getDataRow} header={this.header} cardCount={this.props.cardCount} pageSize={this.props.pageSize} cardNowrap={this.props.cardNowrap} swipe={this.props.swipe} disableBackButton/>
    );
  }
}
