import React from 'react';
import { History } from 'history';
import { Input, InputMultiline, InputSelect, Switch, Picklist } from '../../components';
import BaseEdit from '../base/base-edit.component'; 
import Model from './transactiontype-model';  //editable..
import OwnerPicklist from '../owner/owner-picklist.component';
import ReportgroupPicklist from '../reportgroup/reportgroup-picklist.component';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class TransactiontypeEdit extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataRow = (data:any) => {
        this.setState({dataRow:  data});
    }

    private getDataRowOwner = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'owner': data?.id || null, 'ownerCaption': data?.name || ''  }  });
    };
    
    private getDataRowReportgroup = (data: any) => {
        this.setState({ dataRow: { ...this.state.dataRow, 'reportgroup': data?.id || null, 'reportgroupCaption': data?.name || ''  }  });
    };

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
        
    }

    render() {
        return (
            //editable..
            <BaseEdit history={this.props.history} 
                model={Model} dataRow={this.state.dataRow} getDataRow={this.getDataRow} getDataError={this.getDataError}>              
                
                <Input name='name' label='Name' value={this.state.dataRow.name} error={this.state.dataError?.name} onChange={this.onChange} />
                <InputMultiline name='description' label='Description' value={this.state.dataRow.description} error={this.state.dataError?.description} onChange={this.onChange} />
                <Switch name='status' label='Status' value={this.state.dataRow.status} defaultTrue='active' defaultFalse='inactive' error={this.state.dataError?.status} onChange={this.onChange}/>
                {AppService.isAppFullVersion() &&
                <Picklist name='owner' label='Owner' value={this.state.dataRow.owner} caption={this.state.dataRow.ownerCaption} error={this.state.dataError?.owner} onChange={this.onChange} >
                    <OwnerPicklist currentId={this.state.dataRow.owner} getDataRow={this.getDataRowOwner}/>
                </Picklist>
                }     
                <InputSelect name='type' label='Type' value={this.state.dataRow.type} options={Model.optionType} error={this.state.dataError?.type} onChange={this.onChange} />
                <InputSelect name='pricetype' label='Use Price' value={this.state.dataRow.pricetype} options={Model.optionPricetype} error={this.state.dataError?.pricetype} onChange={this.onChange} />
                <Picklist name='reportgroup' label='Report Group' value={this.state.dataRow.reportgroup} caption={this.state.dataRow.reportgroupCaption} clearValue={this.getDataRowReportgroup} error={this.state.dataError?.reportgroup} onChange={this.onChange}>
                    <ReportgroupPicklist currentId={this.state.dataRow.reportgroup} getDataRow={this.getDataRowReportgroup}/>
                </Picklist>

            </BaseEdit>
        );
    }
}