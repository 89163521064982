import React from 'react';
import { History } from 'history';
import { InputEmail } from '../../components';
import BaseCreate from '../base/base-create.component'; 
import Model from './user-model';  //editable..

interface IProps { 
    history: History;
    caption?: string;
    useremail?: string;
    disableBackButton?: boolean;
    disableCard?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
}

export default class UserAuthSendEmailConfirmation extends  React.Component<IProps, IState> {
    constructor(props:IProps) {
        super(props);  
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
        }
        this.onChange = this.onChange.bind(this);
    }

    private getDataError = (data: any) => {
        this.setState({dataError: data});
    }

    private onSave = (data: any) => {
        var sent=data.sent; //triger for send useremail.
    };
    
    private onChange(fieldName: string, value: string) { 
        const nextState = {
            ...this.state, dataRow: {
                ...this.state.dataRow, [fieldName]: value,
            }
        };
        this.setState(nextState);
    }

    public componentDidMount(){
        const dataRowTemp: any = Model.defaultValueSendEmailConfirmation;
        this.setState({dataRow: dataRowTemp})
    }
    
    public componentDidUpdate(){
        if (!this.state.dataRow.useremail && this.props.useremail!=='' && this.props.useremail!==this.state.dataRow.useremail) 
            this.setState({ dataRow: { ...this.state.dataRow, 'useremail': this.props.useremail || '', 'email': Model.getEmailApp(this.props.useremail || '')}});
    }

    render() {
        return (
            //editable..          
            <BaseCreate history={this.props.history} caption={this.props.caption || 'Send Email Confirmation'} buttonCaption='Send Email'
                endPoint={Model.endPointAuth+'/send-useremail-confirmation'} model={Model} dataRow={this.state.dataRow} validations={Model.validationSendEmailConfirmation} getDataError={this.getDataError} onSave={this.onSave} buttonOnBottom disableBackButton={this.props.disableBackButton} disableCard={this.props.disableCard}>              
    
                <InputEmail id='senduseremail' name='useremail' label='Your registered useremail' value={this.state.dataRow.useremail} error={this.state.dataError?.useremail} onChange={this.onChange} />
            </BaseCreate>
        );
    }     
     
}