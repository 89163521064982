import * as React from 'react';
import { Popover as PopoverMui, Typography } from '@mui/material';

interface Props {
  id?: string;
  value?: string;
  anchorEl: HTMLElement | null,  
  children?: React.ReactNode;
}

export const Popover: React.FunctionComponent<Props> = (props) => {
  return (
    <PopoverMui 
      id={props.id}
      open={props.anchorEl? true : false}
      anchorEl={props.anchorEl}
      anchorOrigin={{ vertical: 'center', horizontal: 'right'}}
      transformOrigin={{ vertical: 'center', horizontal: 'left'}}
      sx={{ pointerEvents: 'none'}}
      disableRestoreFocus
    >
      <Typography p={1}>{props.value}</Typography>
    </PopoverMui>
  );
};
