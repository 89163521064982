import * as React from 'react';
import { Route, Switch } from 'react-router-dom';

import ProductList from './product-list.component';
import ProductCreate from './product-create.component';
import ProductView from './product-view.component';
import ProductViewByuser from './product-view-byuser.component';
import ProductEdit from './product-edit.component';
import ProductCopy from './product-copy.component';
import ProductDelete from './product-delete.component';

interface Props {
  root?: string;
}

export const ProductRoute: React.FunctionComponent<Props> = (props) => {

  return (
    <Switch>
      <Route path={(props.root||'')+'/product/create'} component={ProductCreate} />
      <Route path={(props.root||'')+'/product/view'} component={ProductView} />
      <Route path={(props.root||'')+'/product/viewbyuser'} component={ProductViewByuser} />
      <Route path={(props.root||'')+'/product/edit'} component={ProductEdit} />
      <Route path={(props.root||'')+'/product/copy'} component={ProductCopy} />
      <Route path={(props.root||'')+'/product/delete'} component={ProductDelete} />
      <Route path={(props.root||'')+'/product'} render={(params)=> <ProductList {...params} root={props.root} />} /> 
    </Switch>
  );
};
