import * as React from 'react';
import { History } from 'history';
import Model from './product-model';
import BaseChecklist from '../base/base-checklist.component';

interface IProps { 
  history: History;
  currentId: any;
  pricetype?: string;
  getDataRow?: (data: any) => void;
  readOnly?: boolean
  disableBackButton? : boolean
}

interface IState {
}

export default class ProductChecklist extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
  }


  private header: any = Model.columnPicklist.filter((d: any) => d.name !== (this.props.pricetype === 'pricebase'? 'pricesale' : 'pricebase'));

  public render(): React.ReactNode {
    return (
      <BaseChecklist history={this.props.history} endPointFilter={Model.endPointFilter()} model={Model} currentId={this.props.currentId} getDataRow={this.props.getDataRow} header={this.header} readOnly={this.props.readOnly} disableBackButton={this.props.disableBackButton}/>
    );
  }
}
