import React, {Suspense} from 'react';
import { History } from 'history';
import {Grid, Typography } from '@mui/material';
import { ScreenGuard, TextToHtml } from '../../components';
import BasePreview from '../base/base-preview.component';
import Model from './report-model';  //editable..
import ApiService from '../../services/api.service';
import AppService from '../../services/app.service';

interface IProps { 
    history: History;
    root?: string;
    id?: number;
    caption?: string;
    proccessCaption?: string;
    save?: boolean;
    getFiles?: (data: any) => void;
    disableView?: boolean;
}

interface IState {
    dataRow: Model;
    dataError: any;
    dataRowResult: any;
    isCompleted: boolean;
    isProgress: boolean;
}

export default class ReportPreview extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);
        this.state = {
            dataRow: Model.defaultValue(),
            dataError: null,
            dataRowResult: null,
            isCompleted: false,
            isProgress: false,
        }
        
    }

    private isMobile: boolean = AppService.isMobile();
    private isReady: boolean = false;
    private isCompleted: boolean = false;
    private htmlId =  'section';

    private getDataRow = (data:any) => {
        this.setState({ dataRow: data });
    }

    private getDataRowResult = async(data:any) => {
        if (!this.isReady) {
            var optionText = JSON.stringify(this.state.dataRow.option);
            data = {...data, reportname: this.state.dataRow.name, reportdescription: this.state.dataRow.name, documenttitle: document.title}
            optionText = AppService.replaceValue(optionText, data);
            this.setState({ dataRow: {...this.state.dataRow, option: JSON.parse(optionText)}});
            await this.setState({ dataRowResult: data});
            this.isReady = true;
        }
    }

    private getContent(params: string, style?: any) {
        var paramsSplit = params.replace(/[<>]/gi,'').split(';');
        const url = paramsSplit[0];
        const section = paramsSplit[1];
        const Component = React.lazy(() => import(`../${url}.component`).catch(()=>{return ({default: ()=>{}})} ));

        return (
            <Suspense>
                <Component history={this.props.history} getDataRow={this.getDataRowResult} section={section} style={style}/>
            </Suspense>
        )
    }

    private displaySection(){
        return this.state.dataRow?.report?.map((dataRow: any, i: number)=> {
            var dataRowSection = dataRow.attributes;
            var style = dataRowSection.option || (this.state.dataRow.option[this.state.dataRow.type] || Model.option[this.state.dataRow.type])?.[dataRowSection.type];
            var imagePosition = style?.image?.imagePosition || 0;
            
            if (this.state.dataRowResult) dataRowSection.content = AppService.replaceValue(dataRowSection.content, this.state.dataRowResult);
            this.isCompleted = i===this.state.dataRow.report.length-1;

            return (
                <Grid container key={i} width={1513} borderTop={i>0? 10 : 0} borderColor='whitesmoke' pl={this.isMobile? 2: 20} pr={this.isMobile? 2: 20}>
                    <Grid id={this.htmlId} item container width={1513} sx={style?.frame}>
                        {imagePosition === 0 && 
                        <>
                        {dataRowSection.imagetitle &&
                        <Grid item container sx={style?.imagetitle.frame}>
                            <Typography sx={style?.imagetitle.typography}>
                                <b>{dataRowSection.imagetitle}</b>
                            </Typography>                
                        </Grid>
                        }
                        {dataRowSection.image?.data &&
                        <Grid item container sx={style?.image}>
                            <img src={ApiService.svrUrl+dataRowSection.image.data[0].attributes.url} width='auto' height={style?.image?.imageHeight || this.isMobile? 100:200} alt={dataRowSection.name} />
                        </Grid>
                        }
                        </>
                        }
                        <Grid item container sx={style?.title.frame}>
                            <Typography sx={style?.title.typography}>
                                <b>{dataRowSection.title}</b>
                            </Typography>                
                        </Grid>
                        {imagePosition === 1 && 
                        <>
                        {dataRowSection.imagetitle &&
                        <Grid item container sx={style?.imagetitle.frame}>
                            <Typography sx={style?.imagetitle.typography}>
                                <b>{dataRowSection.imagetitle}</b>
                            </Typography>                
                        </Grid>
                        }
                        {dataRowSection.image?.data &&
                        <Grid item container sx={style?.image}>
                            <img src={ApiService.svrUrl+dataRowSection.image.data[0].attributes.url} width='auto' height={style?.image?.imageHeight || this.isMobile? 100:200} alt={dataRowSection.name} />
                        </Grid>
                        }
                        </>
                        }
                        <Grid item container sx={style?.content.frame}>
                            {dataRowSection.content?.includes('<<') && dataRowSection.content?.includes('>>')? 
                            this.getContent(dataRowSection.content, style?.content.typography)
                            : 
                            <TextToHtml content={dataRowSection.content} sx={style?.content.typography}/>
                            }    
                        </Grid>
                    </Grid>
                </Grid>
            )
        })
    }

    private onProgress = async(data: any) =>{
        this.setState({isProgress: data});
    }

    public componentDidUpdate = () => {
        if (this.state.isCompleted !== this.isCompleted && this.isReady)
            setTimeout(() =>  this.setState({isCompleted: this.isCompleted}), 500); 
    }

    render() {
        return (
            //editable..
            <BasePreview history={this.props.history} id={this.props.id} caption={this.props.disableView? ' ' : this.props.caption} buttonCaption={this.props.disableView || !this.state.isCompleted? ' ' : ''} proccessCaption={this.props.proccessCaption}
                endPointFilter={Model.endPointFilter1} model={Model} getDataRow={this.getDataRow} printOptions={this.state.dataRow.option?.printOption} htmlId={this.htmlId} save={this.props.save && this.state.isCompleted} getFiles={this.props.getFiles} onProgess={this.onProgress} disableCard disableBackButton={this.props.disableView}>   
                <ScreenGuard disable={this.props.disableView || this.state.isProgress}/>
                <Grid container bgcolor='white' height={this.props.disableView? 0 : 'auto'} width={this.props.disableView? 0 : 1513} overflow={this.props.disableView? 'auto' : 'unset'}>
                    {!this.isReady && this.state.dataRow?.content?.includes('<<') && this.getContent(this.state.dataRow.content)}
                    {this.displaySection()}
                </Grid>
            </BasePreview>
        );
    }
}